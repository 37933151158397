import React, { memo } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import Skeleton from "../../../_shared/Skeleton";
import {
  renderSkillChallengesGameInfo,
  renderUsernameIdEmailRow
} from "../../../../core/helpers/functions";
import { deleteSkillChallengesConnection } from "../../actions";
import IconButtonComponent from "../../../_shared/Buttons/IconButton";
import { translateConnectionStatus } from "../../helpers";
import { closeConfirmationDialog, setConfirmationDialog } from "../../../../core/appActions";

import DeleteIcon from "../../../../assets/image/delete.svg";

const ConnectionItem = ({
  id,
  loading = false,
  user,
  username,
  game,
  cod_platform,
  fortnite_platform,
  region,
  status
}) => {
  const dispatch = useDispatch();

  const onDeleteClick = () => {
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: () => dispatch(deleteSkillChallengesConnection(id)),
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: "Delete connection",
        description: "Are you sure you want to delete this skill challenges connection?",
        confirmText: "Delete",
        declineText: "Cancel"
      })
    );
  };

  return (
    <div className="skill-challenges__connection-user-row">
      <div className="skill-challenges__connection-user-row--col">
        {renderUsernameIdEmailRow(user, loading)}
      </div>
      <div className="skill-challenges__connection-user-row--col text-overflow">
        {renderSkillChallengesGameInfo(game, loading, cod_platform || fortnite_platform || region)}
      </div>
      <div className="skill-challenges__connection-user-row--col">
        <Skeleton loading={loading} variant="text" width={80}>
          <span className="table-text text-overflow">{username || "-"}</span>
        </Skeleton>
      </div>
      <div className="skill-challenges__connection-user-row--col">
        <Skeleton loading={loading} variant="text" width={70}>
          <div className={clsx("skill-challenges__connection-user-row--status", status)}>
            {translateConnectionStatus(status)}
          </div>
        </Skeleton>
      </div>
      <div className="skill-challenges__connection-user-row--col">
        <IconButtonComponent onClick={onDeleteClick} alt="delete" src={DeleteIcon} />
      </div>
    </div>
  );
};

export default memo(ConnectionItem);
