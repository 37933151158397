import React from "react";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import PrevIcon from "@material-ui/icons/KeyboardArrowLeft";
import NextIcon from "@material-ui/icons/KeyboardArrowRight";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";

import "./styles.scss";

const propTypes = {
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number
};

const PaginationComponent = ({ page, total, onChange, itemsPerPage = 10 }) => {
  const { t } = useTranslation();
  return (
    <div className="pagination-wrapper">
      <Pagination
        activePage={page}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        onChange={onChange}
        prevPageText={<PrevIcon />}
        nextPageText={<NextIcon />}
        firstPageText={<FirstPageIcon />}
        lastPageText={<LastPageIcon />}
        itemClassPrev="pagination-prev"
        itemClassNext="pagination-next"
        itemClassFirst="pagination-first"
        itemClassLast="pagination-last"
        itemClass="pagination-item"
        activeClass="pagination-item-active"
      />
      <p className="pagination-info">
        {t("t:shared.pagination-info", {
          page,
          numberOfPages: Math.ceil(total / itemsPerPage),
          itemsFrom: 1 + (page - 1) * 10,
          itemsTo: total < page * 10 ? total : page * 10,
          itemsPerPage,
          total
        })}
      </p>
    </div>
  );
};

PaginationComponent.propTypes = propTypes;

export default PaginationComponent;
