import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Linkify from "react-linkify";
import dayjs from "dayjs";

const Message = ({ text, is_mine, create_date, avatar, image, openImageBox }) => {
  let img = image && (
    <button onClick={() => openImageBox(image)}>
      <img src={image} alt="in chat" />
    </button>
  );
  if (is_mine) {
    return (
      <div className="message mine_message">
        <p>
          <Linkify>{text}</Linkify>
        </p>
        {img}
        <span>{dayjs(create_date).format("HH:mm")}</span>
      </div>
    );
  } else {
    return (
      <div className="message friend_message">
        <Avatar
          src={avatar}
          classes={{
            root: "message_avatar"
          }}
        />
        <p>
          <Linkify>{text}</Linkify>
        </p>
        {img}
        <span>{dayjs(create_date).format("HH:mm")}</span>
      </div>
    );
  }
};

export default Message;
