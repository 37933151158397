import * as yup from "yup";

const addUserSchema = yup.object().shape({
  username: yup.string().required("The field is required"),
  first_name: yup.string().required("The field is required"),
  last_name: yup.string().required("The field is required"),
  email: yup
    .string()
    .email("Incorrect email")
    .required("The field is required"),
  phone: yup.string().required("The field is required"),
  password: yup
    .string()
    .required("The field is required")
    .test("len", "Password is too weak", val => val.length > 8),
  password_confirm: yup
    .string()
    .required("The field is required")
    .test("passwords-match", "Passwords must match", function(value) {
      return this.parent.password === value;
    })
});

export default addUserSchema;
