import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { withRouter } from "react-router";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import dayjs from "dayjs";

import DefaultButton from "../../_shared/Buttons/DefaultButton";
import InputField from "../../_shared/InputField";
import SelectComponent from "../../_shared/SelectComponent";
import DateTimePicker from "../../_shared/DateTimePicker";
import IconButtonComponent from "../../_shared/Buttons/IconButton";
import BackDrop from "../../_shared/BackDrop";
import PopperInfo from "../../_shared/PopperInfo";
import { getOption } from "../../../core/helpers/functions";
import {
  positiveOrZeroIntegerValidation,
  positiveOrZeroNumberValidation
} from "../../../core/helpers/validations";
// import { games, platforms } from "../../../helpers/constants";
import urls from "../../../core/urls";

import { patchTournament, postCreateTournament } from "../actions";

import CheckedIcon from "../../../assets/image/checked.svg";
import UncheckedIcon from "../../../assets/image/unchecked.svg";

import "./styles.scss";

const defaultProps = {
  min_participants: 14,
  max_participants: 16,
  title: "",
  entry_fee: "",
  system_fee: "",
  second_place_prize: "",
  custom_first_place_prize: "",
  start_at: dayjs().add(1, "day"),
  tournamentID: null,
  is_test: false
};

const TournamentFields = ({
  t,
  min_participants,
  max_participants,
  title,
  entry_fee,
  system_fee,
  second_place_prize,
  custom_first_place_prize,
  start_at,
  history,
  tournamentID,
  is_test
}) => {
  const games = useSelector(({ tournaments }) => tournaments.selectOptions.games);
  const platforms = useSelector(({ tournaments }) => tournaments.selectOptions.platforms);
  const [anchorEl, setAnchorEl] = useState(null);
  const errorPopper = Boolean(anchorEl);
  const [dateTime, setDateTime] = useState(start_at);
  const [game, setGame] = useState({ label: getOption(games[0].label), value: games[0].value });
  const [platform, setPlatform] = useState({
    label: getOption(platforms[0].label),
    value: platforms[0].value
  });
  const [isTest, setIsTest] = useState(is_test);
  let published = false;

  const platformOptions = useMemo(
    () => games.find(el => el.value === game.value)?.platforms || platforms,
    [game.value]
  );

  useEffect(() => {
    if (platformOptions.length) {
      setPlatform({
        label: getOption(platformOptions[0].label),
        value: platformOptions[0].value
      });
    }
  }, [platformOptions]);

  const dispatch = useDispatch();

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(t("t:validation.required")),
        entry_fee: positiveOrZeroNumberValidation(t),
        min_participants: positiveOrZeroIntegerValidation(t),
        max_participants: positiveOrZeroIntegerValidation(t),
        system_fee: positiveOrZeroIntegerValidation(t),
        second_place_prize: positiveOrZeroNumberValidation(t),
        custom_first_place_prize: yup.string().when("entry_fee", entry_fee => {
          if (entry_fee?.toString().length && entry_fee?.toString() === "0") {
            return yup.string().required();
          }
          return yup.string();
        })
      }),
    []
  );

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors }
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      min_participants,
      max_participants,
      title,
      entry_fee,
      system_fee,
      second_place_prize,
      custom_first_place_prize
    },
    shouldFocusError: true
  });
  const watchFields = watch();

  useEffect(() => {
    if (watchFields.entry_fee.toString().length && +watchFields.entry_fee === 0) {
      setValue("system_fee", "0", { shouldValidate: true });
    } else {
      setValue("custom_first_place_prize", "");
      trigger(["custom_first_place_prize"]);
    }
  }, [watchFields.entry_fee]);

  const buttonLoading = useSelector(({ app }) => app.buttonLoading);

  const onSubmit = async data => {
    if (
      !watchFields.custom_first_place_prize &&
      second_place_prize >
        watchFields.max_participants * (watchFields.entry_fee - watchFields.system_fee) -
          watchFields.second_place_prize
    ) {
      setAnchorEl(document.getElementById("first-place-prize-input"));
    } else {
      const res = await dispatch(
        !tournamentID
          ? postCreateTournament({
              ...data,
              custom_first_place_prize: data.custom_first_place_prize || null,
              start_at: dateTime,
              game: game.value,
              platform: platform.value,
              status: published ? "upcoming" : "not_published",
              is_test: isTest
            })
          : patchTournament(tournamentID, {
              ...data,
              custom_first_place_prize: data.custom_first_place_prize || null,
              start_at: dateTime,
              game: game.value,
              platform: platform.value,
              is_test: isTest
            })
      );
      if (res?.payload) {
        history.push(`${urls.tournamentsUrl}?tab=upcoming&page=1`);
      }
    }
  };

  const onButtonClick = isPublished => (published = isPublished);

  return (
    <form className="tournaments-fields" onSubmit={handleSubmit(onSubmit)}>
      <div className="fields-wrapper">
        <div className="fields-block margin-bottom-48">
          <div className="block-name">{t("t:tournaments.general-info")}</div>
          <div className="fields">
            <Controller
              name="title"
              control={control}
              render={props => (
                <InputField
                  id="name-input"
                  label={t("t:shared.name")}
                  type="text"
                  {..._.omit(props.field, "ref")}
                  inputRef={props.ref}
                  error={errors.title?.message}
                />
              )}
            />
            <div className="several-fields margin-top-32">
              <SelectComponent
                id="game-select"
                change={setGame}
                value={game}
                placeholder={t("t:shared.game")}
                options={games.map(({ label, value }) => ({ label: getOption(label), value }))}
              />
              <SelectComponent
                id="platform-select"
                change={setPlatform}
                value={platform}
                placeholder={t("t:shared.platform")}
                options={platformOptions}
              />
            </div>
          </div>
        </div>
        <div className="fields-block margin-bottom-48">
          <div className="block-name">{t("t:tournaments.time-settings")}</div>
          <div className="fields">
            <div className="several-fields">
              <DateTimePicker dateTime={dateTime} handleDateTime={setDateTime} />
              {/*<Controller*/}
              {/*  name="checkin_time_minutes"*/}
              {/*  control={control}*/}
              {/*  render={props => (*/}
              {/*    <InputField*/}
              {/*      id="check-in-time-input"*/}
              {/*      label={t("t:tournaments.check-in-time")}*/}
              {/*      type="number"*/}
              {/*      {..._.omit(props.field, "ref")}*/}
              {/*      inputRef={props.ref}*/}
              {/*      error={errors.checkin_time_minutes?.message}*/}
              {/*      endAdornment={<span>{t("t:tournaments.minutes")}</span>}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*/>*/}
            </div>
          </div>
        </div>
        <div className="fields-block margin-bottom-48">
          <div className="block-name text-capitalize">{t("t:tournaments.participants")}</div>
          <div className="fields">
            <div className="several-fields">
              <Controller
                name="min_participants"
                control={control}
                render={props => (
                  <InputField
                    id="min-input"
                    label={t("t:tournaments.min")}
                    type="number"
                    disabled
                    {..._.omit(props.field, "ref")}
                    inputRef={props.ref}
                    error={errors.min_participants?.message}
                  />
                )}
              />
              <Controller
                name="max_participants"
                control={control}
                render={props => (
                  <InputField
                    id="max-input"
                    label={t("t:tournaments.max")}
                    type="number"
                    disabled
                    {..._.omit(props.field, "ref")}
                    inputRef={props.ref}
                    error={errors.max_participants?.message}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="fields-block">
          <div className="block-name">{t("t:tournaments.finance")}</div>
          <div className="fields">
            <div className="several-fields">
              <Controller
                name="entry_fee"
                control={control}
                render={props => (
                  <InputField
                    id="entry-fee-input"
                    label={t("t:tournaments.entry-fee")}
                    type="number"
                    {..._.omit(props.field, "ref")}
                    inputRef={props.ref}
                    error={errors.entry_fee?.message}
                    endAdornment={<span className="euro">€</span>}
                  />
                )}
              />
              <div className="input-with-info">
                <Controller
                  name="system_fee"
                  control={control}
                  render={props => (
                    <InputField
                      id="system-fee-input"
                      label={t("t:tournaments.system-fee")}
                      type="number"
                      {..._.omit(props.field, "ref")}
                      inputRef={props.ref}
                      error={errors.system_fee?.message}
                      endAdornment={<span className="euro">€</span>}
                      disabled={
                        watchFields.entry_fee.toString().length !== 0 &&
                        +watchFields.entry_fee === 0
                      }
                    />
                  )}
                />
                {watchFields.entry_fee.toString().length !== 0 &&
                +watchFields.entry_fee === 0 ? null : (
                  <span className="info">
                    <span className="value">
                      {watchFields.max_participants && watchFields.entry_fee
                        ? (
                            watchFields.max_participants *
                            (watchFields.entry_fee - watchFields.system_fee)
                          ).toFixed(0)
                        : 0}
                      €&nbsp;
                    </span>
                    {t("t:tournaments.max-prize-pool")}
                  </span>
                )}
              </div>
            </div>
            <div className="several-fields margin-top-32">
              <Controller
                name="custom_first_place_prize"
                control={control}
                render={props => (
                  <InputField
                    id="entry-fee-input"
                    label={t("t:tournaments.first-place-prize")}
                    type="number"
                    {..._.omit(props.field, "ref")}
                    inputRef={props.ref}
                    error={errors.custom_first_place_prize?.message}
                    endAdornment={<span className="euro">€</span>}
                    disabled={
                      !watchFields.entry_fee.toString().length || +watchFields.entry_fee !== 0
                    }
                  />
                )}
              />
              <div className="input-with-info">
                <Controller
                  name="second_place_prize"
                  control={control}
                  render={props => (
                    <InputField
                      id="second-place-prize-input"
                      label={t("t:tournaments.second-place-prize")}
                      type="number"
                      {..._.omit(props.field, "ref")}
                      inputRef={props.ref}
                      error={errors.second_place_prize?.message}
                      endAdornment={<span className="euro">€</span>}
                    />
                  )}
                />
                {watchFields.entry_fee.toString().length !== 0 &&
                +watchFields.entry_fee === 0 ? null : (
                  <p className="info" id="first-place-prize-input">
                    <span className="value">
                      {watchFields.max_participants &&
                      watchFields.second_place_prize &&
                      watchFields.entry_fee
                        ? (
                            watchFields.max_participants *
                            (watchFields.entry_fee - watchFields.system_fee)
                          ).toFixed(0) - watchFields.second_place_prize
                        : 0}
                      €&nbsp;
                    </span>
                    {t("t:tournaments.first-place-prize")}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="is-test-tournament">
          <IconButtonComponent
            onClick={() => setIsTest(!isTest)}
            alt="is test"
            src={isTest ? CheckedIcon : UncheckedIcon}
          />
          <p onClick={() => setIsTest(!isTest)} className="no-user-select cursor-pointer">
            {t("t:tournaments.is-test-tournament")}
          </p>
        </div>
      </div>
      <div className="buttons-wrapper">
        {tournamentID ? (
          <DefaultButton variant="contained" classes="green-btn" formAction>
            {t("t:tournaments.save-changes")}
          </DefaultButton>
        ) : (
          <>
            <DefaultButton
              variant="outlined"
              onClick={() => onButtonClick(false)}
              classes="green-btn"
              formAction
            >
              {t("t:tournaments.create-unpublished")}
            </DefaultButton>
            <DefaultButton
              variant="contained"
              onClick={() => onButtonClick(true)}
              classes="green-btn"
              formAction
            >
              {t("t:tournaments.create-and-publish")}
            </DefaultButton>
          </>
        )}
      </div>
      <BackDrop open={buttonLoading} />
      <PopperInfo
        open={errorPopper}
        anchorEl={anchorEl}
        clickAway={() => setAnchorEl(null)}
        classes="first-place-prize-popper"
      >
        {t("t:validation.first-place-prize")}
      </PopperInfo>
    </form>
  );
};

TournamentFields.defaultProps = defaultProps;

export default withTranslation()(withRouter(TournamentFields));
