import { useLocation } from "react-router-dom";

const useQueryParams = () => {
  const { search } = useLocation();
  const queries = new URLSearchParams(search);
  const getParam = paramName => queries.get(paramName) || null;
  return {
    queries,
    getParam
  };
};

export { useQueryParams };
