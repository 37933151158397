import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Dialog from "../../Dialog";
import DefaultButton from "../../Buttons/DefaultButton";

import "./styles.scss";

const propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  declineAction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  declineText: PropTypes.string.isRequired
};

const ConfirmationDialog = ({
  open,
  onClose,
  confirmAction,
  declineAction,
  title,
  description,
  confirmText,
  declineText
}) => {
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);
  return (
    <Dialog open={open} onClose={onClose}>
      <div className="confirmation-dialog">
        <h2 className="title">{title}</h2>
        <div className="description">{description}</div>
        <hr className="separator" />
        <div className="buttons-wrapper">
          <DefaultButton variant="contained" onClick={declineAction} classes="grey-btn">
            {declineText}
          </DefaultButton>
          <DefaultButton
            variant="contained"
            onClick={confirmAction}
            classes="green-btn"
            loading={buttonLoading}
          >
            {confirmText}
          </DefaultButton>
        </div>
      </div>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = propTypes;

export default ConfirmationDialog;
