import React, { useEffect, useRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import clsx from "clsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import notifyError from "../../core/helpers/notifyError";
import notifySuccess from "../../core/helpers/notifySuccess";
import { closeErrorSnack, setSocketData } from "../../core/appActions";
import urls from "../../core/urls";
import { API_SOCKET_BASE_URL } from "../../core/config";

import Header from "./LayoutBlocks/Header";
import Panel from "./LayoutBlocks/Panel";

import NotFound from "../../components/NotFound";
import ConfirmationDialog from "../../components/_shared/Dialogs/ConfirmationDialog";
import TournamentsContainer from "../../components/Tournaments/container";
import TournamentDisputes from "../../components/TournamentDisputes";
import TournamentDisputeInner from "../../components/TournamentDisputes/TournamentDisputeInner";
import Users from "../../components/Users";
import AddUser from "../../components/Users/AddUser";
import UserProfile from "../../components/UserProfile";
import SkillChallenges from "../../components/SkillChallenges";
import ChallengesSystem from "../../components/ChallengesSystem";
import AddNewChallenge from "../../components/ChallengesSystem/AddNewChallenge";
import EditChallenge from "../../components/ChallengesSystem/EditChallenge";
import Registrations from "../../components/Registrations";

const Container = ({
  errorSnack,
  successSnack,
  errorSnackText,
  successSnackText,
  match,
  widePanel,
  confirmationDialog
}) => {
  if (!localStorage.token) return <Redirect to={urls.loginUrl} />;
  const ws = useRef(null);
  const reconnect = useRef(null);
  const dispatch = useDispatch();

  const connectSocket = () => {
    ws.current = new WebSocket(`${API_SOCKET_BASE_URL}/admin-socket/?token=${localStorage.token}`);
    ws.current.onopen = () => console.log("admin ws opened");
    ws.current.onmessage = onWSMessage;
    ws.current.onclose = () => onWSClose;
    ws.current.onerror = e => console.log("admin ws error " + JSON.stringify(e));
  };

  const onWSMessage = e => {
    const data = JSON.parse(e.data);
    console.table(data);
    dispatch(setSocketData(data.type, data.data));
  };

  const onWSClose = e => {
    if (e.wasClean) {
      console.log("admin ws closed - was clean");
    } else {
      console.log("admin ws closed - was not clean");
      reconnect.current = setTimeout(() => connectSocket(), 5000);
    }
  };

  useEffect(() => {
    connectSocket();
    return () => {
      ws.current.close();
      clearTimeout(reconnect.current);
    };
  }, []);

  useEffect(() => {
    if (errorSnack) {
      notifyError(errorSnackText);
    }
  }, [errorSnack]);

  useEffect(() => {
    if (successSnack) {
      notifySuccess(successSnackText);
    }
  }, [successSnack]);

  return (
    <>
      <Header />
      <section className={clsx("page-with-panel", !widePanel && "panel-is-hidden")}>
        <Panel />
        <div className="page-content-wrapper">
          <Switch>
            <Route path={match.url} exact render={() => <Redirect to={urls.usersUrl} />} />
            <Route path={urls.usersUrl} exact component={Users} />
            <Route path={urls.addNewUser} exact component={AddUser} />
            <Route path={urls.userProfileUrl} exact component={UserProfile} />

            <Route path={urls.tournamentsUrl} component={TournamentsContainer} />
            <Route path={urls.tournamentDisputesUrl} exact component={TournamentDisputes} />
            <Route path={urls.tournamentDisputeInnerUrl} exact component={TournamentDisputeInner} />

            <Route path={urls.skillChallengesUrl} exact component={SkillChallenges} />

            <Route path={urls.challengesSystemUrl} exact component={ChallengesSystem} />
            <Route path={urls.addDailyChallengeUrl} exact component={AddNewChallenge} />
            <Route path={urls.addOneVsOneChallengeUrl} exact component={AddNewChallenge} />
            <Route path={urls.addSkillChallengeUrl} exact component={AddNewChallenge} />
            <Route path={urls.editDailyChallengeUrl} exact component={EditChallenge} />
            <Route path={urls.editOneVsOneChallengeUrl} exact component={EditChallenge} />
            <Route path={urls.editSkillChallengeUrl} exact component={EditChallenge} />

            <Route path={urls.registrationsUrl} exact component={Registrations} />
            <Route render={() => <NotFound />} />
          </Switch>
        </div>
      </section>
      <ToastContainer />
      <ConfirmationDialog {...confirmationDialog} />
    </>
  );
};

const mapStateToProps = ({ app }) => {
  return {
    errorSnack: app.errorSnack,
    errorSnackText: app.errorSnackText,
    successSnack: app.successSnack,
    successSnackText: app.successSnackText,
    widePanel: app.widePanel,
    confirmationDialog: app.confirmationDialog
  };
};

const mapDispatchToProps = {
  closeErrorSnack
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
