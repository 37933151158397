import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware, ConnectedRouter } from "connected-react-router";
import { multiClientMiddleware } from "redux-axios-middleware";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import api from "./core/api";
import routes from "./core/routes";
import rootReducer from "./core/rootReducer";
import rootSaga from "./core/rootSaga";

// import common_de from "./translations/de/common.json";
import common_en from "./core/translations/en/common.json";

const axiosMiddlewareOptions = {
  interceptors: {
    request: [
      (action, config) => {
        if (localStorage.token || localStorage.token_res) {
          let token = localStorage.token ? localStorage.token : localStorage.token_res;
          config.headers["Authorization"] = "Token " + token;
        }
        return config;
      }
    ],
    response: [
      {
        success: (func, response) => {
          return response;
        },
        error: (func, error) => {
          if (error.response.status === 401) {
            localStorage.clear();
          }
          return Promise.reject(error);
        }
      }
    ]
  }
};

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      t: common_en
    }
  },
  transSupportBasicHtmlNodes: true,
  transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
  saveMissing: true
});

const history = createBrowserHistory();
const appRouterMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const createStoreWithMiddleware = applyMiddleware(
  multiClientMiddleware(api, axiosMiddlewareOptions),
  appRouterMiddleware,
  sagaMiddleware
)(createStore);
const store = createStoreWithMiddleware(rootReducer(history), {});

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <ConnectedRouter history={history}>{routes}</ConnectedRouter>
    </I18nextProvider>
  </Provider>,
  document.getElementById("wrapper")
);
