import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";

import DefaultButton from "../../../_shared/Buttons/DefaultButton";
import InputField from "../../../_shared/InputField";
import SelectComponent from "../../../_shared/SelectComponent";
import BackDrop from "../../../_shared/BackDrop";
import { getOption } from "../../../../core/helpers/functions";
import { nullableIntegerValidation } from "../../../../core/helpers/validations";
import urls from "../../../../core/urls";
import { dailyCountBy, dailyGamemodes, dailyTemplates, rewardOptions } from "../../constants";
import { getChallengeTypeByPathname, translateDailyChampionTemplate } from "../../helpers";
import { patchChallenge, postChallenge } from "../../actions";

import RadioSelectedIcon from "../../../../assets/image/radio-selected.svg";
import RadioUnselectedIcon from "../../../../assets/image/radio-unselected.svg";

import "../styles.scss";

const defaultProps = {
  id: null,
  template: null,
  game_type: null,
  counter_text: null,
  reward_money: "",
  reward_bonus_cash: "",
  is_ongoing: false
};

const DailyFields = ({
  id,
  template,
  game_type,
  counter_text,
  reward_money,
  reward_bonus_cash,
  is_ongoing
}) => {
  const [rewardType, setRewardType] = useState(
    reward_money && reward_bonus_cash
      ? "euro_+_bonus_cash"
      : reward_bonus_cash
      ? "bonus_cash"
      : "euro"
  );
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let published = false;

  const challengeType = useMemo(() => getChallengeTypeByPathname(pathname), [pathname]);

  const { schema, templateOptions, gamemodeOptions, countByOptions } = useMemo(
    () => ({
      schema: yup.object().shape({
        reward_money: nullableIntegerValidation(),
        reward_bonus_cash: nullableIntegerValidation()
      }),
      templateOptions: dailyTemplates.map(value => ({
        label: getOption(translateDailyChampionTemplate(value, t)),
        value
      })),
      gamemodeOptions: dailyGamemodes.map(({ label, value }) => ({
        label: getOption(label),
        value
      })),
      countByOptions: dailyCountBy.map(({ label, value }) => ({
        label: getOption(label),
        value
      }))
    }),
    []
  );

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      template: template
        ? {
            label: getOption(translateDailyChampionTemplate(template, t)),
            value: template
          }
        : null,
      game_type: game_type
        ? {
            label: getOption(dailyGamemodes.find(el => el.value === game_type).label),
            value: game_type
          }
        : null,
      counter_text: counter_text
        ? {
            label: getOption(dailyCountBy.find(el => el.value === counter_text).label),
            value: counter_text
          }
        : null,
      reward_money,
      reward_bonus_cash
    },
    shouldFocusError: true
  });

  const watchFields = watch();

  const disabled = useMemo(
    () =>
      (rewardType === "euro_+_bonus_cash" &&
        !watchFields.reward_bonus_cash &&
        !watchFields.reward_money) ||
      (rewardType === "euro" && !watchFields.reward_money) ||
      (rewardType === "bonus_cash" && !watchFields.reward_bonus_cash) ||
      !watchFields.template ||
      !watchFields.game_type ||
      !watchFields.counter_text,
    [rewardType, watchFields]
  );

  const buttonLoading = useSelector(({ app }) => app.buttonLoading);

  const prepareDataForSubmit = data => ({
    template: data.template.value,
    game_type: data.game_type.value,
    counter_text: data.counter_text.value,
    reward_money: rewardType === "bonus_cash" ? 0 : data.reward_money,
    reward_bonus_cash: rewardType === "euro" ? 0 : data.reward_bonus_cash
  });

  const onSubmit = async data => {
    const res = await dispatch(
      !id
        ? postChallenge(challengeType, {
            ...prepareDataForSubmit(data),
            is_active: !!published
          })
        : patchChallenge(challengeType, id, prepareDataForSubmit(data))
    );
    if (res?.payload) {
      history.push(`${urls.challengesSystemUrl}?tab=daily`);
    }
  };

  const onButtonClick = isPublished => (published = isPublished);

  return (
    <form className="challenges-fields" onSubmit={handleSubmit(onSubmit)}>
      <div className="fields-wrapper">
        <div className="fields-block margin-bottom-48">
          <div className="block-name">General info</div>
          <div className="fields">
            <Controller
              name="template"
              control={control}
              render={props => (
                <SelectComponent
                  change={props.field.onChange}
                  id="gamemode-select"
                  placeholder="Challenge template"
                  {..._.omit(props.field, "ref")}
                  options={templateOptions}
                  isDisabled={is_ongoing}
                />
              )}
            />
            <div className="several-fields margin-top-32">
              <Controller
                name="game_type"
                control={control}
                render={props => (
                  <SelectComponent
                    change={props.field.onChange}
                    id="gamemode-select"
                    placeholder="Gamemode"
                    {..._.omit(props.field, "ref")}
                    options={gamemodeOptions}
                    isDisabled={is_ongoing}
                  />
                )}
              />
              <Controller
                name="counter_text"
                control={control}
                render={props => (
                  <SelectComponent
                    change={props.field.onChange}
                    id="counter-text-select"
                    placeholder="Build leaderboard by"
                    {..._.omit(props.field, "ref")}
                    options={countByOptions}
                    isDisabled={is_ongoing}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="fields-block margin-bottom-48">
          <div className="block-name">Reward</div>
          <div className="fields">
            {!is_ongoing && (
              <div className="radio-buttons margin-bottom-32">
                {rewardOptions.map(({ label, value }) => (
                  <div className="radio-field" key={value} onClick={() => setRewardType(value)}>
                    <img
                      src={rewardType === value ? RadioSelectedIcon : RadioUnselectedIcon}
                      alt={value}
                    />
                    <p>{label}</p>
                  </div>
                ))}
              </div>
            )}
            <div className="several-fields">
              {rewardType !== "bonus_cash" && (
                <div style={{ width: "50%" }}>
                  <Controller
                    name="reward_money"
                    control={control}
                    render={props => (
                      <InputField
                        id="name-input"
                        label="Euro amount"
                        type="text"
                        {..._.omit(props.field, "ref")}
                        inputRef={props.ref}
                        error={errors.reward_money?.message}
                        disabled={is_ongoing}
                      />
                    )}
                  />
                </div>
              )}

              {rewardType !== "euro" && (
                <div style={{ width: "50%" }}>
                  <Controller
                    name="reward_bonus_cash"
                    control={control}
                    render={props => (
                      <InputField
                        id="name-input"
                        label="Bonus Cash amount"
                        type="text"
                        {..._.omit(props.field, "ref")}
                        inputRef={props.ref}
                        error={errors.reward_bonus_cash?.message}
                        disabled={is_ongoing}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!is_ongoing && (
        <div className="challenges-fields__buttons-wrapper">
          {id ? (
            <DefaultButton variant="contained" disabled={disabled} classes="green-btn" formAction>
              Save changes
            </DefaultButton>
          ) : (
            <>
              <DefaultButton
                variant="outlined"
                onClick={() => onButtonClick(false)}
                disabled={disabled}
                classes="green-btn"
                formAction
              >
                Create unpublished
              </DefaultButton>
              <DefaultButton
                variant="contained"
                onClick={() => onButtonClick(true)}
                disabled={disabled}
                classes="green-btn"
                formAction
              >
                Create & Publish
              </DefaultButton>
            </>
          )}
        </div>
      )}
      <BackDrop open={buttonLoading} />
    </form>
  );
};

DailyFields.defaultProps = defaultProps;

export default DailyFields;
