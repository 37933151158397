import APP from "./appActionTypes";

const INITIAL_STATE = {
  loading: false,
  buttonLoading: false,
  errorSnack: false,
  errorSnackText: "",
  successSnack: "",
  successSnackText: "",
  widePanel: localStorage.getItem("wide-panel") !== "false",
  confirmationDialog: {
    open: false,
    onClose: () => {},
    confirmAction: () => {},
    declineAction: () => {},
    title: "",
    description: "",
    confirmText: "",
    declineText: ""
  },
  unresolvedDisputesCount: 0
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case APP.LOADING:
      return { ...state, loading: action.payload };
    case APP.BUTTON_LOADING:
      return { ...state, buttonLoading: action.payload };
    case APP.ERROR_SNACK_OPEN:
      return { ...state, errorSnack: true, errorSnackText: action.payload };
    case APP.ERROR_SNACK_CLOSE:
      return { ...state, errorSnack: false };
    case APP.SUCCESS_SNACK_OPEN:
      return { ...state, successSnack: true, successSnackText: action.payload };
    case APP.SUCCESS_SNACK_CLOSE:
      return { ...state, successSnack: false };
    case APP.CHANGE_PANEL_STATE:
      localStorage.setItem("wide-panel", !state.widePanel);
      return { ...state, widePanel: !state.widePanel };
    case APP.SET_CONFIRMATION_DIALOG:
      return { ...state, confirmationDialog: action.confirmationDialog };
    case APP.CLOSE_CONFIRMATION_DIALOG:
      return { ...state, confirmationDialog: { ...state.confirmationDialog, open: false } };
    case APP.SET_SOCKET_DATA:
      if (action.adminWSType === "tournament_disputes_count_unresolved") {
        return {
          ...state,
          unresolvedDisputesCount: action.adminWSData.count
        };
      }
      return state;
    default:
      return state;
  }
}
