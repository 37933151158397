import React, { useEffect, useRef, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import dayjs from "dayjs";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import Lightbox from "react-images";
import { Scrollbars } from "react-custom-scrollbars";
import Chip from "@material-ui/core/Chip";

import PopoverComponent from "../../../_shared/PopoverComponent";
import Preloader from "../../../_shared/Preloader";
import { renderThumb, renderTrack } from "./helpers";
import { API_SOCKET_BASE_URL } from "../../../../core/config";
import Message from "./Message";
import {
  getDisputeMessages,
  postChatImage,
  updateDisputeMessageList
} from "../../../Tournaments/actions";

import SendIcon from "../../../../assets/image/send-message.svg";
import AttachmentIcon from "../../../../assets/image/attachment.svg";
import SmileIcon from "../../../../assets/image/smile.svg";
import SmileActiveIcon from "../../../../assets/image/smile_active.svg";

import "./styles.scss";

const DisputeChat = ({ disputeID }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const ws = useRef(null);
  const reconnect = useRef(null);
  const scrollbar = useRef(null);
  const dispatch = useDispatch();
  const {
    disputeMessages: { load, results, next },
    disputeInfo: { resolved }
  } = useSelector(({ tournaments }) => tournaments);
  const active = Boolean(anchorEl);

  const maxHeight = "630px";

  const onWSClose = e => {
    if (e.wasClean) {
      console.log("dispute ws closed - was clean");
    } else {
      console.log("dispute ws closed - was not clean");
      reconnect.current = setTimeout(() => connectSocket(), 5000);
    }
  };

  const onWSMessage = e => {
    let data = JSON.parse(e.data);
    const { id, text, create_date, image, room_id, author } = data.data;
    let obj = {
      id,
      text,
      create_date,
      is_mine: data.type === "echo",
      image: image || null,
      room_id: Number(room_id),
      author
    };
    if (data.type === "echo") {
      dispatch(updateDisputeMessageList(obj));
      setValue("");
      scrollbar.current.scrollToBottom();
    } else if (data.type === "error") {
      alert(data.data.message);
    } else {
      dispatch(updateDisputeMessageList(obj));
      ws.current.send(
        JSON.stringify({
          type: "mark_read",
          data: {
            message_id: data.data.id
          }
        })
      );
      if (scrollbar.current) {
        console.log("dispute scroll");
        let obj = scrollbar.current.getValues();
        if (obj.scrollHeight - (obj.scrollTop + obj.clientHeight) < 400) {
          scrollbar.current.scrollToBottom();
        }
      }
    }
  };

  const connectSocket = () => {
    ws.current = new WebSocket(`${API_SOCKET_BASE_URL}/dispute/?token=${localStorage.token}`);
    ws.current.onopen = () => console.log("dispute ws opened");
    ws.current.onmessage = onWSMessage;
    ws.current.onclose = () => onWSClose;
    ws.current.onerror = e => console.log("dispute ws error " + JSON.stringify(e));
  };

  const fetchDisputeMessages = (query, scrolled) => {
    dispatch(getDisputeMessages(query)).then(() => {
      if (scrollbar.current) {
        if (scrolled) {
          scrollbar.current.scrollTop(scrollbar.current.getScrollHeight() - scrolled);
        } else {
          scrollbar.current.scrollToBottom();
        }
      }
    });
  };

  useEffect(() => {
    connectSocket();
    fetchDisputeMessages(`?dispute_id=${disputeID}`, false);
    return () => {
      ws.current.close();
      clearTimeout(reconnect.current);
    };
  }, []);

  const sendSocket = image_id => {
    if (errorMessage !== null) setErrorMessage(null);
    let messageObj = {
      type: "private_message",
      data: {
        message: value,
        to_dispute: disputeID
      }
    };
    if (image_id) messageObj.data.image_id = image_id;
    ws.current.send(JSON.stringify(messageObj));
  };

  const sendMessage = () => {
    if (file !== null) {
      const formData = new FormData();
      formData.append("image", file);
      dispatch(postChatImage(formData)).then(res => {
        if (res.payload && res.payload.status && res.payload.status === 201) {
          sendSocket(res.payload.data.id);
          setFile(null);
        } else if (res.error && res.error.response && res.error.response.status === 400) {
          if (res.error.response.data.image && res.error.response.data.image[0]) {
            setErrorMessage(res.error.response.data.image[0]);
          }
        }
      });
    } else {
      sendSocket();
    }
  };

  const onKeyDown = e => {
    if (e.keyCode === 13) sendMessage();
  };

  const onChange = e => {
    let value = e.target.value;
    if (value.length <= 2000) {
      setValue(e.target.value);
      setErrorMessage(null);
    } else {
      setErrorMessage(t("t:shared.message-restriction"));
    }
  };

  const handleScroll = e => {
    if (e.target.scrollTop === 0 && next) {
      let query = next.split("dispute-message/")[1];
      fetchDisputeMessages(query, e.target.scrollHeight);
    }
  };

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleImageChange = e => {
    e.preventDefault();
    let fileToSet = e.target.files[0];
    if (fileToSet) {
      setFile(fileToSet);
      setErrorMessage(null);
    }
  };

  const openImageBox = imageToSet => setImage(imageToSet);

  const closeImageBox = () => setImage(null);

  return (
    <div className="dispute_chat__body">
      <div className="dispute_chat__chat_messages_wrapper">
        {!load && <Preloader />}
        <Scrollbars
          onScroll={handleScroll}
          renderThumbVertical={renderThumb}
          renderTrackVertical={renderTrack}
          hideTracksWhenNotNeeded
          autoHeight
          autoHeightMax={maxHeight}
          ref={scrollbar}
        >
          {load && (
            <div className="chat_messages">
              {results.map((message, i) => {
                let lastIndex = results.length - 1;
                let timeNow = dayjs(message.create_date).format("YYYY:MM:DD");
                let timeNext = timeNow;
                if (i + 1 <= lastIndex) {
                  timeNext = dayjs(results[i + 1].create_date).format("YYYY:MM:DD");
                }
                return (
                  <Fragment key={message.id}>
                    <Message
                      avatar={message.author?.avatar}
                      openImageBox={openImageBox}
                      {...message}
                    />
                    {(timeNow !== timeNext || i === lastIndex) && (
                      <div className="messages_time_row">
                        {dayjs(message.create_date).format("MMMM DD, YYYY")}
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          )}
        </Scrollbars>
      </div>
      {load &&
        (resolved ? (
          <div className="dispute_resolved">
            <p>{t("t:tournaments.dispute-resolved-no-messages-allowed")}</p>
          </div>
        ) : (
          <div className="chat_panel">
            {file && (
              <Chip
                label={file.name}
                onDelete={() => {
                  setFile(null);
                  setErrorMessage(null);
                }}
                classes={{
                  root: "chat_image_preview"
                }}
              />
            )}
            <input id="chat_input" className="fileInput" type="file" onChange={handleImageChange} />
            <IconButton
              classes={{
                root: "chat_file_btn"
              }}
              onClick={() => document.getElementById("chat_input").click()}
            >
              <img src={AttachmentIcon} alt="attachment" />
            </IconButton>
            <div className="chat_field">
              <input
                type="text"
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={t("t:shared.type-message-here")}
                autoComplete="off"
              />
              <button className="emoji_btn" onClick={handleClick}>
                <img src={active ? SmileActiveIcon : SmileIcon} alt="smile" />
              </button>
              <IconButton
                classes={{
                  root: "chat_send_btn"
                }}
                onClick={sendMessage}
              >
                <img src={SendIcon} alt="send" />
              </IconButton>
              {errorMessage && <p className="chat_field_error">{errorMessage}</p>}
            </div>
          </div>
        ))}
      <Lightbox
        images={image ? [{ src: image }] : []}
        isOpen={!!image}
        onClose={closeImageBox}
        backdropClosesModal
        showImageCount={false}
      />
      <PopoverComponent
        anchor={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        classes="emoji_popover_wrapper"
      >
        <Picker onSelect={e => setValue(value + e.native)} />
      </PopoverComponent>
    </div>
  );
};

export default DisputeChat;
