let BASE_URL, SECOND_URL, SOCKET_BASE_URL;

const host = window.location.host;

if (host.includes("localhost")) {
  BASE_URL = process.env.REACT_APP_API_URL;
  SOCKET_BASE_URL = process.env.REACT_APP_API_SOCKET_URL;
} else {
  BASE_URL = process.env.REACT_APP_API_URL;
  SOCKET_BASE_URL = process.env.REACT_APP_API_SOCKET_URL;
}

SECOND_URL = "";

export const API_BASE_URL = BASE_URL;
export const API_SECOND_URL = SECOND_URL;
export const API_SOCKET_BASE_URL = SOCKET_BASE_URL;
