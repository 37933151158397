import REGISTRATIONS from "./actionTypes";
import { joinQueries } from "../../core/helpers/functions";

export function getRegistrations(queries) {
  return {
    type: REGISTRATIONS.GET_REGISTRATIONS,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/registrations/${joinQueries(queries)}`,
        method: "get"
      }
    }
  };
}

export function setRegistrationsMeta(meta) {
  return {
    type: REGISTRATIONS.SET_REGISTRATIONS_META,
    meta
  };
}

export function resetRegistrations() {
  return {
    type: REGISTRATIONS.RESET_REGISTRATIONS
  };
}
