import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

import { getCurrentTournamentDisputes } from "../../actions";
import { getDisputeState, getPlatformNameAndIcon } from "../../../../core/helpers/functions";
import IconButtonComponent from "../../../_shared/Buttons/IconButton";
import urls from "../../../../core/urls";

import ArrowIcon from "../../../../assets/image/go-to-arrow.svg";

const DisputesDialog = ({ tournamentID }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const disputes = useSelector(({ tournaments }) => tournaments.currentTournamentsDisputes);

  useEffect(() => {
    dispatch(getCurrentTournamentDisputes(tournamentID));
  }, []);

  const onGoToClick = id => history.push(urls.tournamentDisputeInnerUrl.replace(":id", id));

  return (
    <div className="disputes-dialog">
      <div className="disputes-dialog__title">
        {t("t:tournaments.disputes")}
        <p className="disputes-dialog__number">{disputes.length}</p>
      </div>
      <div className="disputes-dialog__disputes-wrapper with-scrollbar">
        {disputes.map(({ id, creation_date, resolved, user_1, user_2, platform }) => (
          <div key={`dispute-${id}`} className="disputes-dialog__dispute">
            <div className="disputes-dialog__dispute--header">
              <div className="disputes-dialog__dispute--info">
                {`#${id}`}
                <p className="disputes-dialog__dispute--date">
                  {dayjs(creation_date).format("DD.MM.YYYY HH:mm")}
                </p>
              </div>
              {getDisputeState(resolved, t)}
            </div>
            <div className="disputes-dialog__dispute--users-wrapper">
              <div className="disputes-dialog__dispute--users">
                <div className="disputes-dialog__dispute--user">
                  <img
                    src={user_1?.avatar}
                    alt="user 1"
                    className="disputes-dialog__dispute--user-avatar"
                  />
                  <div>
                    <div className="disputes-dialog__dispute--user-name">
                      <p>{`${user_1?.first_name} ${user_1?.last_name}`}</p>
                      {resolved && user_1?.result === "win" && (
                        <div className="disputes-dialog__dispute--winner">
                          {t("t:tournaments.winner")}
                        </div>
                      )}
                    </div>
                    {getPlatformNameAndIcon(user_1?.platform_username, platform?.type)}
                  </div>
                </div>
                <hr className="disputes-dialog__dispute--separator" />
                <div className="disputes-dialog__dispute--user">
                  <img
                    src={user_2?.avatar}
                    alt="user 2"
                    className="disputes-dialog__dispute--user-avatar"
                  />
                  <div>
                    <div className="disputes-dialog__dispute--user-name">
                      <p>{`${user_2?.first_name} ${user_2?.last_name}`}</p>
                      {resolved && user_2?.result === "win" && (
                        <div className="disputes-dialog__dispute--winner">
                          {t("t:tournaments.winner")}
                        </div>
                      )}
                    </div>
                    {getPlatformNameAndIcon(user_2?.platform_username, platform?.type)}
                  </div>
                </div>
              </div>
              <IconButtonComponent
                onClick={() => onGoToClick(id)}
                alt="arrow"
                src={ArrowIcon}
                classes="go-to-btn"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DisputesDialog;
