import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { useQueryParams } from "../../core/helpers/hooks";
import BackLink from "../_shared/BackLink";
import urls from "../../core/urls";
import Tabs from "../_shared/Tabs";
import { userProfileTabs } from "./constants";
import Loader from "../_shared/Loader";
import { getProfileGeneral, resetUserProfile } from "./actions";

import ProfileHeader from "./ProfileHeader";
import General from "./General";
import Platforms from "./Platforms";
import Affiliate from "./Affiliate";
import KYC from "./KYC";
import SkillChallenges from "./SkillChallenges";
import Disputes from "./Disputes";

import "./styles.scss";

const UserProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { getParam, queries } = useQueryParams();
  const { id: userID } = useParams();
  const [tab, setTab] = useState(getParam("tab"));
  const {
    general: { initial: generalInitial }
  } = useSelector(({ userProfile }) => userProfile);
  const isLoading = Boolean(generalInitial);

  const onTabChange = newTab =>
    history.push(`${urls.userProfileUrl.replace(":id", userID)}?tab=${newTab}`);

  useEffect(() => {
    dispatch(getProfileGeneral(userID));
    return () => {
      dispatch(resetUserProfile());
    };
  }, []);

  useEffect(() => {
    if (tab !== getParam("tab")) {
      setTab(getParam("tab"));
    }
  }, [queries]);

  const renderLoader = () => (
    <div className="user-profile__content-wrapper justify-center">
      <Loader />
    </div>
  );

  const tabContent = useMemo(() => {
    if (isLoading) return renderLoader();
    switch (tab) {
      case "general":
        return <General />;
      case "platforms":
        return <Platforms renderLoader={renderLoader} />;
      case "affiliate":
        return <Affiliate renderLoader={renderLoader} />;
      case "kyc":
        return <KYC renderLoader={renderLoader} />;
      case "skill_challenges":
        return <SkillChallenges renderLoader={renderLoader} />;
      case "disputes":
        return <Disputes />;
      default:
        return null;
    }
  }, [tab, isLoading]);

  return (
    <div className="default-content-wrapper user-profile">
      <BackLink to={urls.usersUrl} hard />
      <ProfileHeader />
      <Tabs currentTab={tab} tabs={userProfileTabs} onTabChange={onTabChange} />
      {tabContent}
    </div>
  );
};

export default UserProfile;
