import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import Pagination from "../../_shared/Pagination";
import NoItems from "../../_shared/NoItems";
import { statisticsTableCols } from "../constants";
import { getSkillChallengesStatistics, setStatisticsMeta } from "../actions";
import StatisticsItem from "./StatisticsItem";

const Statistics = () => {
  const dispatch = useDispatch();
  const {
    statistics: { results, count },
    statisticsMeta
  } = useSelector(({ skillChallenges }) => skillChallenges);

  const prepareParamsArray = () => Object.entries(statisticsMeta).map(obj => `${obj[0]}=${obj[1]}`);

  const setPage = page => dispatch(setStatisticsMeta({ page }));
  const setOrdering = ordering => dispatch(setStatisticsMeta({ ordering }));

  const fetchStatistics = async () => {
    const res = await dispatch(getSkillChallengesStatistics(prepareParamsArray(statisticsMeta)));
    if (res.error) setPage(1);
  };

  useEffect(() => {
    fetchStatistics();
  }, [JSON.stringify(statisticsMeta)]);

  return (
    <>
      <section className="skill-challenges__statistics-list">
        {results.length ? (
          <div className="skill-challenges__statistics-user-row header-row">
            {statisticsTableCols.map(({ label, value }) => (
              <div
                className={clsx(
                  "skill-challenges__statistics-user-row--col header-col",
                  (statisticsMeta.ordering === value || statisticsMeta.ordering === `-${value}`) &&
                    "active-sort"
                )}
                key={value}
                onClick={() => setOrdering(statisticsMeta.ordering === value ? `-${value}` : value)}
              >
                {label}
                <div
                  className={clsx(
                    "sort-arrow with-transition",
                    statisticsMeta.ordering === value && "active",
                    statisticsMeta.ordering === `-${value}` && "active-rotated"
                  )}
                />
              </div>
            ))}
          </div>
        ) : (
          <NoItems />
        )}
        {results.map(({ id, ...rest }) => (
          <StatisticsItem key={`statistics_${id}`} {...rest} />
        ))}
      </section>
      {count > 0 && <Pagination onChange={setPage} total={count} page={statisticsMeta.page} />}
    </>
  );
};

export default Statistics;
