import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProfilePlatforms } from "../actions";
import { renderPlatformWithStatus } from "../helpers";
import DefaultButton from "../../_shared/Buttons/DefaultButton";
import PlatformFields from "./PlatformFields";

const Platforms = ({ renderLoader }) => {
  const [editingID, setEditingID] = useState(null);
  const dispatch = useDispatch();
  const {
    general: { id: userID },
    platforms
  } = useSelector(({ userProfile }) => userProfile);

  useEffect(() => {
    dispatch(getProfilePlatforms(userID));
  }, []);

  if (!platforms) return renderLoader();

  const onEditClick = platformID => setEditingID(platformID === editingID ? null : platformID);

  return (
    <div className="user-profile__content-wrapper user-profile__platforms">
      {Object.entries(platforms).map(el => {
        const { status, profile_id, id } = el[1];
        const isConnected = status !== "not_connected";
        return (
          <div className="user-profile__platforms--platform" key={el[0]}>
            <div className="justify-between">
              {renderPlatformWithStatus(el[0], el[1].status)}
              <div className="flex-center">
                {isConnected && (
                  <p className="user-profile__platforms--platform-profile-id">{profile_id}</p>
                )}
                {isConnected ? (
                  <DefaultButton
                    variant="outlined"
                    type="button"
                    onClick={() => onEditClick(id)}
                    classes="blue-btn"
                  >
                    Edit
                  </DefaultButton>
                ) : (
                  <DefaultButton
                    variant="outlined"
                    onClick={() => onEditClick(id)}
                    classes="green-btn"
                  >
                    + Add
                  </DefaultButton>
                )}
              </div>
            </div>
            {id === editingID && (
              <PlatformFields
                platformID={id}
                status={status}
                profileID={profile_id}
                onEditClick={onEditClick}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Platforms;
