import React from "react";

const HeaderRow = ({ cols }) => {
  return (
    <div className="challenges-system__challenge-row header-row">
      {cols.map(({ label, value }) => (
        <div className="challenges-system__challenge-row--col header-col" key={value}>
          {label}
        </div>
      ))}
    </div>
  );
};

export default HeaderRow;
