import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Lightbox from "react-images";
import clsx from "clsx";

import BackLink from "../../_shared/BackLink";
import urls from "../../../core/urls";
import {
  resetTournamentDisputeInner,
  getTournamentDisputeInner,
  postSetTournamentDisputeWinner
} from "../../Tournaments/actions";
import {
  getDisputeState,
  getGameInfo,
  getTournamentStageByDepthIndex
} from "../../../core/helpers/functions";
import Skeleton from "../../_shared/Skeleton";
import DefaultButton from "../../_shared/Buttons/DefaultButton";
import Dialog from "../../_shared/Dialog";
import { renderOpponentInfo, renderScreenshotRow, renderUserIconAndName } from "./helpers";
import DisputeChat from "./DisputeChat";

import RoundOfIcon from "../../../assets/image/round-of.svg";

import "./styles.scss";

const TournamentDisputeInner = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: disputeID } = useParams();
  const [image, setImage] = useState(null);
  const [chooseWinnerDialog, setChooseWinnerDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const {
    disputeInfo: {
      creation_date,
      resolved,
      game,
      platform,
      initial,
      tournament_match,
      agent,
      user_1,
      user_2
    },
    submittingWinner
  } = useSelector(({ tournaments }) => tournaments);

  const fetchDisputeInfo = () => dispatch(getTournamentDisputeInner(disputeID));

  useEffect(() => {
    fetchDisputeInfo();
    return () => {
      dispatch(resetTournamentDisputeInner());
    };
  }, []);

  const openImageBox = imageToSet => setImage(imageToSet);

  const closeImageBox = () => setImage(null);

  const handleChooseWinnerDialog = () => setChooseWinnerDialog(!chooseWinnerDialog);

  const onConfirmWinnerClick = async () => {
    await dispatch(postSetTournamentDisputeWinner(disputeID, { winner: selectedUser }));
    fetchDisputeInfo();
    handleChooseWinnerDialog();
  };

  return (
    <div className="tournament-dispute-inner">
      <div className="tournament-dispute-inner__header-wrapper">
        <BackLink to={urls.tournamentDisputesUrl} />
        <div className="tournament-dispute-inner__header-wrapper--info-wrapper">
          <div>
            <Skeleton loading={initial} variant="text" width={100}>
              <div className="tournament-dispute-inner__header-wrapper--number">{disputeID}</div>
            </Skeleton>
            <Skeleton loading={initial} variant="text" width={200}>
              <div className="tournament-dispute-inner__header-wrapper--date-and-status">
                <div className="tournament-dispute-inner__header-wrapper--date">
                  {dayjs(creation_date).format("DD.MM.YYYY HH:mm")}
                </div>
                <div className="tournament-dispute-inner__header-wrapper--dot-separator">•</div>
                <div>{getDisputeState(resolved, t)}</div>
              </div>
            </Skeleton>
          </div>
          <div>
            <div className="tournament-dispute-inner__header-wrapper--tournament-and-game">
              <div className="tournament-dispute-inner__header-wrapper--tournament-info">
                <Skeleton loading={initial} variant="text" width={130}>
                  <div className="tournament-dispute-inner__header-wrapper--tournament-name">
                    {tournament_match?.tournament?.title}
                  </div>
                </Skeleton>
                <Skeleton loading={initial} variant="text" width={90}>
                  <div className="tournament-dispute-inner__header-wrapper--tournament-round">
                    <img className="round-icon" src={RoundOfIcon} alt="state" />
                    {getTournamentStageByDepthIndex(tournament_match?.depth_index, t)}
                  </div>
                </Skeleton>
              </div>
              {getGameInfo(game, platform, initial)}
            </div>
          </div>
        </div>
      </div>
      <section className="tournament-dispute-inner__content-wrapper">
        <div className="tournament-dispute-inner__content-wrapper--left-column">
          <div className="tournament-dispute-inner__participants">
            <div className="tournament-dispute-inner__participants--title">
              {t("t:tournaments.participants")}
            </div>
            <div className="tournament-dispute-inner__participants--subtitle">
              {t("t:tournaments.agent")}
            </div>
            <div className="tournament-dispute-inner__participants--user-info">
              {renderUserIconAndName(agent, initial, true)}
            </div>
            <div className="tournament-dispute-inner__participants--separator" />
            <div className="tournament-dispute-inner__participants--subtitle">
              {t("t:tournaments.opponents")}
            </div>
            {renderOpponentInfo(user_1, platform, initial, resolved, t, "mb-12")}
            {renderOpponentInfo(user_2, platform, initial, resolved, t)}
            <div className="tournament-dispute-inner__participants--separator" />
            <Skeleton loading={initial} variant="rect" width={160} height={40} classes="mr-auto">
              {resolved ? (
                <div className="tournament-dispute-inner__participants--resolved">
                  {t("t:tournaments.dispute-resolved")}
                </div>
              ) : (
                <DefaultButton
                  variant="contained"
                  onClick={handleChooseWinnerDialog}
                  classes="green-btn"
                >
                  {t("t:tournaments.resolve-dispute")}
                </DefaultButton>
              )}
            </Skeleton>
          </div>
          <div className="tournament-dispute-inner__screenshots">
            <div className="tournament-dispute-inner__screenshots--title">
              {t("t:tournaments.screenshots")}
            </div>
            {renderScreenshotRow(user_1, platform, initial, openImageBox)}
            <div className="tournament-dispute-inner__screenshots--separator" />
            {renderScreenshotRow(user_2, platform, initial, openImageBox)}
          </div>
        </div>
        <div className="tournament-dispute-inner__content-wrapper--right-column">
          <div className="tournament-dispute-inner__chat">
            <DisputeChat disputeID={disputeID} />
          </div>
        </div>
      </section>
      <Lightbox
        images={image ? [{ src: image }] : []}
        isOpen={!!image}
        onClose={closeImageBox}
        backdropClosesModal
        showImageCount={false}
      />
      <Dialog open={chooseWinnerDialog} onClose={handleChooseWinnerDialog}>
        <div className="confirmation-dialog choose-winner-dialog">
          <h2 className="title">{t("t:tournaments.choose-winner")}</h2>
          <div className="description">{t("t:tournaments.confirm-winner-choosing")}</div>
          <div
            className={clsx(
              "choose-winner-dialog__opponent-row",
              selectedUser === user_1?.id && "active"
            )}
            onClick={() => setSelectedUser(user_1?.id)}
          >
            {renderUserIconAndName(user_1, initial)}
          </div>
          <div
            className={clsx(
              "choose-winner-dialog__opponent-row",
              selectedUser === user_2?.id && "active"
            )}
            onClick={() => setSelectedUser(user_2?.id)}
          >
            {renderUserIconAndName(user_2, initial)}
          </div>
          <hr className="separator" />
          <div className="buttons-wrapper">
            <DefaultButton
              variant="contained"
              onClick={handleChooseWinnerDialog}
              classes="grey-btn"
            >
              {t("t:shared.cancel")}
            </DefaultButton>
            <DefaultButton
              variant="contained"
              onClick={onConfirmWinnerClick}
              classes="green-btn"
              loading={submittingWinner}
              disabled={!selectedUser}
            >
              {t("t:shared.confirm")}
            </DefaultButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default TournamentDisputeInner;
