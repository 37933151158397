import * as yup from "yup";
import { positiveOrZeroNumberValidation } from "../../../core/helpers/validations";

const skillChallengesSchema = yup.object().shape({
  is_boy_stake_limit_enabled: yup.bool(),
  is_auto_boy_stake_limit_enabled: yup.bool(),
  boy_stake_limit: positiveOrZeroNumberValidation()
});

export default skillChallengesSchema;
