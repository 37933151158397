import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router";

import urls from "../../core/urls";
import GLoader from "../_shared/GLoader";
import { getTournamentsSelectOptions } from "./actions";

import TournamentsList from "./TournamentsList";
import AddNewTournament from "./AddNewTournament";
import EditTournament from "./EditTournament";
import TournamentInner from "./TournamentInner";

const TournamentsContainer = ({ selectOptions, getTournamentsSelectOptions }) => {
  useEffect(() => {
    getTournamentsSelectOptions();
  }, []);

  if (selectOptions.loading) return <GLoader />;
  return (
    <Switch>
      <Route path={urls.tournamentsUrl} exact component={TournamentsList} />
      <Route path={urls.addNewTournament} exact component={AddNewTournament} />
      <Route path={urls.editTournamentUrl} exact component={EditTournament} />
      <Route path={urls.tournamentUrl} exact component={TournamentInner} />
      <Route render={() => <Redirect to={urls.tournamentsUrl} />} />
    </Switch>
  );
};

const mapStateToProps = ({ tournaments }) => {
  return {
    selectOptions: tournaments.selectOptions
  };
};

const mapDispatchToProps = {
  getTournamentsSelectOptions
};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentsContainer);
