import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import AppReducer from "./appReducer";
import AuthReducer from "../components/Auth/reducer";
import TournamentsReducer from "../components/Tournaments/reducer";
import UsersReducer from "../components/Users/reducer";
import UserProfileReducer from "../components/UserProfile/reducer";
import SkillChallengesReducer from "../components/SkillChallenges/reducer";
import ChallengesSystemReducer from "../components/ChallengesSystem/reducer";
import RegistrationsReducer from "../components/Registrations/reducer";

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    auth: AuthReducer,
    app: AppReducer,
    tournaments: TournamentsReducer,
    users: UsersReducer,
    userProfile: UserProfileReducer,
    skillChallenges: SkillChallengesReducer,
    challengesSystem: ChallengesSystemReducer,
    registrations: RegistrationsReducer
  });

export default rootReducer;
