import React from "react";

export const renderThumb = ({ style, ...props }) => {
  let thumbStyle = {
    width: "10px"
  };
  return <div className="custom_scrollbar_thumb" style={{ ...style, ...thumbStyle }} {...props} />;
};

export const renderTrack = ({ style, ...props }) => {
  let trackStyle = {
    top: "2px",
    right: "2px",
    bottom: "2px",
    width: "10px"
  };
  return <div className="custom_scrollbar_track" style={{ ...style, ...trackStyle }} {...props} />;
};
