import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import Pagination from "../../_shared/Pagination";
import SearchBy from "../../_shared/SearchBy";
import NoItems from "../../_shared/NoItems";
import DefaultButton from "../../_shared/Buttons/DefaultButton";
import Skeleton from "../../_shared/Skeleton";
import { connectionTableCols, connectionInitialFilters } from "../constants";
import { getSkillChallengesConnection, setConnectionMeta } from "../actions";
import ConnectionItem from "./ConnectionItem";
import Filters from "./Filters";

import FiltersIcon from "../../../assets/image/filters.svg";

const Connection = () => {
  const [filtersIsOpen, setFiltersIsOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    connection: { results, count, filter_options },
    connectionMeta,
    reloadConnection
  } = useSelector(({ skillChallenges }) => skillChallenges);

  const prepareParamsArray = () => Object.entries(connectionMeta).map(obj => `${obj[0]}=${obj[1]}`);

  const fetchUsers = async () => {
    const res = await dispatch(getSkillChallengesConnection(prepareParamsArray(connectionMeta)));
    if (res.error) setPage(1);
  };

  useEffect(() => {
    fetchUsers();
  }, [JSON.stringify(connectionMeta)]);

  useEffect(() => {
    if (reloadConnection) fetchUsers();
  }, [reloadConnection]);

  const setPage = page => dispatch(setConnectionMeta({ page }));
  const setOrdering = ordering => dispatch(setConnectionMeta({ ordering }));
  const setSearch = search => dispatch(setConnectionMeta({ search }));

  const handleFiltersPanel = () => setFiltersIsOpen(!filtersIsOpen);
  const onFiltersReset = () => dispatch(setConnectionMeta(connectionInitialFilters));

  const numberOfFilters = useMemo(
    () =>
      Object.entries(connectionInitialFilters).reduce((acc, el) => {
        if (connectionMeta[el[0]] !== "") return acc + 1;
        return acc;
      }, 0),
    [connectionMeta]
  );

  return (
    <>
      <div className="search-and-filters">
        <SearchBy placeholder="Search by game, username or email" onSearch={setSearch} />
        <Skeleton
          loading={!filter_options}
          variant="rect"
          width={118}
          height={42}
          classes="border-radius-22 ml-32"
        >
          <DefaultButton
            variant="outlined"
            type="button"
            onClick={handleFiltersPanel}
            classes="blue-btn"
          >
            <img src={FiltersIcon} alt="filters" className="mr-8" />
            Filters
            {!!numberOfFilters && (
              <div className="search-and-filters__filters-number">{numberOfFilters}</div>
            )}
          </DefaultButton>
        </Skeleton>
        {!!numberOfFilters && (
          <div className="users__filters--reset text-hover" onClick={onFiltersReset}>
            Reset all filters
          </div>
        )}
      </div>
      <section className="skill-challenges__connection-list">
        {results.length ? (
          <div className="skill-challenges__connection-user-row header-row">
            {connectionTableCols.map(({ label, value }) => (
              <div
                className={clsx(
                  "skill-challenges__connection-user-row--col header-col",
                  (connectionMeta.ordering === value || connectionMeta.ordering === `-${value}`) &&
                    "active-sort"
                )}
                key={value}
                onClick={() => setOrdering(connectionMeta.ordering === value ? `-${value}` : value)}
              >
                {label}
                <div
                  className={clsx(
                    "sort-arrow with-transition",
                    connectionMeta.ordering === value && "active",
                    connectionMeta.ordering === `-${value}` && "active-rotated"
                  )}
                />
              </div>
            ))}
            <div className="skill-challenges__connection-user-row--col header-col" />
          </div>
        ) : (
          <NoItems />
        )}
        {results.map(user => (
          <ConnectionItem key={`user_${user.id}`} {...user} />
        ))}
      </section>
      {count > 0 && <Pagination onChange={setPage} total={count} page={connectionMeta.page} />}
      {filter_options && (
        <Filters
          filterOptions={filter_options}
          handleFiltersPanel={handleFiltersPanel}
          filtersIsOpen={filtersIsOpen}
        />
      )}
    </>
  );
};

export default Connection;
