import React, { useMemo, memo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import Skeleton from "../../_shared/Skeleton";
import urls from "../../../core/urls";
import { getUserInListInfo, selectUsers } from "../actions";
import MoreUserInfo from "../MoreUserInfo";
import UserActionsPopover from "../UserActionsPopover";

import ActiveIcon from "../../../assets/image/is_active.svg";
import InactiveIcon from "../../../assets/image/is_inactive.svg";
import BlockedIcon from "../../../assets/image/is_blocked.svg";
import UncheckedIcon from "../../../assets/image/unchecked.svg";
import CheckedIcon from "../../../assets/image/checked.svg";
import ExpandIcon from "../../../assets/image/expand-white-arrow.svg";

const UserItem = ({
  id,
  loading = false,
  username,
  first_name,
  last_name,
  is_active,
  is_blocked,
  email,
  phone,
  balance,
  avatar,
  withAdditionalInfo,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { selectedUsers } = useSelector(({ users }) => users);
  const isSelected = useMemo(() => selectedUsers.includes(id), [selectedUsers]);

  const onSelect = () => dispatch(selectUsers([id], isSelected));

  const onAdditionalInfoClick = () => {
    if (!withAdditionalInfo) dispatch(getUserInListInfo(id));
  };

  return (
    <Accordion
      classes={{
        root: "accordion-root",
        expanded: "accordion-expanded"
      }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        onClick={onAdditionalInfoClick}
        expandIcon={<img src={ExpandIcon} alt="expand" />}
        classes={{
          root: "accordion-panel-root",
          expandIcon: "accordion-expand-icon"
        }}
      >
        <div className="users__user-row" onClick={e => e.stopPropagation()}>
          <div className="users__user-row--col">
            <img src={isSelected ? CheckedIcon : UncheckedIcon} alt="check" onClick={onSelect} />
          </div>
          <div className="users__user-row--col">
            <Skeleton loading={loading} variant="text" width={80}>
              <Link
                to={`${urls.userProfileUrl.replace(":id", id)}?tab=general`}
                className="text-hover"
              >{`#${id}`}</Link>
            </Skeleton>
          </div>
          <div className="users__user-row--col text-overflow">
            <Skeleton loading={loading} variant="circle" width={36} height={36}>
              <img src={avatar} alt="user" className="users__user-row--avatar" />
            </Skeleton>
            <div className="ml-12">
              <Skeleton loading={loading} variant="text" width={110}>
                <p className="users__user-row--username">
                  <span
                    className={clsx(is_blocked && "is_blocked")}
                  >{`${first_name} ${last_name}`}</span>
                  <img
                    src={is_active ? ActiveIcon : InactiveIcon}
                    alt="is_active"
                    className="users__user-row--is-active"
                  />
                  {is_blocked && <img src={BlockedIcon} alt="blocked" />}
                </p>
              </Skeleton>
              <Skeleton loading={loading} variant="text" width={80}>
                <p className={clsx("users__user-row--user-name", is_blocked && "is_blocked")}>
                  {username}
                </p>
              </Skeleton>
            </div>
          </div>
          <div className="users__user-row--col text-overflow">
            <Skeleton loading={loading} variant="text" width={200}>
              <span className={clsx("table-text", is_blocked && "is_blocked")}>{email}</span>
            </Skeleton>
          </div>
          <div className="users__user-row--col">
            <Skeleton loading={loading} variant="text" width={120}>
              <span className={clsx("table-text", is_blocked && "is_blocked")}>{phone || "-"}</span>
            </Skeleton>
          </div>
          <div className="users__user-row--col">
            <Skeleton loading={loading} variant="text" width={70}>
              <span
                className={clsx("table-text", is_blocked && "is_blocked")}
              >{`€${balance?.toFixed(2)}`}</span>
            </Skeleton>
          </div>
          <div className="users__user-row--col">
            <UserActionsPopover
              id={id}
              loading={loading}
              is_blocked={is_blocked}
              is_active={is_active}
            />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <MoreUserInfo {...rest} />
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(UserItem);
