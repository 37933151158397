import USER_PROFILE from "./actionTypes";
import USERS from "../Users/actionTypes";

const INITIAL_STATE = {
  general: { initial: true },
  platforms: null,
  affiliate: { initial: true },
  kyc: { initial: true },
  skillChallenges: { initial: true },
  disputes: {
    results: {
      resolved: Array.from({ length: 8 }, (v, k) => ({ id: k, loading: true })),
      pending: Array.from({ length: 2 }, (v, k) => ({ id: k, loading: true }))
    }
  }
};

export default function(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case USER_PROFILE.GET_PROFILE_GENERAL_SUCCESS:
      return { ...state, general: payload.data };
    case USER_PROFILE.RESET_USER:
      return INITIAL_STATE;

    case USER_PROFILE.PATCH_PROFILE_GENERAL_SUCCESS:
    case USER_PROFILE.POST_ADD_BALANCE_SUCCESS:
    case USER_PROFILE.POST_ADD_BONUS_CASH_SUCCESS:
      return { ...state, general: { ...state.general, ...payload.data } };
    case USERS.POST_ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        general: { ...state.general, is_active: true }
      };
    case USERS.POST_DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        general: { ...state.general, is_active: false }
      };
    case USERS.POST_BLOCK_USER_SUCCESS:
      return {
        ...state,
        general: { ...state.general, is_blocked: true }
      };
    case USERS.POST_UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        general: { ...state.general, is_blocked: false }
      };

    case USER_PROFILE.GET_PROFILE_PLATFORMS_SUCCESS:
      return { ...state, platforms: payload.data };
    case USER_PROFILE.POST_DISCONNECT_PROFILE_PLATFORM_SUCCESS:
      return { ...state, platforms: payload.data };

    case USER_PROFILE.GET_PROFILE_AFFILIATE_SUCCESS:
      return { ...state, affiliate: payload.data };
    case USER_PROFILE.PATCH_PROFILE_AFFILIATE_SUCCESS:
      return { ...state, affiliate: { ...state.affiliate, ...payload.data } };

    case USER_PROFILE.GET_PROFILE_KYC_SUCCESS:
      return { ...state, kyc: payload.data };
    case USER_PROFILE.PATCH_PROFILE_KYC_SUCCESS:
      return { ...state, kyc: { ...state.kyc, ...payload.data } };

    case USER_PROFILE.GET_PROFILE_SKILL_CHALLENGES_SUCCESS:
      return { ...state, skillChallenges: payload.data };
    case USER_PROFILE.PATCH_PROFILE_SKILL_CHALLENGES_SUCCESS:
      return { ...state, skillChallenges: { ...state.skillChallenges, ...payload.data } };

    case USER_PROFILE.GET_PROFILE_DISPUTES_SUCCESS:
      return { ...state, disputes: payload.data };
    default:
      return state;
  }
}
