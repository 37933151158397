import dayjs from "dayjs";
import REGISTRATIONS from "./actionTypes";

const INITIAL_STATE = {
  registrations: { total: {}, data: { android: [], ios: [], unknown: [], web: [] } },
  meta: {
    from_date: dayjs().subtract(2, "year"),
    to_date: dayjs(),
    period: "day"
  },
  isFetched: false
};

export default function(state = INITIAL_STATE, { type, payload, meta }) {
  switch (type) {
    case REGISTRATIONS.GET_REGISTRATIONS_SUCCESS:
      return { ...state, registrations: payload.data, isFetched: true };
    case REGISTRATIONS.SET_REGISTRATIONS_META:
      return { ...state, meta: { ...state.meta, ...meta } };
    case REGISTRATIONS.RESET_REGISTRATIONS:
      return INITIAL_STATE;
    default:
      return state;
  }
}
