import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { setUsersMeta } from "../actions";
import IconButtonComponent from "../../_shared/Buttons/IconButton";
import DefaultButton from "../../_shared/Buttons/DefaultButton";

import CloseIcon from "../../../assets/image/close.svg";
import RadioSelectedIcon from "../../../assets/image/radio-selected.svg";
import RadioUnselectedIcon from "../../../assets/image/radio-unselected.svg";
import { filterOptions, initialFilters } from "../constants";

const Filters = ({ handleFiltersPanel, filtersIsOpen }) => {
  const [filters, setFilters] = useState(initialFilters);
  const dispatch = useDispatch();
  const { meta } = useSelector(({ users }) => users);

  useEffect(() => {
    if (JSON.stringify(filters) !== JSON.stringify(initialFilters)) {
      if (Object.entries(initialFilters).every(el => meta[el[0]] === el[1])) {
        setFilters(initialFilters);
      }
    }
  }, [meta]);

  const onFiltersChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const onApplySelectedClick = () => {
    dispatch(setUsersMeta(filters));
    handleFiltersPanel();
  };

  const onReset = () => {
    setFilters(initialFilters);
    dispatch(setUsersMeta(initialFilters));
    handleFiltersPanel();
  };

  return (
    <div className={clsx("filters with-transition no-user-select", filtersIsOpen && "is-open")}>
      <div className="filters--close-wrapper">
        <IconButtonComponent
          onClick={handleFiltersPanel}
          alt="close"
          src={CloseIcon}
          classes="filters--close-icon"
        />
      </div>
      <h1 className="mb-40">Filters</h1>
      <div className="filters--container with-scrollbar">
        <div className="filters--title">Status</div>
        <div className="filters--wrapper">
          <div className="filters--options">
            {filterOptions.isActive.map(({ label, value }) => (
              <div
                key={`active-${label}`}
                className="filters--option"
                onClick={() => onFiltersChange("is_active", value)}
              >
                <img
                  src={filters.is_active === value ? RadioSelectedIcon : RadioUnselectedIcon}
                  alt={label}
                />
                {label}
              </div>
            ))}
          </div>
          <div className="filters--options pl-32">
            {filterOptions.isBlocked.map(({ label, value }) => (
              <div
                key={`blocked-${label}`}
                className="filters--option"
                onClick={() => onFiltersChange("is_blocked", value)}
              >
                <img
                  src={filters.is_blocked === value ? RadioSelectedIcon : RadioUnselectedIcon}
                  alt={label}
                />
                {label}
              </div>
            ))}
          </div>
        </div>
        <div className="filters--title">Type</div>
        <div className="filters--wrapper">
          <div className="filters--options">
            {filterOptions.isAdmin.map(({ label, value }) => (
              <div
                key={`admin-${label}`}
                className="filters--option"
                onClick={() => onFiltersChange("is_admin", value)}
              >
                <img
                  src={filters.is_admin === value ? RadioSelectedIcon : RadioUnselectedIcon}
                  alt={label}
                />
                {label}
              </div>
            ))}
          </div>
          <div className="filters--options pl-32">
            {filterOptions.isBlogger.map(({ label, value }) => (
              <div
                key={`blogger-${label}`}
                className="filters--option"
                onClick={() => onFiltersChange("is_blogger", value)}
              >
                <img
                  src={filters.is_blogger === value ? RadioSelectedIcon : RadioUnselectedIcon}
                  alt={label}
                />
                {label}
              </div>
            ))}
          </div>
        </div>
        <div className="filters--title">KYC</div>
        <div className="filters--wrapper">
          <div className="filters--options">
            {filterOptions.kycVerified.map(({ label, value }) => (
              <div
                key={`kyc-verified-${label}`}
                className="filters--option"
                onClick={() => onFiltersChange("kyc_verified", value)}
              >
                <img
                  src={filters.kyc_verified === value ? RadioSelectedIcon : RadioUnselectedIcon}
                  alt={label}
                />
                {label}
              </div>
            ))}
          </div>
          <div className="filters--options pl-32">
            {filterOptions.isKycSubmitted.map(({ label, value }) => (
              <div
                key={`kyc-submitted-${label}`}
                className="filters--option"
                onClick={() => onFiltersChange("is_kyc_submitted", value)}
              >
                <img
                  src={filters.is_kyc_submitted === value ? RadioSelectedIcon : RadioUnselectedIcon}
                  alt={label}
                />
                {label}
              </div>
            ))}
          </div>
        </div>
        <div className="filters--btn-wrapper">
          <DefaultButton variant="contained" onClick={onApplySelectedClick} classes="green-btn">
            Apply selected
          </DefaultButton>
          <div className="filters--reset text-hover" onClick={onReset}>
            Reset all filters
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Filters);
