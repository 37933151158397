import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";

import IconButton from "../../_shared/Buttons/IconButton";
import { postActivateUser, postBlockUser, postDeactivateUser, postUnblockUser } from "../actions";
import { closeConfirmationDialog, setConfirmationDialog } from "../../../core/appActions";

import ActionsIcon from "../../../assets/image/three-vertical-dots.svg";
import BlockIcon from "../../../assets/image/block.svg";
import UnblockIcon from "../../../assets/image/unblock.svg";
import DeactivateIcon from "../../../assets/image/deactivate.svg";
import ActivateIcon from "../../../assets/image/activate.svg";

const propTypes = {
  id: PropTypes.number,
  is_active: PropTypes.bool,
  is_blocked: PropTypes.bool,
  loading: PropTypes.bool
};
const defaultProps = {
  id: null,
  is_active: false,
  is_blocked: false,
  loading: false
};

const UserActionsPopover = ({ id, is_active, is_blocked, loading }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onMenuClick = event => setAnchorEl(event.currentTarget);
  const onMenuClose = () => setAnchorEl(null);

  const onBlockClick = () => {
    onMenuClose();
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: () => dispatch(postBlockUser(id)),
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: "Block user",
        description: "Are you sure you want to block this user?",
        confirmText: "Block",
        declineText: "Cancel"
      })
    );
  };

  const onUnblockClick = () => {
    onMenuClose();
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: () => dispatch(postUnblockUser(id)),
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: "Unblock user",
        description: "Confirm that you want to unblock this user",
        confirmText: "Unblock",
        declineText: "Cancel"
      })
    );
  };

  const onActivateClick = () => {
    onMenuClose();
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: () => dispatch(postActivateUser(id)),
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: "Activate user",
        description: "Confirm that you want to activate this user",
        confirmText: "Activate",
        declineText: "Cancel"
      })
    );
  };

  const onDeactivateClick = () => {
    onMenuClose();
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: () => dispatch(postDeactivateUser(id)),
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: "Deactivate user",
        description: "Are you sure you want to deactivate this user?",
        confirmText: "Deactivate",
        declineText: "Cancel"
      })
    );
  };

  return (
    <>
      {!loading && (
        <IconButton src={ActionsIcon} onClick={onMenuClick} alt="actions" classes="primary-color" />
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        classes={{
          paper: "default-popover"
        }}
      >
        <div className="app-menu user-actions">
          {is_blocked ? (
            <button onClick={onUnblockClick}>
              <span>Unblock</span>
              <img src={UnblockIcon} alt="unblock" />
            </button>
          ) : (
            <button onClick={onBlockClick}>
              <span>Block</span>
              <img src={BlockIcon} alt="block" />
            </button>
          )}
          {is_active ? (
            <button onClick={onDeactivateClick}>
              <span>Deactivate</span>
              <img src={DeactivateIcon} alt="deactivate" />
            </button>
          ) : (
            <button onClick={onActivateClick}>
              <span>Activate</span>
              <img src={ActivateIcon} alt="activate" />
            </button>
          )}
        </div>
      </Popover>
    </>
  );
};

UserActionsPopover.propTypes = propTypes;
UserActionsPopover.defaultProps = defaultProps;

export default UserActionsPopover;
