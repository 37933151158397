import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { setConnectionMeta } from "../../actions";
import IconButtonComponent from "../../../_shared/Buttons/IconButton";
import DefaultButton from "../../../_shared/Buttons/DefaultButton";
import { connectionInitialFilters } from "../../constants";
import { renderFilterBlock } from "../../helpers";

import CloseIcon from "../../../../assets/image/close.svg";

const Filters = ({ filterOptions, handleFiltersPanel, filtersIsOpen }) => {
  const [filters, setFilters] = useState(connectionInitialFilters);
  const dispatch = useDispatch();
  const { connectionMeta } = useSelector(({ skillChallenges }) => skillChallenges);

  useEffect(() => {
    if (JSON.stringify(filters) !== JSON.stringify(connectionInitialFilters)) {
      if (Object.entries(connectionInitialFilters).every(el => connectionMeta[el[0]] === el[1])) {
        setFilters(connectionInitialFilters);
      }
    }
  }, [connectionMeta]);

  const onFiltersChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const onApplySelectedClick = () => {
    dispatch(setConnectionMeta(filters));
    handleFiltersPanel();
  };

  const onReset = () => {
    setFilters(connectionInitialFilters);
    dispatch(setConnectionMeta(connectionInitialFilters));
    handleFiltersPanel();
  };

  return (
    <div className={clsx("filters with-transition no-user-select", filtersIsOpen && "is-open")}>
      <div className="filters--close-wrapper">
        <IconButtonComponent
          onClick={handleFiltersPanel}
          alt="close"
          src={CloseIcon}
          classes="filters--close-icon"
        />
      </div>
      <h1 className="mb-40">Filters</h1>
      <div className="filters--container with-scrollbar">
        {renderFilterBlock("Game", "game", filterOptions.games, filters.game, onFiltersChange)}
        {renderFilterBlock(
          "Status",
          "status",
          filterOptions.statuses,
          filters.status,
          onFiltersChange
        )}
        <div className="filters--btn-wrapper">
          <DefaultButton variant="contained" onClick={onApplySelectedClick} classes="green-btn">
            Apply selected
          </DefaultButton>
          <div className="filters--reset text-hover" onClick={onReset}>
            Reset all filters
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Filters);
