import React, { memo } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import dayjs from "dayjs";

import Skeleton from "../../../_shared/Skeleton";
import {
  capitalizeFirstLetter,
  renderSkillChallengesGameInfo,
  renderUsernameIdEmailRow
} from "../../../../core/helpers/functions";
import { postSkillChallengesMatchWin, postSkillChallengesMatchLoss } from "../../actions";
import { translateMatchesStatus } from "../../helpers";
import { closeConfirmationDialog, setConfirmationDialog } from "../../../../core/appActions";
import DefaultButton from "../../../_shared/Buttons/DefaultButton";

import ExpandIcon from "../../../../assets/image/expand-white-arrow.svg";

const MatchItem = ({
  id,
  loading = false,
  user,
  connection: { username, game, cod_platform, fortnite_platform, region },
  details: {
    created_at,
    ended_at,
    started_at,
    error_at,
    bet_name,
    odds,
    bet_description,
    win_amount,
    loss_amount,
    bet_amount,
    match_log_link
  },
  status,
  show_win_lost_buttons
}) => {
  const dispatch = useDispatch();

  const onSetWinClick = () => {
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: () => dispatch(postSkillChallengesMatchWin(id)),
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: "Set win",
        description: "Are you sure?",
        confirmText: "Set win",
        declineText: "Cancel"
      })
    );
  };

  const onSetLossClick = () => {
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: () => dispatch(postSkillChallengesMatchLoss(id)),
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: "Set loss",
        description: "Are you sure?",
        confirmText: "Set loss",
        declineText: "Cancel"
      })
    );
  };

  return (
    <Accordion
      classes={{
        root: "accordion-root",
        expanded: "accordion-expanded"
      }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<img src={ExpandIcon} alt="expand" />}
        classes={{
          root: "accordion-panel-root",
          expandIcon: "accordion-expand-icon"
        }}
      >
        <div className="skill-challenges__matches-user-row">
          <div className="skill-challenges__matches-user-row--col">
            {renderUsernameIdEmailRow(user, loading)}
          </div>
          <div className="skill-challenges__matches-user-row--col text-overflow">
            {renderSkillChallengesGameInfo(
              game,
              loading,
              cod_platform || fortnite_platform || region
            )}
          </div>
          <div className="skill-challenges__matches-user-row--col">
            <Skeleton loading={loading} variant="text" width={80}>
              <span className="table-text text-overflow">{username || "-"}</span>
            </Skeleton>
          </div>
          <div className="skill-challenges__matches-user-row--col">
            <Skeleton loading={loading} variant="text" width={70}>
              <div className={clsx("skill-challenges__matches-user-row--status", status)}>
                {translateMatchesStatus(status)}
              </div>
            </Skeleton>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="skill-challenges__matches-info">
          <h4 className="skill-challenges__matches-info--title">Dates</h4>
          <div className="skill-challenges__matches-info--cols">
            <div className="skill-challenges__matches-info--details">
              <div className="skill-challenges__matches-info--info-wrapper">
                <p className="skill-challenges__matches-info--label">Created at:</p>
                <p className="skill-challenges__matches-info--value">
                  {created_at ? dayjs(created_at).format("DD.MM.YYYY HH:mm") : "-"}
                </p>
              </div>
            </div>
            <div className="skill-challenges__matches-info--details">
              <div className="skill-challenges__matches-info--info-wrapper">
                <p className="skill-challenges__matches-info--label">Ended at:</p>
                <p className="skill-challenges__matches-info--value">
                  {ended_at ? dayjs(ended_at).format("DD.MM.YYYY HH:mm") : "-"}
                </p>
              </div>
            </div>
            <div className="skill-challenges__matches-info--details" />
            <div className="skill-challenges__matches-info--details">
              <div className="skill-challenges__matches-info--info-wrapper">
                <p className="skill-challenges__matches-info--label">Started at:</p>
                <p className="skill-challenges__matches-info--value">
                  {started_at ? dayjs(started_at).format("DD.MM.YYYY HH:mm") : "-"}
                </p>
              </div>
            </div>
            <div className="skill-challenges__matches-info--details">
              <div className="skill-challenges__matches-info--info-wrapper">
                <p className="skill-challenges__matches-info--label">Error at:</p>
                <p className="skill-challenges__matches-info--value">
                  {error_at ? dayjs(error_at).format("DD.MM.YYYY HH:mm") : "-"}
                </p>
              </div>
            </div>
          </div>
          <hr className="skill-challenges__matches-info--separator" />
          <h4 className="skill-challenges__matches-info--title">Match info</h4>
          <div className="skill-challenges__matches-info--cols">
            <div className="skill-challenges__matches-info--details">
              <div className="skill-challenges__matches-info--info-wrapper">
                <p className="skill-challenges__matches-info--label">Bet name:</p>
                <p className="skill-challenges__matches-info--value">{bet_name || "-"}</p>
              </div>
            </div>
            <div className="skill-challenges__matches-info--details">
              <div className="skill-challenges__matches-info--info-wrapper">
                <p className="skill-challenges__matches-info--label">Odds:</p>
                <p className="skill-challenges__matches-info--value">{odds || "-"}</p>
              </div>
            </div>
            <div className="skill-challenges__matches-info--details">
              <div className="skill-challenges__matches-info--info-wrapper">
                <p className="skill-challenges__matches-info--label">Bet description:</p>
                <p className="skill-challenges__matches-info--value">
                  {capitalizeFirstLetter(bet_description || "-")}
                </p>
              </div>
            </div>
            <div className="skill-challenges__matches-info--details">
              <div className="skill-challenges__matches-info--info-wrapper">
                <p className="skill-challenges__matches-info--label">Win/loss amount:</p>
                <p className="skill-challenges__matches-info--value">{`${win_amount?.toFixed(
                  2
                )}€/${loss_amount?.toFixed(2)}€`}</p>
              </div>
            </div>
            <div className="skill-challenges__matches-info--details">
              <div className="skill-challenges__matches-info--info-wrapper">
                <p className="skill-challenges__matches-info--label">Bet amount:</p>
                <p className="skill-challenges__matches-info--value">{`${bet_amount?.toFixed(
                  2
                )}€`}</p>
              </div>
            </div>
          </div>
          <hr className="skill-challenges__matches-info--separator" />
          <div className="skill-challenges__matches-info--controls">
            <div className="flex-center">
              {show_win_lost_buttons && (
                <>
                  <DefaultButton variant="contained" onClick={onSetWinClick} classes="green-btn">
                    Set win
                  </DefaultButton>
                  <DefaultButton variant="outlined" onClick={onSetLossClick} classes="red-btn">
                    Set loss
                  </DefaultButton>
                </>
              )}
            </div>
            <a
              href={match_log_link}
              target="_blank"
              rel="noopener noreferrer"
              className="skill-challenges__matches-info--link"
            >
              View match log
            </a>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(MatchItem);
