import React, { memo } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import { getGameInfo, getPlatformNameAndIcon } from "../../../../../core/helpers/functions";
import urls from "../../../../../core/urls";
import Skeleton from "../../../../_shared/Skeleton";

import { setUsersList } from "../../../actions";

import ParticipantsIcon from "../../../../../assets/image/participants.svg";
import ExpandIcon from "../../../../../assets/image/expand-arrow.svg";
import WinnerIcon from "../../../../../assets/image/winner.svg";
import RunnerUpIcon from "../../../../../assets/image/runner-up.svg";

import "./styles.scss";

const defaultProps = {
  name: "",
  startDate: dayjs().add(1, "day"),
  game: 8,
  platform: 1,
  entryFee: null,
  participants: [],
  participantsCount: null,
  tournamentID: null,
  firstPlacePrize: 0,
  secondPlacePrize: 0,
  winner: {},
  runnerUp: {},
  is_test: false
};

const CompletedTournament = ({
  loading,
  name,
  startDate,
  game,
  platform,
  entryFee,
  participants,
  participantsCount,
  tournamentID,
  is_test,
  firstPlacePrize,
  secondPlacePrize,
  winner,
  runnerUp,
  t
}) => {
  const dispatch = useDispatch();

  const onParticipantsClick = e => {
    e.stopPropagation();
    dispatch(
      setUsersList({
        open: true,
        title: t("t:tournaments.participants"),
        numberOfUsers: participantsCount,
        users: participants
      })
    );
  };

  return (
    <Accordion
      classes={{
        root: "accordion-root",
        expanded: "accordion-expanded"
      }}
    >
      <AccordionSummary
        expandIcon={<img src={ExpandIcon} alt="expand" />}
        classes={{
          root: "accordion-panel-root"
        }}
      >
        <div className="completed-tournament-in-list">
          <div className="first-block">
            <Skeleton loading={loading} variant="text" width={200}>
              <Link
                to={urls.tournamentUrl.replace(":id", tournamentID)}
                className="name text-hover"
              >
                <p className="text-overflow">{name}</p>
                {is_test && (
                  <>
                    <span className="dot-separator">•</span>
                    Test
                  </>
                )}
              </Link>
            </Skeleton>
            <Skeleton loading={loading} variant="text" width={180}>
              <div className="start-date">
                {t("t:tournaments.start-at")} {dayjs(startDate).format("DD.MM.YYYY HH:mm")}
              </div>
            </Skeleton>
          </div>
          <div className="second-block">{getGameInfo(game, platform, loading)}</div>
          <div className="third-block">
            <Skeleton loading={loading} variant="text" width={25}>
              {entryFee > 0 ? (
                <div className="fee">{entryFee}€</div>
              ) : (
                <div className="free">Free</div>
              )}
            </Skeleton>
            <Skeleton loading={loading} variant="text" width={50}>
              <div className="label lower-case">{t("t:tournaments.entry-fee")}</div>
            </Skeleton>
          </div>
          <div className="fourth-block">
            <Skeleton loading={loading} variant="circle" width={34} height={34}>
              <img className="participants-icon" src={ParticipantsIcon} alt="users" />
            </Skeleton>
            <div className="participants">
              <Skeleton loading={loading} variant="text" width={25}>
                <div className="value" onClick={onParticipantsClick}>
                  {participantsCount}
                </div>
              </Skeleton>
              <Skeleton loading={loading} variant="text" width={80}>
                <div className="label">{t("t:tournaments.participants")}</div>
              </Skeleton>
            </div>
          </div>
          <div className="last-block">
            <Skeleton loading={loading} variant="text" width={150}>
              <div className="top-places-control">{t("t:tournaments.top-places")}</div>
            </Skeleton>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="top-places-wrapper">
          <div className="place">
            <p className="items-center">
              <img src={WinnerIcon} alt="winner" />
              {t("t:tournaments.winner")}
            </p>
            <div className="user">
              <img src={winner?.avatar} alt="avatar" className="user-icon" />
              <div>
                <p>{winner?.username}</p>
                {getPlatformNameAndIcon(winner?.platform_username, winner?.platform_type)}
              </div>
            </div>
            <div className="prize-amount">{firstPlacePrize}€</div>
          </div>
          <hr className="line-separator" />
          <div className="place">
            <p className="items-center">
              <img src={RunnerUpIcon} alt="winner" />
              {t("t:tournaments.runner-up")}
            </p>
            <div className="user">
              <img src={runnerUp?.avatar} alt="avatar" className="user-icon" />
              <div>
                <p>{runnerUp?.username}</p>
                {getPlatformNameAndIcon(runnerUp?.platform_username, runnerUp?.platform_type)}
              </div>
            </div>
            <div className="prize-amount">{secondPlacePrize}€</div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

CompletedTournament.defaultProps = defaultProps;

export default memo(withTranslation()(CompletedTournament));
