import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { initialFilters, usersTableCols } from "./constants";
import urls from "../../core/urls";
import DefaultButton from "../_shared/Buttons/DefaultButton";
import { getUsers, setUsersMeta, selectUsers, resetUsers } from "./actions";
import Pagination from "../_shared/Pagination";
import UserItem from "./UserItem";
import SearchBy from "../_shared/SearchBy";
import BulkPanel from "./BulkPanel";
import Filters from "./Filters";
import NoItems from "../_shared/NoItems";
import BackDrop from "../_shared/BackDrop";

import UncheckedIcon from "../../assets/image/unchecked.svg";
import CheckedIcon from "../../assets/image/checked.svg";
import FiltersIcon from "../../assets/image/filters.svg";

import "./styles.scss";

const Users = () => {
  const [filtersIsOpen, setFiltersIsOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    users: { results, count },
    meta,
    selectedUsers,
    infoLoading
  } = useSelector(({ users }) => users);

  const prepareParamsArray = () => Object.entries(meta).map(obj => `${obj[0]}=${obj[1]}`);

  const fetchUsers = () => {
    dispatch(getUsers(prepareParamsArray(meta)));
  };

  useEffect(
    () => () => {
      dispatch(resetUsers());
    },
    []
  );

  useEffect(() => {
    fetchUsers();
  }, [JSON.stringify(meta)]);

  const setPage = page => dispatch(setUsersMeta({ page }));
  const setOrdering = ordering => dispatch(setUsersMeta({ ordering }));
  const setSearch = search => dispatch(setUsersMeta({ search }));

  const isAllSelected = useMemo(() => results.every(({ id }) => selectedUsers.includes(id)), [
    results,
    selectedUsers
  ]);

  const onSelectAllUsers = () =>
    dispatch(
      selectUsers(
        results.map(({ id }) => id),
        isAllSelected
      )
    );

  const handleFiltersPanel = () => setFiltersIsOpen(!filtersIsOpen);
  const onFiltersReset = () => dispatch(setUsersMeta(initialFilters));

  const numberOfFilters = useMemo(
    () =>
      Object.entries(initialFilters).reduce((acc, el) => {
        if (meta[el[0]] !== "") return acc + 1;
        return acc;
      }, 0),
    [meta]
  );

  return (
    <div className={clsx("users__wrapper", !!selectedUsers.length && "with-mb")}>
      <div className="users__header">
        <h1>Users</h1>
        <DefaultButton variant="contained" type="link" to={urls.addNewUser} classes="green-btn">
          + Add new
        </DefaultButton>
      </div>
      <div className="search-and-filters">
        <SearchBy placeholder="Search by name, ID or email" onSearch={setSearch} />
        <DefaultButton
          variant="outlined"
          type="button"
          onClick={handleFiltersPanel}
          classes="blue-btn"
        >
          <img src={FiltersIcon} alt="filters" className="mr-8" />
          Filters
          {!!numberOfFilters && (
            <div className="search-and-filters__filters-number">{numberOfFilters}</div>
          )}
        </DefaultButton>
        {!!numberOfFilters && (
          <div className="filters--reset text-hover" onClick={onFiltersReset}>
            Reset all filters
          </div>
        )}
      </div>
      <section className="users__list">
        {results.length ? (
          <div className="users__user-row header-row">
            <div className={clsx("users__user-row--col")}>
              <img
                src={isAllSelected ? CheckedIcon : UncheckedIcon}
                alt="check"
                onClick={onSelectAllUsers}
              />
            </div>
            {usersTableCols.map(({ label, value }) => (
              <div
                className={clsx(
                  "users__user-row--col header-col",
                  (meta.ordering === value || meta.ordering === `-${value}`) && "active-sort"
                )}
                key={value}
                onClick={() => setOrdering(meta.ordering === value ? `-${value}` : value)}
              >
                {label}
                <div
                  className={clsx(
                    "sort-arrow with-transition",
                    meta.ordering === value && "active",
                    meta.ordering === `-${value}` && "active-rotated"
                  )}
                />
              </div>
            ))}
            <div className="users__user-row--col" />
          </div>
        ) : (
          <NoItems />
        )}
        {results.map(user => (
          <UserItem key={`user_${user.id}`} {...user} />
        ))}
      </section>
      {count > 0 && <Pagination onChange={setPage} total={count} page={meta.page} />}
      <BulkPanel />
      <Filters handleFiltersPanel={handleFiltersPanel} filtersIsOpen={filtersIsOpen} />
      <BackDrop open={infoLoading} />
    </div>
  );
};

export default Users;
