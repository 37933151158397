import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { FormControlLabel } from "@material-ui/core";
import Lightbox from "react-images";
import _ from "lodash";

import { getProfileKYC, patchProfileKYC } from "../actions";
import DefaultButton from "../../_shared/Buttons/DefaultButton";
import CheckField from "../../_shared/CheckField";
import { validateImage } from "../../../core/helpers/functions";
import notifyError from "../../../core/helpers/notifyError";

const KYC = ({ renderLoader }) => {
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState({
    kyc_passport_front: {
      label: "Passport front",
      name: "kyc_passport_front",
      file: null,
      preview: null
    },
    kyc_passport_back: {
      label: "Passport back",
      name: "kyc_passport_back",
      file: null,
      preview: null
    },
    kyc_selfie_passport: {
      label: "Selfie passport",
      name: "kyc_selfie_passport",
      file: null,
      preview: null
    },
    kyc_selfie_username: {
      label: "Selfie username",
      name: "kyc_selfie_username",
      file: null,
      preview: null
    }
  });
  const [documentToChange, setDocumentToChange] = useState(null);
  const [imageToOpen, setImageToOpen] = useState(null);
  const {
    general: { id: userID },
    kyc: {
      initial,
      kyc_verified,
      kyc_passport_front,
      kyc_passport_back,
      kyc_selfie_passport,
      kyc_selfie_username
    }
  } = useSelector(({ userProfile }) => userProfile);
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);
  const inputRef = useRef();

  const documentsSetter = (name, file, preview) => {
    setDocuments(prevDocs => ({
      ...prevDocs,
      [name]: {
        ...prevDocs[name],
        file,
        preview
      }
    }));
    if (documentToChange) setDocumentToChange(null);
  };

  const { control, handleSubmit, setValue } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      kyc_verified: kyc_verified || false
    },
    shouldFocusError: true
  });

  useEffect(() => {
    dispatch(getProfileKYC(userID));
  }, []);

  useEffect(() => {
    if (!initial) {
      setDocuments(prevDocs => ({
        kyc_passport_front: {
          ...prevDocs.kyc_passport_front,
          preview: kyc_passport_front
        },
        kyc_passport_back: {
          ...prevDocs.kyc_passport_back,
          preview: kyc_passport_back
        },
        kyc_selfie_passport: {
          ...prevDocs.kyc_selfie_passport,
          preview: kyc_selfie_passport
        },
        kyc_selfie_username: {
          ...prevDocs.kyc_selfie_username,
          preview: kyc_selfie_username
        }
      }));
      setValue("kyc_verified", kyc_verified);
    }
  }, [initial]);

  const prepareFormData = data => {
    const {
      kyc_passport_front,
      kyc_passport_back,
      kyc_selfie_passport,
      kyc_selfie_username
    } = documents;
    const formData = new FormData();
    formData.append("kyc_verified", data.kyc_verified);
    if (kyc_passport_front.preview !== kyc_passport_front)
      formData.append("kyc_passport_front", kyc_passport_front.file || "");
    if (kyc_passport_back.preview !== kyc_passport_back)
      formData.append("kyc_passport_back", kyc_passport_back.file || "");
    if (kyc_selfie_passport.preview !== kyc_selfie_passport)
      formData.append("kyc_selfie_passport", kyc_selfie_passport.file || "");
    if (kyc_selfie_username.preview !== kyc_selfie_username)
      formData.append("kyc_selfie_username", kyc_selfie_username.file || "");
    return formData;
  };

  const onSubmit = data => dispatch(patchProfileKYC(userID, prepareFormData(data)));

  const handleFile = e => {
    e.persist();
    const file = e.target.files[0];
    if (file) {
      if (validateImage(file)) {
        const filePreview = URL.createObjectURL(file);
        documentsSetter(documentToChange, file, filePreview);
      } else {
        notifyError("File type is not supported");
      }
    }
  };

  const onDeleteClick = name => documentsSetter(name, null, null);

  const onUploadClick = name => {
    setDocumentToChange(name);
    inputRef?.current.click();
  };

  if (initial) return renderLoader();

  return (
    <>
      <form
        className="user-profile__content-wrapper user-profile__kyc"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="user-profile__general--subtitle">Photo of documents</div>
        <div className="user-profile__kyc--documents">
          <input
            ref={inputRef}
            className="input-file"
            type="file"
            multiple={false}
            onChange={handleFile}
            accept="image/*"
          />
          {Object.values(documents).map(({ label, name, preview }) => (
            <div key={label} className="user-profile__kyc--document justify-between">
              <div className="flex-center">
                {preview && (
                  <img
                    className="user-profile__kyc--document-image"
                    src={preview}
                    alt="preview"
                    onClick={() => setImageToOpen(preview)}
                  />
                )}
                <div>
                  <p className="user-profile__kyc--document-name">{label}</p>
                  {/*{preview && (*/}
                  {/*  <a*/}
                  {/*    className="user-profile__kyc--document-link text-hover"*/}
                  {/*    href={preview}*/}
                  {/*    rel="noopener noreferrer"*/}
                  {/*    target="_blank"*/}
                  {/*  >*/}
                  {/*    <img src={LinkIcon} alt="open" className="user-profile__kyc--document-link-icon" />*/}
                  {/*    Open in new window*/}
                  {/*  </a>*/}
                  {/*)}*/}
                </div>
              </div>
              <div className="flex-center">
                {preview && (
                  <button
                    type="button"
                    className="user-profile__kyc--document-delete text-hover"
                    onClick={() => onDeleteClick(name)}
                  >
                    Delete
                  </button>
                )}
                <DefaultButton
                  variant="outlined"
                  type="button"
                  onClick={() => onUploadClick(name)}
                  classes="blue-btn"
                >
                  {preview ? "Change file" : "Choose file"}
                </DefaultButton>
              </div>
            </div>
          ))}
        </div>
        <div className="user-profile__general--subtitle">Status</div>
        <div className="user-profile__affiliate--blogger-settings">
          <FormControlLabel
            classes={{
              label: "user-profile__general--check-label"
            }}
            control={
              <Controller
                name="kyc_verified"
                control={control}
                render={props => <CheckField {..._.omit(props.field, "ref")} />}
              />
            }
            label="KYC verified"
          />
        </div>
        <div className="user-profile__general--save-btn">
          <DefaultButton variant="contained" classes="green-btn" loading={buttonLoading} formAction>
            Save changes
          </DefaultButton>
        </div>
      </form>
      <Lightbox
        images={imageToOpen ? [{ src: imageToOpen }] : []}
        isOpen={!!imageToOpen}
        onClose={() => setImageToOpen(null)}
        backdropClosesModal
        showImageCount={false}
      />
    </>
  );
};

export default KYC;
