import { createMuiTheme } from "@material-ui/core";

const defaultMaterialTheme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fill: "#FFFFFF"
      }
    },
    MuiPopover: {
      paper: {
        backgroundColor: "transparent"
      }
    },
    MuiTypography: {
      root: {
        fontFamily: "Inter, sans-serif!important",
        color: "#FFFFFF!important"
      },
      subtitle1: {
        fontSize: "20px"
      },
      h4: {
        fontSize: "32px"
      },
      h3: {
        fontSize: "40px"
      },
      colorPrimary: {
        color: "#57A1E6"
      }
    },
    MuiPickersYear: {
      yearDisabled: {
        opacity: 0.5
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#F32A2A"
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "transparent"
      }
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: "#57A1E6"
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#57A1E6"
      }
    },
    MuiPickersDay: {
      day: {
        color: "#707070"
      },
      dayDisabled: {
        opacity: 0.5
      },
      daySelected: {
        backgroundColor: "#57A1E6",
        "&:hover": {
          backgroundColor: "#57A1E6"
        }
      },
      current: {
        color: "#57A1E6"
      }
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: "#57A1E6"
      }
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: "#1F2D48"
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: "#57A1E6"
      },
      thumb: {
        backgroundColor: "#FFFFFF",
        border: "14px solid #57A1E6"
      },
      noPoint: {
        backgroundColor: "#57A1E6"
      }
    }
  }
});

export default defaultMaterialTheme;
