import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { FormControlLabel } from "@material-ui/core";

import { getProfileAffiliate, patchProfileAffiliate } from "../actions";
import DefaultButton from "../../_shared/Buttons/DefaultButton";
import affiliateSchema from "./affiliateSchema";
import { handleValidationErrors } from "../../../core/helpers/functions";
import InputField from "../../_shared/InputField";
import CheckField from "../../_shared/CheckField";

const Affiliate = ({ renderLoader }) => {
  const dispatch = useDispatch();
  const {
    general: { id: userID },
    affiliate: {
      initial,
      blogger,
      blogger_code_entered_at,
      is_blogger,
      blogger_code,
      blogger_revenue_days,
      blogger_revenue_share_percentage
    }
  } = useSelector(({ userProfile }) => userProfile);
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(affiliateSchema),
    defaultValues: {
      is_blogger: is_blogger || "",
      blogger_code: blogger_code || "",
      blogger_revenue_days: blogger_revenue_days || "",
      blogger_revenue_share_percentage: blogger_revenue_share_percentage || ""
    },
    shouldFocusError: true
  });

  useEffect(() => {
    dispatch(getProfileAffiliate(userID));
  }, []);

  useEffect(() => {
    if (!initial) {
      setValue("is_blogger", is_blogger);
      setValue("blogger_code", blogger_code);
      setValue("blogger_revenue_days", blogger_revenue_days);
      setValue("blogger_revenue_share_percentage", blogger_revenue_share_percentage);
    }
  }, [initial]);

  const onSubmit = async data => {
    const res = await dispatch(patchProfileAffiliate(userID, data));
    if (res.error) handleValidationErrors(res, setError);
  };

  if (initial) return renderLoader();

  return (
    <form
      className="user-profile__content-wrapper user-profile__affiliate"
      onSubmit={handleSubmit(onSubmit)}
    >
      {blogger && (
        <>
          <div className="user-profile__general--subtitle">Invitation info</div>
          <div className="user-profile__affiliate--invitation-info">
            <div className="flex-center">
              <img
                className="user-profile__affiliate--blogger-icon"
                src={blogger.avatar}
                alt="blogger"
              />
              <div>
                <p className="user-profile__affiliate--value-text">
                  {blogger.first_name && blogger.last_name
                    ? `${blogger.first_name} ${blogger.last_name}`
                    : blogger.username}
                </p>
                <p className="user-profile__affiliate--description-text">Invited this user</p>
              </div>
            </div>
            <div>
              <p className="user-profile__affiliate--value-text">
                {dayjs(blogger_code_entered_at).format("DD.MM.YYYY HH:mm")}
              </p>
              <p className="user-profile__affiliate--description-text">
                User entered blogger&apos;s code
              </p>
            </div>
          </div>
        </>
      )}
      <div className="user-profile__general--subtitle">Blogger settings</div>
      <div className="user-profile__affiliate--blogger-settings">
        <FormControlLabel
          classes={{
            label: "user-profile__general--check-label"
          }}
          control={
            <Controller
              name="is_blogger"
              control={control}
              render={props => <CheckField {..._.omit(props.field, "ref")} />}
            />
          }
          label="This user is blogger"
        />
        <div className="user-profile__affiliate--form-fields">
          <Controller
            name="blogger_code"
            control={control}
            render={props => (
              <InputField
                id="blogger-code-input"
                label="Blogger code"
                type="text"
                {..._.omit(props.field, "ref")}
                error={errors.blogger_code?.message}
              />
            )}
          />
          <Controller
            name="blogger_revenue_days"
            control={control}
            render={props => (
              <InputField
                id="blogger-revenue-days-input"
                label="Revenue days"
                type="text"
                {..._.omit(props.field, "ref")}
                error={errors.blogger_revenue_days?.message}
              />
            )}
          />
          <Controller
            name="blogger_revenue_share_percentage"
            control={control}
            render={props => (
              <InputField
                id="blogger-revenue-share-percentage-input"
                label="Revenue share"
                type="text"
                {..._.omit(props.field, "ref")}
                error={errors.blogger_revenue_share_percentage?.message}
                endAdornment={<span>%</span>}
              />
            )}
          />
        </div>
      </div>
      <div className="user-profile__general--save-btn">
        <DefaultButton variant="contained" classes="green-btn" loading={buttonLoading} formAction>
          Save changes
        </DefaultButton>
      </div>
    </form>
  );
};

export default Affiliate;
