import SKILL_CHALLENGES from "./actionTypes";

const INITIAL_STATE = {
  connection: {
    count: 10,
    results: Array.from({ length: 10 }, (v, k) => ({ id: k, loading: true }))
  },
  connectionMeta: {
    page: 1,
    page_size: 10,
    game: "",
    status: "",
    ordering: "",
    search: ""
  },
  matches: { count: 10, results: Array.from({ length: 10 }, (v, k) => ({ id: k, loading: true })) },
  matchesMeta: {
    page: 1,
    page_size: 10,
    game: "",
    status: "",
    bet_type: "",
    loss_details: "",
    ordering: "",
    search: ""
  },
  statistics: {
    count: 10,
    results: Array.from({ length: 10 }, (v, k) => ({ id: k, loading: true }))
  },
  statisticsMeta: {
    page: 1,
    page_size: 10,
    ordering: ""
  },
  reloadConnection: false,
  reloadMatches: false
};

export default function(
  state = INITIAL_STATE,
  { type, payload, connectionMeta, matchesMeta, statisticsMeta }
) {
  switch (type) {
    case SKILL_CHALLENGES.GET_SKILL_CHALLENGES_CONNECTION_SUCCESS:
      return {
        ...state,
        connection: {
          ...payload.data,
          filter_options: {
            games: [{ label: "All", value: "" }, ...payload.data.filter_options.games],
            statuses: [{ label: "All", value: "" }, ...payload.data.filter_options.statuses]
          }
        },
        reloadConnection: false
      };
    case SKILL_CHALLENGES.SET_CONNECTION_META:
      return { ...state, connectionMeta: { ...state.connectionMeta, ...connectionMeta } };
    case SKILL_CHALLENGES.SET_MATCHES_META:
      return { ...state, matchesMeta: { ...state.matchesMeta, ...matchesMeta } };
    case SKILL_CHALLENGES.SET_STATISTICS_META:
      return { ...state, statisticsMeta: { ...state.statisticsMeta, ...statisticsMeta } };
    case SKILL_CHALLENGES.DELETE_SKILL_CHALLENGES_CONNECTION_SUCCESS:
      return { ...state, reloadConnection: true };
    case SKILL_CHALLENGES.GET_SKILL_CHALLENGES_MATCHES_SUCCESS:
      return {
        ...state,
        matches: {
          ...payload.data,
          filter_options: {
            games: [{ label: "All", value: "" }, ...payload.data.filter_options.games],
            statuses: [{ label: "All", value: "" }, ...payload.data.filter_options.statuses],
            bet_types: [{ label: "All", value: "" }, ...payload.data.filter_options.bet_types],
            loss_details: [{ label: "All", value: "" }, ...payload.data.filter_options.loss_details]
          }
        },
        reloadMatches: false
      };
    case SKILL_CHALLENGES.POST_SKILL_CHALLENGES_MATCH_WIN_SUCCESS:
    case SKILL_CHALLENGES.POST_SKILL_CHALLENGES_MATCH_LOSS_SUCCESS:
      return { ...state, reloadMatches: true };
    case SKILL_CHALLENGES.GET_SKILL_CHALLENGES_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: payload.data
      };
    case SKILL_CHALLENGES.RESET_SKILL_CHALLENGES:
      return INITIAL_STATE;
    default:
      return state;
  }
}
