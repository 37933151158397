import * as yup from "yup";

export const positiveOrZeroIntegerValidation = t => {
  return yup
    .number()
    .typeError(t("t:validation.number"))
    .min(0)
    .typeError(t("t:validation.incorrect"))
    .required(t("t:validation.required"));
};

export const positiveOrZeroNumberValidation = t => {
  if (t) {
    return yup
      .number()
      .typeError(t("t:validation.number"))
      .min(0)
      .typeError(t("t:validation.incorrect"))
      .required(t("t:validation.required"));
  } else {
    return yup
      .number()
      .typeError("The field must be a number")
      .min(0)
      .typeError("Incorrect value")
      .required("The field is required");
  }
};

export const integerNumberValidation = () =>
  yup
    .number()
    .typeError("The field must be a number")
    .integer("The number must be an integer")
    .min(0)
    .typeError("Incorrect value")
    .required("The field is required");

export const nullableIntegerValidation = () =>
  yup
    .number()
    .typeError("The field must be a number")
    .integer("The number must be an integer")
    .min(0)
    .typeError("Incorrect value")
    .transform((_, val) => (val ? Number(val) : 0));
