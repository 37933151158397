import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import Skeleton from "../../components/_shared/Skeleton";
import urls from "../urls";

import PlaystationIcon from "../../assets/image/playstation.svg";
import XboxIcon from "../../assets/image/xbox.svg";
import SteamIcon from "../../assets/image/steam.svg";
import FIFA21 from "../../assets/image/FIFA21.png";
import GreenCheckIcon from "../../assets/image/green-checkmark.svg";
import EpicIcon from "../../assets/image/epic.svg";
import ActivisionIcon from "../../assets/image/activision.svg";
import BlizzardIcon from "../../assets/image/blizzard.svg";
import SupercellIcon from "../../assets/image/supercell-logo.svg";
import CODIcon from "../../assets/image/skill-challenges-cod.svg";
import DotaIcon from "../../assets/image/skill-challenges-dota.svg";
import FortniteIcon from "../../assets/image/skill-challenges-fortnite.svg";
import LOLIcon from "../../assets/image/skill-challenges-lol.svg";
import ValorantIcon from "../../assets/image/skill-challenges-valorant.svg";

const joinQueries = arr => `${arr.length && arr.length !== 0 ? "?" : ""}${arr.join("&")}`;

const getOption = label => (
  <div className={`status ${label}`}>
    <div>
      {label !== "All networks" && <span />}
      {label}
    </div>
  </div>
);

const splitByCommas = data => data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const getPlatformIconAndName = platform => {
  switch (platform?.toLowerCase()) {
    case "playstation":
    case "psn":
      return (
        <span className="platform-icon-and-name">
          <img className="platform-icon" src={PlaystationIcon} alt="psn" />
          Playstation
        </span>
      );
    case "xbox":
    case "xbl":
      return (
        <span className="platform-icon-and-name">
          <img className="platform-icon" src={XboxIcon} alt="xbox" />
          Xbox
        </span>
      );
    case "steam":
      return (
        <span className="platform-icon-and-name">
          <img className="platform-icon" src={SteamIcon} alt="steam" />
          Steam
        </span>
      );
    case "battlenet":
      return (
        <span className="platform-icon-and-name">
          <img className="platform-icon" src={BlizzardIcon} alt="blizzard" />
          Battle.net
        </span>
      );
    case "atvi":
    case "activision":
      return (
        <span className="platform-icon-and-name">
          <img src={ActivisionIcon} alt="activision" />
        </span>
      );
    case "supercell":
      return (
        <span className="platform-icon-and-name">
          <img className="platform-icon supercell" src={SupercellIcon} alt="supercell" />
          Supercell
        </span>
      );
    default:
      return null;
  }
};

const getGameInfo = (game, platform, loading = false) => {
  return (
    <div className="game-info-wrapper">
      <Skeleton loading={loading} variant="rect" width={38} height={38}>
        <img className="game-icon" src={game?.icon || FIFA21} alt={game?.short_name} />
      </Skeleton>
      <div className="info">
        <Skeleton loading={loading} variant="text" width={200}>
          <div className="name">{game?.short_name}</div>
        </Skeleton>
        <Skeleton loading={loading} variant="text" width={100}>
          <div className="platform">{getPlatformIconAndName(platform?.name)}</div>
        </Skeleton>
      </div>
    </div>
  );
};

const getUserInfo = (user, platform, t, loading = false, isDisputeWinner = false) => {
  return (
    <div className="user-info-wrapper">
      <Skeleton loading={loading} variant="circle" width={36} height={36}>
        <img className="user-icon" src={user?.avatar} alt={user?.username} />
      </Skeleton>
      <div className="info">
        <Skeleton loading={loading} variant="text" width={150}>
          <div className="name">{`${user?.first_name} ${user?.last_name}`}</div>
        </Skeleton>
        <Skeleton loading={loading} variant="text" width={80}>
          <div className="username">
            {getPlatformNameAndIcon(user?.platform_username, platform?.type)}
            {isDisputeWinner && (
              <p className="dispute-winner">
                <span />
                {t("t:tournaments.winner")}
              </p>
            )}
          </div>
        </Skeleton>
      </div>
    </div>
  );
};

const getTournamentStatus = (status, t) => {
  switch (status) {
    case "not_published":
      return t("t:tournaments.not-published");
    case "upcoming":
    case "published":
      return t("t:tournaments.published");
    default:
      return "";
  }
};

const getTournamentStageByDepthIndex = (depth_index, t) => {
  switch (depth_index) {
    case 1:
      return t("t:tournaments.final");
    case 2:
      return t("t:tournaments.semi-finals");
    case 3:
      return t("t:tournaments.quarter-finals");
    default:
      return `${t("t:tournaments.round-of")} ${Math.pow(2, depth_index)}`;
  }
};

const getDisputeState = (resolved, t) => {
  if (resolved) {
    return (
      <div className="dispute-status solved">
        {t("t:tournaments.solved")} <div className="marker" />
      </div>
    );
  } else {
    return (
      <div className="dispute-status pending">
        {t("t:tournaments.pending")} <div className="marker" />
      </div>
    );
  }
};

function getPlatformNameAndIcon(username, platformType = "") {
  switch (platformType.toLowerCase()) {
    case "xbox":
      return (
        <p className="platform-username-and-icon" title={username}>
          <span>{username}</span>
          <img src={XboxIcon} alt="xbox" />
        </p>
      );
    case "steam":
      return (
        <p className="platform-username-and-icon" title={username}>
          <span>{username}</span>
          <img src={SteamIcon} alt="steam" />
        </p>
      );
    case "supercell":
      return (
        <p className="platform-username-and-icon" title={username}>
          <span>{username}</span>
          <img src={SupercellIcon} alt="steam" />
        </p>
      );
    default:
      return (
        <p className="platform-username-and-icon" title={username}>
          <span>{username}</span>
          <img src={PlaystationIcon} alt="psn" />
        </p>
      );
  }
}

const validateImage = file => {
  const validTypes = ["image/jpeg", "image/jpg", "image/png"];
  return validTypes.indexOf(file.type) !== -1;
};

const handleValidationErrors = (res, setError) => {
  Object.entries(res.error.response.data).map(field =>
    setError(field[0], { type: "manual", message: field[1] })
  );
};

const getPlatformStatus = status => {
  switch (status) {
    case "verified":
      return (
        <p className={clsx("platform-status", status)}>
          <img src={GreenCheckIcon} alt="checked" />
          Verified
        </p>
      );
    case "verifying":
      return <p className={clsx("platform-status", status)}>Verifying</p>;
    case "not_connected":
      return <p className={clsx("platform-status", status)}>Not connected</p>;
    case "error":
      return <p className={clsx("platform-status", status)}>Error</p>;
    default:
      return <p className={clsx("platform-status", status)}>Unknown status</p>;
  }
};

const renderUserRow = (user, secondRow, loading = false) => {
  return (
    <div className="user-info-wrapper">
      <Skeleton loading={loading} variant="circle" width={36} height={36}>
        <img className="user-icon" src={user?.avatar} alt={user?.id} />
      </Skeleton>
      <div className="info">
        <Skeleton loading={loading} variant="text" width={150}>
          <div className="name">
            {user?.first_name ? `${user?.first_name} ${user?.last_name}` : "-"}
          </div>
        </Skeleton>
        <Skeleton loading={loading} variant="text" width={80}>
          <div className="username">{secondRow()}</div>
        </Skeleton>
      </div>
    </div>
  );
};

const renderUsernameIdEmailRow = (user, loading = false) => {
  return (
    <div className="user-info-wrapper">
      <Skeleton loading={loading} variant="circle" width={36} height={36}>
        <img className="user-icon" src={user?.avatar} alt={user?.id} />
      </Skeleton>
      <div className="info">
        <Skeleton loading={loading} variant="text" width={200}>
          <div className="flex-center">
            <div className="name">{user?.username || "-"}</div>
            <div className="dot-separator" />
            <Link
              to={`${urls.userProfileUrl.replace(":id", user?.id)}?tab=general`}
              className="user-id text-hover"
            >
              {`#${user?.id}`}
            </Link>
          </div>
        </Skeleton>
        <Skeleton loading={loading} variant="text" width={150}>
          <div className="username">{user?.email || "-"}</div>
        </Skeleton>
      </div>
    </div>
  );
};

const renderSkillChallengesGameInfo = (game, loading = false, platform) => {
  const getIcon = () => {
    switch (game?.id) {
      case 1:
        return DotaIcon;
      case 2:
        return FortniteIcon;
      case 3:
        return LOLIcon;
      case 4:
        return CODIcon;
      case 5:
        return ValorantIcon;
      default:
        return "";
    }
  };

  const getFortnitePlatform = () => {
    switch (platform) {
      case "pc":
        return (
          <span className="platform-icon-and-name">
            <img className="platform-icon" src={EpicIcon} alt="xbox" />
            Epic (PC)
          </span>
        );
      case "psn":
        return getPlatformIconAndName("PSN");
      case "xbl":
        return getPlatformIconAndName("XBOX");
      case "psn_epic":
        return (
          <span className="platform-icon-and-name">
            <img className="platform-icon" src={EpicIcon} alt="xbox" />
            Epic (Playstation)
          </span>
        );
      case "xbox_epic":
        return (
          <span className="platform-icon-and-name">
            <img className="platform-icon" src={EpicIcon} alt="xbox" />
            Epic (Xbox)
          </span>
        );
      default:
        return null;
    }
  };

  const getPlatform = () => {
    switch (game?.id) {
      case 1:
        return getPlatformIconAndName("STEAM");
      case 2:
        return getFortnitePlatform();
      case 3:
        return <span className="lol-region">{`Region: ${platform}`}</span>;
      case 4:
      case 5:
        return getPlatformIconAndName(platform?.toUpperCase());
      default:
        return null;
    }
  };

  return (
    <div className="game-info-wrapper">
      <Skeleton loading={loading} variant="rect" width={38} height={38}>
        {game?.name ? <img className="game-icon" src={getIcon()} alt={game?.name} /> : "-"}
      </Skeleton>
      <div className="info">
        <Skeleton loading={loading} variant="text" width={200}>
          <div className="name">{game?.name}</div>
        </Skeleton>
        <Skeleton loading={loading} variant="text" width={100}>
          <div className="platform">{getPlatform()}</div>
        </Skeleton>
      </div>
    </div>
  );
};

const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export {
  joinQueries,
  getOption,
  splitByCommas,
  getPlatformIconAndName,
  getGameInfo,
  getUserInfo,
  getTournamentStatus,
  getTournamentStageByDepthIndex,
  getDisputeState,
  getPlatformNameAndIcon,
  validateImage,
  getPlatformStatus,
  handleValidationErrors,
  renderUserRow,
  renderUsernameIdEmailRow,
  renderSkillChallengesGameInfo,
  capitalizeFirstLetter
};
