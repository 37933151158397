import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";

import DefaultButton from "../../../_shared/Buttons/DefaultButton";
import InputField from "../../../_shared/InputField";
import SelectComponent from "../../../_shared/SelectComponent";
import BackDrop from "../../../_shared/BackDrop";
import { getOption } from "../../../../core/helpers/functions";
import { integerNumberValidation } from "../../../../core/helpers/validations";
import urls from "../../../../core/urls";
import { oneVsOneTemplates, skillChallengesTemplates, challengesRanks } from "../../constants";
import {
  getChallengeTypeByPathname,
  translateOneVsOneTemplate,
  translateSkillChallengesTemplate
} from "../../helpers";
import { patchChallenge, postChallenge } from "../../actions";

import "../styles.scss";

const defaultProps = {
  id: null,
  template: null,
  rank_name: null,
  amount: ""
};

const RankFields = ({ id, template, rank_name, amount }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let published = false;

  const challengeType = useMemo(() => getChallengeTypeByPathname(pathname), [pathname]);

  const { schema, rankOptions, getTemplateOptions } = useMemo(
    () => ({
      schema: yup.object().shape({
        amount: integerNumberValidation()
      }),
      rankOptions: challengesRanks.map(({ label, value }) => ({
        label: getOption(label),
        value
      })),
      getTemplateOptions: () => {
        if (challengeType === "skill/challenge") {
          return skillChallengesTemplates.map(value => ({
            label: getOption(translateSkillChallengesTemplate(value, "N", t)),
            value
          }));
        }
        return oneVsOneTemplates.map(value => ({
          label: getOption(translateOneVsOneTemplate(value, "N", t)),
          value
        }));
      }
    }),
    [challengeType]
  );

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      template: template ? getTemplateOptions().find(el => el.value === template) : null,
      rank_name: rank_name ? rankOptions.find(el => el.value === rank_name) : null,
      amount
    },
    shouldFocusError: true
  });

  const watchFields = watch();

  const disabled = useMemo(
    () => !watchFields.template || !watchFields.rank_name || !watchFields.amount,
    [watchFields]
  );

  const buttonLoading = useSelector(({ app }) => app.buttonLoading);

  const prepareDataForSubmit = data => ({
    template: data.template.value,
    rank_name: data.rank_name.value,
    amount: data.amount
  });

  const onSubmit = async data => {
    const res = await dispatch(
      !id
        ? postChallenge(challengeType, {
            ...prepareDataForSubmit(data),
            is_active: !!published
          })
        : patchChallenge(challengeType, id, prepareDataForSubmit(data))
    );
    if (res?.payload) {
      history.push(`${urls.challengesSystemUrl}?tab=${challengeType}`);
    }
  };

  const onButtonClick = isPublished => (published = isPublished);

  return (
    <form className="challenges-fields" onSubmit={handleSubmit(onSubmit)}>
      <div className="fields-wrapper">
        <div className="fields-block margin-bottom-48">
          <div className="block-name">General info</div>
          <div className="fields">
            <Controller
              name="template"
              control={control}
              render={props => (
                <SelectComponent
                  change={props.field.onChange}
                  id="gamemode-select"
                  placeholder="Challenge template"
                  {..._.omit(props.field, "ref")}
                  options={getTemplateOptions()}
                />
              )}
            />
            <div className="several-fields margin-top-32">
              <Controller
                name="rank_name"
                control={control}
                render={props => (
                  <SelectComponent
                    change={props.field.onChange}
                    id="gamemode-select"
                    placeholder="Rank"
                    {..._.omit(props.field, "ref")}
                    options={rankOptions}
                  />
                )}
              />
              <Controller
                name="amount"
                control={control}
                render={props => (
                  <InputField
                    id="name-input"
                    label="Steps to complete"
                    type="text"
                    {..._.omit(props.field, "ref")}
                    inputRef={props.ref}
                    error={errors.amount?.message}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="challenges-fields__buttons-wrapper">
        {id ? (
          <DefaultButton variant="contained" classes="green-btn" disabled={disabled} formAction>
            Save changes
          </DefaultButton>
        ) : (
          <>
            <DefaultButton
              variant="outlined"
              onClick={() => onButtonClick(false)}
              disabled={disabled}
              classes="green-btn"
              formAction
            >
              Create unpublished
            </DefaultButton>
            <DefaultButton
              variant="contained"
              onClick={() => onButtonClick(true)}
              disabled={disabled}
              classes="green-btn"
              formAction
            >
              Create & Publish
            </DefaultButton>
          </>
        )}
      </div>
      <BackDrop open={buttonLoading} />
    </form>
  );
};

RankFields.defaultProps = defaultProps;

export default RankFields;
