import APP from "./appActionTypes";

export function closeErrorSnack() {
  return {
    type: APP.ERROR_SNACK_CLOSE
  };
}

export function changePanelState() {
  return {
    type: APP.CHANGE_PANEL_STATE
  };
}

export function setConfirmationDialog(confirmationDialog) {
  return {
    type: APP.SET_CONFIRMATION_DIALOG,
    confirmationDialog
  };
}

export function closeConfirmationDialog() {
  return {
    type: APP.CLOSE_CONFIRMATION_DIALOG
  };
}

export function setSocketData(adminWSType, adminWSData) {
  return {
    type: APP.SET_SOCKET_DATA,
    adminWSType,
    adminWSData
  };
}
