import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { postLogin } from "../actions";

import RenderField from "../../_shared/RenderField";
import DefaultButton from "../../_shared/Buttons/DefaultButton";

import ErrorIcon from "../../../assets/image/error.svg";

let Login = ({
  postLogin,
  history,
  handleSubmit,
  submitting,
  pristine,
  valid,
  authError,
  loading
}) => {
  if (localStorage.token) {
    history.push("/admin");
  }

  const submitForm = data => {
    return postLogin(data).then(res => {
      if (res.payload && res.payload.status && res.payload.status === 200) {
        localStorage.token = res.payload.data.token;
        localStorage.email = res.payload.data.email;
        history.push("/admin");
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="auth_block_head">Log in</div>
      <div className="auth_block_descriptions">Please log in to your account</div>
      <Field name="email" type="text" component={RenderField} label="Email" autoCapitalize="none" />
      <Field name="password" type="password" component={RenderField} label="Password" />
      <DefaultButton
        classes="auth-btn"
        variant="contained"
        disabled={submitting || pristine || !valid}
        loading={loading}
        formAction
      >
        Log in
      </DefaultButton>
      {authError && (
        <div className="auth_error">
          <img src={ErrorIcon} alt="error" />
          Incorrect email or password
        </div>
      )}
    </form>
  );
};

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
    errors.email = "Invalid email";
  }
  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 8) {
    errors.password = "Must be 8 characters or more";
  }
  return errors;
};

Login = reduxForm({
  form: "LoginForm",
  validate
})(Login);

const mapStateToProps = ({ auth, app }) => {
  return {
    authError: auth.authError,
    loading: app.loading
  };
};
const mapDispatchToProps = {
  postLogin
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
