import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Popover from "@material-ui/core/Popover";
import clsx from "clsx";

import IconButton from "../../_shared/Buttons/IconButton";
import {
  translateChallengeStatus,
  translateDailyChampionTemplate,
  translateGameType,
  translateOneVsOneTemplate,
  translateSkillChallengesTemplate
} from "../helpers";
import urls from "../../../core/urls";
import { deleteChallenge, patchChallenge } from "../actions";
import { closeConfirmationDialog, setConfirmationDialog } from "../../../core/appActions";

import DragHandleIcon from "../../../assets/image/drag-handle.svg";
import ActionsIcon from "../../../assets/image/three-vertical-dots.svg";
import EditIcon from "../../../assets/image/edit.svg";
import PublishIcon from "../../../assets/image/publish.svg";
import UnpublishIcon from "../../../assets/image/unpublish.svg";
import DeleteIcon from "../../../assets/image/delete.svg";
import { useHistory } from "react-router";

const ChallengeRow = ({
  id,
  challengeType,
  is_active,
  is_ongoing,
  game_type,
  template,
  amount,
  showDrag,
  ...restFields
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const history = useHistory();
  const { challenges } = useSelector(({ challengesSystem }) => challengesSystem);

  const onMenuClick = event => setAnchorEl(event.currentTarget);
  const onMenuClose = () => setAnchorEl(null);

  const onEditClick = () => {
    if (challengeType === "daily") history.push(urls.editDailyChallengeUrl.replace(":id", id));
    if (challengeType === "1vs1/challenge")
      history.push(urls.editOneVsOneChallengeUrl.replace(":id", id));
    if (challengeType === "skill/challenge")
      history.push(urls.editSkillChallengeUrl.replace(":id", id));
  };

  const onRowClick = () => {
    if (is_ongoing) onEditClick();
  };

  const onPublishClick = () => {
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: () =>
          dispatch(
            patchChallenge(challengeType, id, {
              ...restFields,
              is_active: true,
              position: Math.max(...challenges.map(el => el.position)) + 1
            })
          ),
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: "Publish challenge",
        description: "Confirm that you want to publish this challenge",
        confirmText: "Publish",
        declineText: "Cancel"
      })
    );
  };

  const onUnpublishClick = () => {
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: () =>
          dispatch(patchChallenge(challengeType, id, { ...restFields, is_active: false })),
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: "Unpublish challenge",
        description: "Are you sure you want to unpublish this challenge?",
        confirmText: "Unpublish",
        declineText: "Cancel"
      })
    );
  };

  const onDeleteClick = () => {
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: () => dispatch(deleteChallenge(challengeType, id)),
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: "Delete challenge",
        description: "Are you sure you want to delete this challenge?",
        confirmText: "Delete",
        declineText: "Cancel"
      })
    );
  };

  return (
    <div
      className={clsx(
        "challenges-system__challenge-row",
        is_ongoing && showDrag && "active-challenge"
      )}
      onClick={() => onRowClick()}
    >
      <div className="challenges-system__challenge-row--col">
        {!!showDrag && (
          <div
            className={clsx(
              "challenges-system__challenge-row--drag-handle-wrapper",
              is_ongoing && "active-challenge"
            )}
          >
            <img src={DragHandleIcon} alt="drag" />
          </div>
        )}
        <p className="challenges-system__challenge-row--name">
          {challengeType === "daily" && translateDailyChampionTemplate(template, t)}
          {challengeType === "1vs1/challenge" && translateOneVsOneTemplate(template, amount, t)}
          {challengeType === "skill/challenge" &&
            translateSkillChallengesTemplate(template, amount, t)}
        </p>
      </div>
      <div className="challenges-system__challenge-row--col">
        <span className="table-text">
          {challengeType === "daily" ? translateGameType(game_type, t) : `${amount} step(s)`}
        </span>
      </div>
      <div className="challenges-system__challenge-row--col">
        {translateChallengeStatus(is_active, is_ongoing)}
        {!is_ongoing && (
          <IconButton
            src={ActionsIcon}
            onClick={onMenuClick}
            alt="actions"
            classes="primary-color"
          />
        )}
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        classes={{
          paper: "default-popover"
        }}
      >
        <div className="app-menu challenge-actions">
          {is_active ? (
            <button onClick={onUnpublishClick}>
              <span>Unpublish</span>
              <img src={UnpublishIcon} alt="unpublish" />
            </button>
          ) : (
            <button onClick={onPublishClick}>
              <span>Publish</span>
              <img src={PublishIcon} alt="publish" />
            </button>
          )}
          <button onClick={onEditClick}>
            <span>Edit</span>
            <img src={EditIcon} alt="edit" />
          </button>
          <button onClick={onDeleteClick}>
            <span>Delete</span>
            <img src={DeleteIcon} alt="delete" />
          </button>
        </div>
      </Popover>
    </div>
  );
};

export default ChallengeRow;
