import React from "react";

import AddUserFields from "../AddUserFields";
import BackLink from "../../_shared/BackLink";
import urls from "../../../core/urls";

const AddUser = () => {
  return (
    <div className="default-content-wrapper add-user">
      <BackLink to={urls.usersUrl} />
      <h1>Add new user</h1>
      <AddUserFields />
    </div>
  );
};

export default AddUser;
