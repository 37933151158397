import React, { memo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { getGameInfo } from "../../../../../core/helpers/functions";
import Skeleton from "../../../../_shared/Skeleton";
import DefaultButton from "../../../../_shared/Buttons/DefaultButton";
import Dialog from "../../../../_shared/Dialog";
import DateTimePicker from "../../../../_shared/DateTimePicker";

import { postRestoreTournament } from "../../../actions";

import ParticipantsIcon from "../../../../../assets/image/participants.svg";

import "./styles.scss";

const defaultProps = {
  name: "",
  startDate: dayjs().add(1, "day"),
  game: 8,
  platform: 1,
  entryFee: null,
  minParticipants: null,
  maxParticipants: null
};

const InactiveTournament = ({
  loading,
  name,
  startDate,
  game,
  platform,
  entryFee,
  minParticipants,
  maxParticipants,
  tournamentID,
  t
}) => {
  const [restoreDialogIsOpen, setRestoreDialogIsOpen] = useState(false);
  const [dateTime, setDateTime] = useState(startDate);
  const dispatch = useDispatch();
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);

  const onRestoreClick = () => {
    dispatch(
      postRestoreTournament(tournamentID, { start_at: dateTime }, t("t:success-messages.restore"))
    ).then(res => res.payload && setRestoreDialogIsOpen(false));
  };

  return (
    <div className="inactive-tournament-in-list">
      <div className="first-block">
        <Skeleton loading={loading} variant="text" width={200}>
          <div className="name">
            <p className="text-overflow">{name}</p>
          </div>
        </Skeleton>
        <Skeleton loading={loading} variant="text" width={180}>
          <div className="start-date">
            {t("t:tournaments.start-at")} {dayjs(startDate).format("DD.MM.YYYY HH:mm")}
          </div>
        </Skeleton>
      </div>
      <div className="second-block">{getGameInfo(game, platform, loading)}</div>
      <div className="third-block">
        <Skeleton loading={loading} variant="text" width={25}>
          {entryFee > 0 ? <div className="fee">{entryFee}€</div> : <div className="free">Free</div>}
        </Skeleton>
        <Skeleton loading={loading} variant="text" width={50}>
          <div className="label lower-case">{t("t:tournaments.entry-fee")}</div>
        </Skeleton>
      </div>
      <div className="fourth-block">
        <Skeleton loading={loading} variant="circle" width={34} height={34}>
          <img className="participants-icon" src={ParticipantsIcon} alt="users" />
        </Skeleton>
        <div className="participants">
          <Skeleton loading={loading} variant="text" width={100}>
            <div className="values-wrapper">
              <div className="value">
                {minParticipants}
                <span className="label lower-case">{t("t:tournaments.min")}</span>
              </div>
              <div className="value">
                {maxParticipants}
                <span className="label">{t("t:tournaments.max")}</span>
              </div>
            </div>
          </Skeleton>
          <Skeleton loading={loading} variant="text" width={120}>
            <div />
          </Skeleton>
        </div>
      </div>
      <div className="last-block">
        <Skeleton loading={loading} variant="text" width={52}>
          <div
            className="restore text-hover no-user-select"
            onClick={() => setRestoreDialogIsOpen(true)}
          >
            {t("t:tournaments.restore")}
          </div>
        </Skeleton>
      </div>
      <Dialog open={restoreDialogIsOpen} onClose={() => setRestoreDialogIsOpen(false)}>
        <div className="restore-tournament-dialog">
          <h2 className="title">{t("t:tournaments.restore-tournament")}</h2>
          <div className="description">{t("t:tournaments.restore-tournament-description")}</div>
          <DateTimePicker dateTime={dateTime} handleDateTime={setDateTime} />
          <hr className="separator" />
          <div className="buttons-wrapper">
            <DefaultButton
              variant="contained"
              onClick={() => setRestoreDialogIsOpen(false)}
              classes="grey-btn"
            >
              {t("t:shared.cancel")}
            </DefaultButton>
            <DefaultButton
              variant="contained"
              onClick={onRestoreClick}
              classes="green-btn"
              loading={buttonLoading}
            >
              {t("t:tournaments.restore")}
            </DefaultButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

InactiveTournament.defaultProps = defaultProps;

export default memo(withTranslation()(InactiveTournament));
