import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import Highstock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import clsx from "clsx";
import dayjs from "dayjs";

import { getRegistrations, resetRegistrations, setRegistrationsMeta } from "./actions";
import BackDrop from "../_shared/BackDrop";
import { pieChartOptions, lineChartOptions, periods, prepareLineChartData } from "./constants";
import { splitByCommas } from "../../core/helpers/functions";
import { DatePickerComponent } from "../_shared/DateTimePicker";

import "./styles.scss";

const Registrations = () => {
  const dispatch = useDispatch();
  const {
    registrations: { total, data },
    meta,
    isFetched
  } = useSelector(({ registrations }) => registrations);

  const prepareParamsArray = fields => Object.entries(fields).map(obj => `${obj[0]}=${obj[1]}`);

  const fetchRegistrations = () => {
    dispatch(
      getRegistrations(
        prepareParamsArray({
          ...meta,
          from_date: dayjs(meta.from_date).format("YYYY-MM-DD"),
          to_date: dayjs(meta.to_date).format("YYYY-MM-DD")
        })
      )
    );
  };

  const setPeriod = period => dispatch(setRegistrationsMeta({ period }));
  const setFromDate = from_date => dispatch(setRegistrationsMeta({ from_date }));
  const setToDate = to_date => dispatch(setRegistrationsMeta({ to_date }));

  useEffect(() => {
    fetchRegistrations();
  }, [JSON.stringify(meta)]);

  useEffect(
    () => () => {
      dispatch(resetRegistrations());
    },
    []
  );

  return (
    <div className="registrations__wrapper">
      <h1>Registrations</h1>
      <div className="registrations__date-pickers">
        <DatePickerComponent
          dateTime={meta.from_date}
          handleDateTime={setFromDate}
          format="yyyy-MM-dd"
        />
        <span>-</span>
        <DatePickerComponent
          dateTime={meta.to_date}
          handleDateTime={setToDate}
          format="yyyy-MM-dd"
        />
      </div>
      <div className="registrations__chart-wrapper">
        <h2>Total</h2>
        <div className="registrations__chart-wrapper--pie-total">
          <div className="registrations__chart-wrapper--pie-total-value">
            {splitByCommas(
              (total.web || 0) + (total.ios || 0) + (total.android || 0) + (total.unknown || 0)
            )}
          </div>
          <div className="registrations__chart-wrapper--pie-total-label">Users</div>
        </div>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...pieChartOptions,
            series: [
              {
                name: "Users",
                colorByPoint: true,
                data: [
                  {
                    name: "Web",
                    color: "#F3BB48",
                    y: total.web || 0
                  },
                  {
                    name: "iOS",
                    color: "#57A1E6",
                    y: total.ios || 0
                  },
                  {
                    name: "Android",
                    color: "#2FC272",
                    y: total.android || 0
                  },
                  {
                    name: "Unknown",
                    color: "#737A93",
                    y: total.unknown || 0
                  }
                ]
              }
            ]
          }}
        />
      </div>
      <div className="registrations__chart-wrapper">
        <div className="registrations__chart-wrapper--header">
          <h2>History</h2>
          <div className="registrations__chart-wrapper--periods">
            {periods.map(({ label, value }) => (
              <div
                key={value}
                className={clsx([
                  "registrations__chart-wrapper--capsule",
                  meta.period === value && "active"
                ])}
                onClick={() => setPeriod(value)}
              >
                {label}
              </div>
            ))}
          </div>
        </div>
        <HighchartsReact
          highcharts={Highstock}
          options={{
            ...lineChartOptions,
            xAxis: {
              ...lineChartOptions.xAxis,
              labels: {
                style: {
                  color: "#737A93"
                },
                formatter: function() {
                  if (meta.period === "week")
                    return `${dayjs(this.value).format("D MMM YY")} - ${dayjs(this.value)
                      .add(1, "week")
                      .format("D MMM YY")}`;
                  if (meta.period === "month") return dayjs(this.value).format("MMM YY");
                  return dayjs(this.value).format("D MMM YY");
                }
              }
            },
            series: prepareLineChartData(data)
          }}
        />
      </div>
      <BackDrop open={!isFetched} />
    </div>
  );
};

export default Registrations;
