import * as yup from "yup";

const userProfileSchema = yup.object().shape({
  username: yup.string().required("The field is required"),
  first_name: yup.string().required("The field is required"),
  last_name: yup.string().required("The field is required"),
  email: yup
    .string()
    .email("Incorrect email")
    .required("The field is required"),
  phone: yup.string().required("The field is required")
});

export default userProfileSchema;
