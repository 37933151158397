import CHALLENGES_SYSTEM from "./actionTypes";
import { joinQueries } from "../../core/helpers/functions";

export function getChallenges(type, queries) {
  return {
    type: CHALLENGES_SYSTEM.GET_CHALLENGES,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/challenge2/${type}/${joinQueries(queries)}`,
        method: "get"
      }
    }
  };
}

export function postChallengesPosition(type, data) {
  return {
    type: CHALLENGES_SYSTEM.POST_CHALLENGES_POSITION,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/challenge2/${type}/position/`,
        method: "post",
        data
      }
    }
  };
}

export function getChallenge(type, id) {
  return {
    type: CHALLENGES_SYSTEM.GET_CHALLENGE,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/challenge2/${type}/${id}/`,
        method: "get"
      }
    }
  };
}

export function postChallenge(type, data) {
  return {
    type: CHALLENGES_SYSTEM.POST_CHALLENGE,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/challenge2/${type}/`,
        method: "post",
        data
      }
    }
  };
}

export function patchChallenge(type, id, data) {
  return {
    type: CHALLENGES_SYSTEM.PATCH_CHALLENGE,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/challenge2/${type}/${id}/`,
        method: "patch",
        data
      }
    }
  };
}

export function deleteChallenge(type, id) {
  return {
    type: CHALLENGES_SYSTEM.DELETE_CHALLENGE,
    challengeToDelete: id,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/challenge2/${type}/${id}/`,
        method: "delete"
      }
    }
  };
}

export function getReward(type) {
  return {
    type: CHALLENGES_SYSTEM.GET_REWARD,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/challenge2/${type}/rank/`,
        method: "get"
      }
    }
  };
}

export function patchReward(type, data) {
  return {
    type: CHALLENGES_SYSTEM.PATCH_REWARD,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/challenge2/${type}/rank/${data.name}/`,
        method: "patch",
        data
      }
    }
  };
}

export function resetChallengesSystem() {
  return {
    type: CHALLENGES_SYSTEM.RESET_CHALLENGES_SYSTEM
  };
}
