import { toast } from "react-toastify";

const notifySuccess = successSnackText =>
  toast.success(successSnackText, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });

export default notifySuccess;
