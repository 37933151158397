import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import ChallengeRow from "../ChallengeRow";
import { postChallengesPosition } from "../actions";

const DraggableChallenges = props => {
  const dispatch = useDispatch();
  const [items, setItems] = useState(props.items);

  useEffect(() => {
    if (props.items.length) setItems(props.items);
  }, [JSON.stringify(props.items)]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const itemsToSet = reorder(items, result.source.index, result.destination.index);

    setItems(itemsToSet);

    dispatch(
      postChallengesPosition(
        props.type.replace("/challenge", ""),
        [...props.ongoingChallenges, ...itemsToSet].reduce(function(result, el, key) {
          result[el.id] = key;
          return result;
        }, {})
      )
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={{ width: "100%" }}>
            {items.map((item, index) => (
              <Draggable
                key={`draggable-${item.id}`}
                draggableId={`draggable-${item.id}`}
                index={index}
              >
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ChallengeRow {...item} showDrag />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableChallenges;
