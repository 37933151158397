import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import Pagination from "../../_shared/Pagination";
import SearchBy from "../../_shared/SearchBy";
import NoItems from "../../_shared/NoItems";
import DefaultButton from "../../_shared/Buttons/DefaultButton";
import Skeleton from "../../_shared/Skeleton";
import { matchesTableCols, matchesInitialFilters } from "../constants";
import { getSkillChallengesMatches, setMatchesMeta } from "../actions";
import MatchItem from "./MatchItem";
import Filters from "./Filters";

import FiltersIcon from "../../../assets/image/filters.svg";

const Matches = () => {
  const [filtersIsOpen, setFiltersIsOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    matches: { results, count, filter_options },
    matchesMeta,
    reloadMatches
  } = useSelector(({ skillChallenges }) => skillChallenges);

  const prepareParamsArray = () =>
    Object.entries(matchesMeta).map(obj => `${obj[0]}=${encodeURIComponent(obj[1].toString())}`);

  const fetchMatches = async () => {
    const res = await dispatch(getSkillChallengesMatches(prepareParamsArray(matchesMeta)));
    if (res.error) setPage(1);
  };

  useEffect(() => {
    fetchMatches();
  }, [JSON.stringify(matchesMeta)]);

  useEffect(() => {
    if (reloadMatches) fetchMatches();
  }, [reloadMatches]);

  const setPage = page => dispatch(setMatchesMeta({ page }));
  const setOrdering = ordering => dispatch(setMatchesMeta({ ordering }));
  const setSearch = search => dispatch(setMatchesMeta({ search }));

  const handleFiltersPanel = () => setFiltersIsOpen(!filtersIsOpen);
  const onFiltersReset = () => dispatch(setMatchesMeta(matchesInitialFilters));

  const numberOfFilters = useMemo(
    () =>
      Object.entries(matchesInitialFilters).reduce((acc, el) => {
        if (matchesMeta[el[0]] !== "") return acc + 1;
        return acc;
      }, 0),
    [matchesMeta]
  );

  return (
    <>
      <div className="search-and-filters">
        <SearchBy placeholder="Search by game, username or email" onSearch={setSearch} />
        <Skeleton
          loading={!filter_options}
          variant="rect"
          width={118}
          height={42}
          classes="border-radius-22 ml-32"
        >
          <DefaultButton
            variant="outlined"
            type="button"
            onClick={handleFiltersPanel}
            classes="blue-btn"
          >
            <img src={FiltersIcon} alt="filters" className="mr-8" />
            Filters
            {!!numberOfFilters && (
              <div className="search-and-filters__filters-number">{numberOfFilters}</div>
            )}
          </DefaultButton>
        </Skeleton>
        {!!numberOfFilters && (
          <div className="users__filters--reset text-hover" onClick={onFiltersReset}>
            Reset all filters
          </div>
        )}
      </div>
      <section className="skill-challenges__matches-list">
        {results.length ? (
          <div className="skill-challenges__matches-user-row header-row">
            {matchesTableCols.map(({ label, value }) => (
              <div
                className={clsx(
                  "skill-challenges__matches-user-row--col header-col",
                  (matchesMeta.ordering === value || matchesMeta.ordering === `-${value}`) &&
                    "active-sort"
                )}
                key={value}
                onClick={() => setOrdering(matchesMeta.ordering === value ? `-${value}` : value)}
              >
                {label}
                <div
                  className={clsx(
                    "sort-arrow with-transition",
                    matchesMeta.ordering === value && "active",
                    matchesMeta.ordering === `-${value}` && "active-rotated"
                  )}
                />
              </div>
            ))}
          </div>
        ) : (
          <NoItems />
        )}
        {results.map(match => (
          <MatchItem
            key={`match_${match.id}`}
            {...match}
            connection={match.connection || {}}
            details={match.details || {}}
          />
        ))}
      </section>
      {count > 0 && <Pagination onChange={setPage} total={count} page={matchesMeta.page} />}
      {filter_options && (
        <Filters
          filterOptions={filter_options}
          handleFiltersPanel={handleFiltersPanel}
          filtersIsOpen={filtersIsOpen}
        />
      )}
    </>
  );
};

export default Matches;
