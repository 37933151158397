import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Login from "../../components/Auth/Login";
import NotFound from "../../components/NotFound";

import { closeErrorSnack } from "../../core/appActions";
import urls from "../../core/urls";

import LogoIcon from "../../assets/image/logo.svg";

import "./styles.scss";

const AuthContainer = ({ match }) => {
  if (localStorage.token) return <Redirect to={urls.usersUrl} />;
  return (
    <>
      <main className="auth_container">
        <img src={LogoIcon} alt="gaming stars" />
        <Switch>
          <Route path={match.url} exact render={() => <Redirect to={urls.loginUrl} />} />
          <Route path={urls.loginUrl} exact component={Login} />
          <Route render={() => <NotFound />} />
        </Switch>
        <div />
      </main>
    </>
  );
};

const mapStateToProps = ({ app }) => {
  return {
    errorSnack: app.errorSnack,
    errorSnackText: app.errorSnackText
  };
};

const mapDispatchToProps = {
  closeErrorSnack
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
