import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import InputField from "../../../_shared/InputField";
import {
  getProfilePlatforms,
  patchProfilePlatform,
  postDisconnectProfilePlatform
} from "../../actions";
import { yupResolver } from "@hookform/resolvers/yup";
import platformFieldsSchema from "./platformFieldsSchema";
import { platformStatuses } from "../../../../core/helpers/constants";
import { getOption, handleValidationErrors } from "../../../../core/helpers/functions";
import SelectComponent from "../../../_shared/SelectComponent";
import DefaultButton from "../../../_shared/Buttons/DefaultButton";
import { closeConfirmationDialog, setConfirmationDialog } from "../../../../core/appActions";

const propTypes = {
  status: PropTypes.string.isRequired,
  profileID: PropTypes.string,
  platformID: PropTypes.number.isRequired,
  onEditClick: PropTypes.func.isRequired
};

const defaultProps = {
  profileID: ""
};

const PlatformFields = ({ status, profileID, platformID, onEditClick }) => {
  const dispatch = useDispatch();
  const {
    general: { id: userID }
  } = useSelector(({ userProfile }) => userProfile);
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(platformFieldsSchema),
    defaultValues: {
      profile_id: profileID,
      status: {
        label: getOption(platformStatuses.find(el => el.value === status).label),
        value: status
      }
    },
    shouldFocusError: true
  });

  const prepareDataForSubmit = data => {
    return {
      profile_id: data.profile_id,
      status: data.status.value
    };
  };

  const onCancelClick = () => onEditClick(platformID);

  const onSubmit = async data => {
    const res = await dispatch(
      patchProfilePlatform(userID, platformID, prepareDataForSubmit(data))
    );
    if (res.error) {
      handleValidationErrors(res, setError);
    } else {
      dispatch(getProfilePlatforms(userID));
      onCancelClick();
    }
  };

  const onDisconnectClick = () => {
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: async () => {
          await dispatch(postDisconnectProfilePlatform(userID, platformID));
          onCancelClick();
        },
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: "Disconnect platform",
        description: "Are you sure you want to disconnect this platform?",
        confirmText: "Disconnect",
        declineText: "Cancel"
      })
    );
  };

  return (
    <form className="user-profile__platforms--fields" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="profile_id"
        control={control}
        render={props => (
          <InputField
            id="profile-id-input"
            label="Profile ID"
            type="text"
            {..._.omit(props.field, "ref")}
            error={errors.profile_id?.message}
          />
        )}
      />
      <Controller
        name="status"
        control={control}
        render={props => (
          <SelectComponent
            change={props.field.onChange}
            id="status-select"
            placeholder=""
            {..._.omit(props.field, "ref")}
            options={platformStatuses.map(({ label, value }) => ({
              label: getOption(label),
              value
            }))}
          />
        )}
      />
      <div className="flex-center">
        <DefaultButton variant="contained" classes="green-btn" loading={buttonLoading} formAction>
          Save changes
        </DefaultButton>
        <DefaultButton variant="outlined" onClick={onCancelClick} classes="grey-btn">
          Cancel
        </DefaultButton>
      </div>
      <div className="user-profile__platforms--disconnect text-hover" onClick={onDisconnectClick}>
        Disconnect
      </div>
    </form>
  );
};

PlatformFields.propTypes = propTypes;
PlatformFields.defaultProps = defaultProps;

export default PlatformFields;
