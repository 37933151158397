import AUTH from "./actionTypes";

const INITIAL_STATE = {
  authError: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH.LOGIN:
      return { ...state, authError: false };
    case AUTH.LOGIN_FAIL:
      return { ...state, authError: true };

    default:
      return state;
  }
}
