import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

import "./styles.scss";

const Preloader = () => (
  <div className="preloader_wrapper">
    <LinearProgress />
  </div>
);

export default Preloader;
