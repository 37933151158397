import TOURNAMENTS from "./actionTypes";
import APP from "../../core/appActionTypes";

const INITIAL_STATE = {
  selectOptions: { loading: true },
  tournamentsList: {
    count: 10,
    loading: true,
    results: Array.from({ length: 10 }, (v, k) => ({ id: k }))
  },
  usersList: { open: false, title: "", numberOfUsers: 0, users: [] },
  tournament: { loading: true },
  disputes: {
    count: 10,
    results: Array.from({ length: 10 }, (v, k) => ({ id: k, loading: true }))
  },
  disputeInfo: { initial: true },
  submittingWinner: false,
  disputeMessages: { load: false, results: [] },
  disputesDialogOpen: false,
  currentTournamentsDisputes: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOURNAMENTS.GET_TOURNAMENT_SELECT_OPTIONS_SUCCESS:
      return { ...state, selectOptions: action.payload.data };
    case TOURNAMENTS.GET_TOURNAMENTS_SUCCESS:
      return { ...state, tournamentsList: { loading: false, ...action.payload.data } };
    case TOURNAMENTS.RESET_TOURNAMENTS:
      return {
        ...state,
        tournamentsList: INITIAL_STATE.tournamentsList,
        tournament: INITIAL_STATE.tournament,
        usersList: INITIAL_STATE.usersList
      };
    case TOURNAMENTS.SET_USERS_LIST:
      return { ...state, usersList: { ...state.usersList, ...action.users } };
    case TOURNAMENTS.SET_DISPUTE_DIALOG_OPEN:
      return { ...state, disputesDialogOpen: action.disputesDialogOpen };
    case TOURNAMENTS.GET_TOURNAMENT_SUCCESS:
      return { ...state, tournament: action.payload.data };
    case TOURNAMENTS.RESET_TOURNAMENT_INFO:
      return { ...state, tournament: INITIAL_STATE.tournament, usersList: INITIAL_STATE.usersList };

    case TOURNAMENTS.POST_JOINED_CHECK_IN_SUCCESS:
      return {
        ...state,
        tournament: { ...state.tournament, ...action.payload.data },
        usersList: {
          ...state.usersList,
          participants: [...action.payload.data.participants],
          users: [...action.payload.data.joined],
          numberOfUsers: action.payload.data.joined_count
        }
      };
    case TOURNAMENTS.POST_JOINED_CHECK_IN_ALL_SUCCESS:
      return {
        ...state,
        tournament: { ...state.tournament, ...action.payload.data },
        usersList: {
          ...state.usersList,
          participants: [...action.payload.data.participants],
          users: [...action.payload.data.joined],
          numberOfUsers: action.payload.data.joined_count
        }
      };
    case TOURNAMENTS.POST_JOINED_REMOVE_SUCCESS:
      return {
        ...state,
        tournament: { ...state.tournament, ...action.payload.data },
        usersList: {
          ...state.usersList,
          participants: [...action.payload.data.participants],
          users: [...action.payload.data.joined],
          numberOfUsers: action.payload.data.joined_count
        }
      };
    case TOURNAMENTS.POST_JOINED_REMOVE_ALL_SUCCESS:
      return {
        ...state,
        tournament: { ...state.tournament, ...action.payload.data },
        usersList: {
          ...state.usersList,
          users: [...action.payload.data.joined],
          numberOfUsers: action.payload.data.joined_count
        }
      };
    case TOURNAMENTS.POST_JOINED_ADD_RANDOM_SUCCESS:
      return {
        ...state,
        tournament: { ...state.tournament, ...action.payload.data },
        usersList: {
          ...state.usersList,
          users: [...action.payload.data.joined],
          numberOfUsers: action.payload.data.joined_count
        }
      };
    case TOURNAMENTS.POST_WAITLIST_REMOVE_SUCCESS:
      return {
        ...state,
        tournament: { ...state.tournament, ...action.payload.data },
        usersList: {
          ...state.usersList,
          users: [...action.payload.data.waitlist],
          numberOfUsers: action.payload.data.waitlist_count
        }
      };
    case TOURNAMENTS.POST_WAITLIST_REMOVE_ALL_SUCCESS:
      return {
        ...state,
        tournament: { ...state.tournament, ...action.payload.data },
        usersList: {
          ...state.usersList,
          users: [...action.payload.data.waitlist],
          numberOfUsers: action.payload.data.waitlist_count
        }
      };
    case TOURNAMENTS.POST_WAITLIST_ADD_RANDOM_SUCCESS:
      return {
        ...state,
        tournament: { ...state.tournament, ...action.payload.data },
        usersList: {
          ...state.usersList,
          users: [...action.payload.data.waitlist],
          numberOfUsers: action.payload.data.waitlist_count
        }
      };

    case TOURNAMENTS.POST_START_TOURNAMENT_SUCCESS:
      return {
        ...state,
        tournament: { ...state.tournament, ...action.payload.data }
      };

    case TOURNAMENTS.POST_TOURNAMENT_RANDOM_PLAY_SUCCESS:
      return {
        ...state,
        tournament: { ...state.tournament, ...action.payload.data }
      };

    case TOURNAMENTS.POST_MATCH_RANDOM_PLAY_SUCCESS:
      return {
        ...state,
        tournament: { ...state.tournament, ...action.payload.data }
      };

    case TOURNAMENTS.POST_MATCH_WINNER_SUCCESS:
      return {
        ...state,
        tournament: { ...state.tournament, ...action.payload.data }
      };

    case APP.SET_SOCKET_DATA:
      if (
        action.adminWSType === "tournament_bracket_updated" &&
        action.adminWSData?.bracket?.tournament === state.tournament.id
      ) {
        return {
          ...state,
          tournament: { ...state.tournament, bracket: action.adminWSData.bracket }
        };
      }
      if (
        action.adminWSType === "tournament_dispute_created" &&
        action.adminWSData?.tournament_match?.tournament?.id === state.tournament.id
      ) {
        return {
          ...state,
          tournament: { ...state.tournament, disputes_count: state.tournament.disputes_count + 1 }
        };
      }
      return state;

    case TOURNAMENTS.GET_TOURNAMENT_DISPUTES_LIST:
      return { ...state, disputes: INITIAL_STATE.disputes };
    case TOURNAMENTS.GET_TOURNAMENT_DISPUTES_LIST_SUCCESS:
      return { ...state, disputes: action.payload.data };
    case TOURNAMENTS.GET_TOURNAMENT_DISPUTE_INNER_SUCCESS:
      return { ...state, disputeInfo: { ...action.payload.data, initial: false } };
    case TOURNAMENTS.RESET_TOURNAMENT_DISPUTE_INNER:
      return {
        ...state,
        disputeInfo: INITIAL_STATE.disputeInfo,
        disputeMessages: INITIAL_STATE.disputeMessages
      };
    case TOURNAMENTS.POST_SET_TOURNAMENT_DISPUTE_WINNER:
      return { ...state, submittingWinner: true };
    case TOURNAMENTS.POST_SET_TOURNAMENT_DISPUTE_WINNER_SUCCESS:
    case TOURNAMENTS.POST_SET_TOURNAMENT_DISPUTE_WINNER_FAIL:
      return { ...state, submittingWinner: false };
    case TOURNAMENTS.GET_DISPUTE_MESSAGES_SUCCESS:
      return { ...state, disputeMessages: { load: true, ...action.payload.data } };
    case TOURNAMENTS.UPDATE_DISPUTE_MESSAGE_LIST:
      return {
        ...state,
        disputeMessages: {
          ...state.disputeMessages,
          results: [action.data, ...state.disputeMessages.results]
        }
      };

    case TOURNAMENTS.GET_CURRENT_TOURNAMENT_DISPUTES:
      return { ...state, currentTournamentsDisputes: [] };
    case TOURNAMENTS.GET_CURRENT_TOURNAMENT_DISPUTES_SUCCESS:
      return { ...state, currentTournamentsDisputes: action.payload.data };
    default:
      return state;
  }
}
