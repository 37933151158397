import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { postTournamentRandomPlay } from "../../../actions";

import BranchComponent from "./BranchComponent";
import Loader from "../../../../_shared/Loader";
import IconButtonComponent from "../../../../_shared/Buttons/IconButton";
import { getTournamentStageByDepthIndex } from "../../../../../core/helpers/functions";

import RandomWinnerButton from "../../../../../assets/image/random-winner.svg";

import "./styles.scss";

const TournamentBranch = ({ bracket, is_test, tournamentID, state }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onRandomPlayClick = async () => {
    await dispatch(postTournamentRandomPlay(tournamentID));
  };

  return (
    <section className="default-content-wrapper tournament-branch">
      {!bracket ? (
        <Loader />
      ) : (
        <>
          <div className="tournament-stages">
            {bracket.levels.map(({ id, depth_index }) => (
              <div className="tournament-stage" key={`stage_${id}`}>
                {getTournamentStageByDepthIndex(depth_index, t)}
                {is_test && state === "playing" && depth_index === 1 && (
                  <IconButtonComponent
                    onClick={onRandomPlayClick}
                    alt="random play"
                    src={RandomWinnerButton}
                  />
                )}
              </div>
            ))}
          </div>
          <BranchComponent
            bracket={bracket}
            state={state}
            tournamentID={tournamentID}
            is_test={is_test}
          />
        </>
      )}
    </section>
  );
};

export default TournamentBranch;
