const CHALLENGES_SYSTEM = {
  GET_CHALLENGES: "GET_CHALLENGES",
  GET_CHALLENGES_SUCCESS: "GET_CHALLENGES_SUCCESS",
  GET_CHALLENGES_FAIL: "GET_CHALLENGES_FAIL",

  POST_CHALLENGES_POSITION: "POST_CHALLENGES_POSITION",
  POST_CHALLENGES_POSITION_SUCCESS: "POST_CHALLENGES_POSITION_SUCCESS",
  POST_CHALLENGES_POSITION_FAIL: "POST_CHALLENGES_POSITION_FAIL",

  GET_CHALLENGE: "GET_CHALLENGE",
  GET_CHALLENGE_SUCCESS: "GET_CHALLENGE_SUCCESS",
  GET_CHALLENGE_FAIL: "GET_CHALLENGE_FAIL",

  POST_CHALLENGE: "POST_CHALLENGE",
  POST_CHALLENGE_SUCCESS: "POST_CHALLENGE_SUCCESS",
  POST_CHALLENGE_FAIL: "POST_CHALLENGE_FAIL",

  PATCH_CHALLENGE: "PATCH_CHALLENGE",
  PATCH_CHALLENGE_SUCCESS: "PATCH_CHALLENGE_SUCCESS",
  PATCH_CHALLENGE_FAIL: "PATCH_CHALLENGE_FAIL",

  DELETE_CHALLENGE: "DELETE_CHALLENGE",
  DELETE_CHALLENGE_SUCCESS: "DELETE_CHALLENGE_SUCCESS",
  DELETE_CHALLENGE_FAIL: "DELETE_CHALLENGE_FAIL",

  GET_REWARD: "GET_REWARD",
  GET_REWARD_SUCCESS: "GET_REWARD_SUCCESS",
  GET_REWARD_FAIL: "GET_REWARD_FAIL",

  PATCH_REWARD: "PATCH_REWARD",
  PATCH_REWARD_SUCCESS: "PATCH_REWARD_SUCCESS",
  PATCH_REWARD_FAIL: "PATCH_REWARD_FAIL",

  RESET_CHALLENGES_SYSTEM: "RESET_CHALLENGES_SYSTEM"
};

export default CHALLENGES_SYSTEM;
