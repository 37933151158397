import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlLabel } from "@material-ui/core";
import _ from "lodash";
import clsx from "clsx";

import { getProfileSkillChallenges, patchProfileSkillChallenges } from "../actions";
import DefaultButton from "../../_shared/Buttons/DefaultButton";
import skillChallengesSchema from "./skillChallengesSchema";
import { handleValidationErrors } from "../../../core/helpers/functions";
import InputField from "../../_shared/InputField";
import CheckField from "../../_shared/CheckField";
import { userProfileSkillChallengesStatistics } from "../constants";

const SkillChallenges = ({ renderLoader }) => {
  const dispatch = useDispatch();
  const {
    general: { id: userID },
    skillChallenges: {
      initial,
      boy_stake_limit,
      is_boy_stake_limit_enabled,
      is_auto_boy_stake_limit_enabled,
      statistics
    }
  } = useSelector(({ userProfile }) => userProfile);
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(skillChallengesSchema),
    defaultValues: {
      boy_stake_limit: boy_stake_limit || "",
      is_boy_stake_limit_enabled: is_boy_stake_limit_enabled || false,
      is_auto_boy_stake_limit_enabled: is_auto_boy_stake_limit_enabled || false
    },
    shouldFocusError: true
  });

  useEffect(() => {
    dispatch(getProfileSkillChallenges(userID));
  }, []);

  useEffect(() => {
    if (!initial) {
      setValue("boy_stake_limit", boy_stake_limit);
      setValue("is_boy_stake_limit_enabled", is_boy_stake_limit_enabled);
      setValue("is_auto_boy_stake_limit_enabled", is_auto_boy_stake_limit_enabled);
    }
  }, [initial]);

  const onSubmit = async data => {
    const res = await dispatch(patchProfileSkillChallenges(userID, data));
    if (res.error) handleValidationErrors(res, setError);
  };

  if (initial) return renderLoader();

  return (
    <form
      className="user-profile__content-wrapper user-profile__skill-challenges"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="user-profile__general--subtitle">Limit</div>
      <div className="user-profile__skill-challenges--limit">
        <div className="user-profile__skill-challenges--form-fields">
          <Controller
            name="boy_stake_limit"
            control={control}
            render={props => (
              <InputField
                id="boy-stake-limit-input"
                label="Stake limit"
                type="text"
                {..._.omit(props.field, "ref")}
                error={errors.boy_stake_limit?.message}
                endAdornment={<span>€</span>}
              />
            )}
          />
          <FormControlLabel
            classes={{
              root: "user-profile__general--check-wrapper",
              label: "user-profile__general--check-label"
            }}
            control={
              <Controller
                name="is_boy_stake_limit_enabled"
                control={control}
                render={props => <CheckField {..._.omit(props.field, "ref")} />}
              />
            }
            label="Enable Skill Challenges stake limit"
          />
          <FormControlLabel
            classes={{
              root: "user-profile__general--check-wrapper",
              label: "user-profile__general--check-label"
            }}
            control={
              <Controller
                name="is_auto_boy_stake_limit_enabled"
                control={control}
                render={props => <CheckField {..._.omit(props.field, "ref")} />}
              />
            }
            label="Enable auto Skill Challenges stake limit"
          />
        </div>
      </div>
      <div className="user-profile__general--subtitle">Statistics</div>
      <div className="user-profile__skill-challenges--statistics">
        {userProfileSkillChallengesStatistics.map(({ label, value, color, unit }) => (
          <div key={value} className="user-profile__skill-challenges--statistics-row">
            <p className="user-profile__skill-challenges--statistics-label">{`${label}:`}</p>
            <p className={clsx("user-profile__skill-challenges--statistics-value", color)}>{`${
              unit === "€" ? statistics[value].toFixed(2) : statistics[value]
            }${unit}`}</p>
          </div>
        ))}
      </div>
      <div className="user-profile__general--save-btn">
        <DefaultButton variant="contained" classes="green-btn" loading={buttonLoading} formAction>
          Save changes
        </DefaultButton>
      </div>
    </form>
  );
};

export default SkillChallenges;
