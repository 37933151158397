import USERS from "./actionTypes";
import { joinQueries } from "../../core/helpers/functions";

export function getUsers(queries) {
  return {
    type: USERS.GET_USERS,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/users/list/${joinQueries(queries)}`,
        method: "get"
      }
    }
  };
}

export function getUserInListInfo(id) {
  return {
    type: USERS.GET_USER_IN_LIST_INFO,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/users/list/${id}/`,
        method: "get"
      }
    }
  };
}

export function setUsersMeta(meta) {
  return {
    type: USERS.SET_USERS_META,
    meta
  };
}

export function selectUsers(users, toRemove) {
  return {
    type: USERS.SELECT_USERS,
    users,
    toRemove
  };
}

export function deselectUsers() {
  return {
    type: USERS.DESELECT_USERS
  };
}

export function postActivateUser(id) {
  return {
    type: USERS.POST_ACTIVATE_USER,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/users/list/${id}/activate/`,
        method: "post"
      }
    }
  };
}

export function postDeactivateUser(id) {
  return {
    type: USERS.POST_DEACTIVATE_USER,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/users/list/${id}/deactivate/`,
        method: "post"
      }
    }
  };
}

export function postBlockUser(id) {
  return {
    type: USERS.POST_BLOCK_USER,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/users/list/${id}/block/`,
        method: "post"
      }
    }
  };
}

export function postUnblockUser(id) {
  return {
    type: USERS.POST_UNBLOCK_USER,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/users/list/${id}/unblock/`,
        method: "post"
      }
    }
  };
}

export function postNewUser(data) {
  return {
    type: USERS.POST_NEW_USER,
    payload: {
      client: "default",
      request: {
        url: "/api/admin/users/add-user/",
        method: "post",
        data
      }
    }
  };
}

export function resetUsers() {
  return {
    type: USERS.RESET_USERS
  };
}
