import React from "react";
import { withTranslation } from "react-i18next";

import BackLink from "../../_shared/BackLink";
import urls from "../../../core/urls";
import TournamentFields from "../TournamentFields";

import "./styles.scss";

const AddNewTournament = ({ t }) => {
  return (
    <div className="default-content-wrapper add-new-tournament">
      <BackLink to={urls.tournamentsUrl} />
      <h1>{t("t:tournaments.add-new-tournament")}</h1>
      <TournamentFields />
    </div>
  );
};

export default withTranslation()(AddNewTournament);
