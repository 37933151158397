import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { useQueryParams } from "../../core/helpers/hooks";
import urls from "../../core/urls";
import {
  challengesSystemTabs,
  dailyChampionTabs,
  dailyChampionTableCols,
  challengesTableCols,
  challengesRanks
} from "./constants";
import { getChallenges, resetChallengesSystem } from "./actions";
import Tabs from "../_shared/Tabs";
import DefaultButton from "../_shared/Buttons/DefaultButton";
import NoItems from "../_shared/NoItems";
import BackDrop from "../_shared/BackDrop";
import HeaderRow from "./HeaderRow";
import ChallengeRow from "./ChallengeRow";
import DraggableChallenges from "./DraggableChallenges";
import Reward from "./Reward";

import "./styles.scss";

const ChallengesSystem = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { getParam, queries } = useQueryParams();
  const { challenges, isLoading } = useSelector(({ challengesSystem }) => challengesSystem);

  const [meta, setMeta] = useState({
    tab: getParam("tab"),
    subTab: getParam("tab") === "daily" ? "" : "no_rank"
  });

  const setMetaField = (name, value) => {
    setMeta({
      ...meta,
      [name]: value
    });
  };

  const { subTabs, tableCols, typeKey, allowDrag } = useMemo(
    () => ({
      subTabs: meta.tab === "daily" ? dailyChampionTabs : challengesRanks,
      tableCols: meta.tab === "daily" ? dailyChampionTableCols : challengesTableCols,
      typeKey: meta.tab === "daily" ? "game_type" : "rank_name",
      allowDrag: (meta.tab === "daily" && !meta.subTab) || meta.tab !== "daily"
    }),
    [meta, challenges]
  );

  const { ongoingChallenges, draggableChallenges, activeChallenges, inactiveChallenges } = useMemo(
    () => ({
      ongoingChallenges: challenges.reduce((result, el) => {
        if (el.is_ongoing && (!meta.subTab || el[typeKey] === meta.subTab)) {
          result.push({
            ...el,
            challengeType: meta.tab
          });
        }
        return result;
      }, []),
      draggableChallenges: challenges.reduce((result, el) => {
        if (el.is_active && !el.is_ongoing && (!meta.subTab || el[typeKey] === meta.subTab)) {
          result.push({
            ...el,
            challengeType: meta.tab
          });
        }
        return result;
      }, []),
      activeChallenges: challenges.reduce((result, el) => {
        if (el.is_active && (!meta.subTab || el[typeKey] === meta.subTab)) {
          result.push({
            ...el,
            challengeType: meta.tab
          });
        }
        return result;
      }, []),
      inactiveChallenges: challenges.reduce((result, el) => {
        if (!el.is_active && (!meta.subTab || el[typeKey] === meta.subTab)) {
          result.push({
            ...el,
            challengeType: meta.tab
          });
        }
        return result;
      }, [])
    }),
    [challenges, meta.subTab]
  );

  useEffect(() => {
    if (meta.tab !== getParam("tab"))
      setMeta({
        ...meta,
        tab: getParam("tab"),
        subTab: getParam("tab") === "daily" ? "" : "no_rank"
      });
  }, [queries]);

  const getQueries = () => ({
    game_type: "",
    ordering: "position"
  });

  useEffect(() => {
    dispatch(
      getChallenges(
        meta.tab,
        Object.entries(getQueries()).map(obj => `${obj[0]}=${obj[1]}`)
      )
    );
  }, [meta.tab]);

  useEffect(
    () => () => {
      dispatch(resetChallengesSystem());
    },
    []
  );

  const onTabChange = newTab => history.push(`${urls.challengesSystemUrl}?tab=${newTab}`);

  const onAddNewClick = () => {
    if (meta.tab === "daily") history.push(urls.addDailyChallengeUrl);
    if (meta.tab === "1vs1/challenge") history.push(urls.addOneVsOneChallengeUrl);
    if (meta.tab === "skill/challenge") history.push(urls.addSkillChallengeUrl);
  };

  return (
    <div className="default-content-wrapper challenges-system">
      <h1>Challenges System</h1>
      <Tabs currentTab={meta.tab} tabs={challengesSystemTabs} onTabChange={onTabChange} />
      <div className="challenges-system__sub-tabs-wrapper">
        <Tabs
          currentTab={meta.subTab}
          tabs={subTabs}
          onTabChange={val => setMetaField("subTab", val)}
          type="capsule"
        />
        <div className="challenges-system__sub-tabs-wrapper--actions">
          {meta.tab !== "daily" && (
            <Reward challengeType={meta.tab.replace("/challenge", "")} defaultRank={meta.subTab} />
          )}
          <DefaultButton variant="contained" onClick={onAddNewClick} classes="green-btn">
            + Add new
          </DefaultButton>
        </div>
      </div>
      <div className="challenges-system__subtitle">Published</div>
      <HeaderRow cols={tableCols} />
      {allowDrag ? (
        <>
          {ongoingChallenges.map(el => (
            <ChallengeRow {...el} key={el.id} showDrag />
          ))}
          {!!draggableChallenges.length && (
            <DraggableChallenges
              items={draggableChallenges}
              type={meta.tab}
              ongoingChallenges={ongoingChallenges}
            />
          )}
        </>
      ) : (
        <>
          {activeChallenges
            .filter(el => el[typeKey] === meta.subTab)
            .map(el => (
              <ChallengeRow {...el} key={el.id} />
            ))}
        </>
      )}
      {!activeChallenges.length && <NoItems />}
      <div className="challenges-system__subtitle">Not Published</div>
      <HeaderRow cols={tableCols} />
      {inactiveChallenges.map(el => (
        <ChallengeRow {...el} key={el.id} />
      ))}
      {!inactiveChallenges.length && <NoItems />}
      <BackDrop open={isLoading} />
    </div>
  );
};

export default ChallengesSystem;
