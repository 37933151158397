import React, { memo } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import { getGameInfo, getTournamentStageByDepthIndex } from "../../../../../core/helpers/functions";
import urls from "../../../../../core/urls";
import Skeleton from "../../../../_shared/Skeleton";

import { setUsersList } from "../../../actions";

import ParticipantsIcon from "../../../../../assets/image/participants.svg";
import RoundOfIcon from "../../../../../assets/image/round-of.svg";

import "./styles.scss";

const defaultProps = {
  name: "",
  startDate: dayjs().add(1, "day"),
  game: 8,
  platform: 1,
  entryFee: null,
  participants: [],
  participantsCount: null,
  tournamentID: null,
  state: "",
  is_test: false
};

const CurrentTournament = ({
  loading,
  name,
  startDate,
  game,
  platform,
  entryFee,
  participants,
  participantsCount,
  state,
  tournamentID,
  is_test,
  t
}) => {
  const dispatch = useDispatch();

  const onParticipantsClick = () => {
    dispatch(
      setUsersList({
        open: true,
        title: t("t:tournaments.participants"),
        numberOfUsers: participantsCount,
        users: participants
      })
    );
  };

  return (
    <div className="current-tournament-in-list">
      <div className="first-block">
        <Skeleton loading={loading} variant="text" width={200}>
          <Link to={urls.tournamentUrl.replace(":id", tournamentID)} className="name text-hover">
            <p className="text-overflow">{name}</p>
            {is_test && (
              <>
                <span className="dot-separator">•</span>
                Test
              </>
            )}
          </Link>
        </Skeleton>
        <Skeleton loading={loading} variant="text" width={180}>
          <div className="start-date">
            {t("t:tournaments.start-at")} {dayjs(startDate).format("DD.MM.YYYY HH:mm")}
          </div>
        </Skeleton>
      </div>
      <div className="second-block">{getGameInfo(game, platform, loading)}</div>
      <div className="third-block">
        <Skeleton loading={loading} variant="text" width={25}>
          {entryFee > 0 ? <div className="fee">{entryFee}€</div> : <div className="free">Free</div>}
        </Skeleton>
        <Skeleton loading={loading} variant="text" width={50}>
          <div className="label lower-case">{t("t:tournaments.entry-fee")}</div>
        </Skeleton>
      </div>
      <div className="fourth-block">
        <Skeleton loading={loading} variant="circle" width={34} height={34}>
          <img className="participants-icon" src={ParticipantsIcon} alt="users" />
        </Skeleton>
        <div className="participants">
          <Skeleton loading={loading} variant="text" width={25}>
            <div className="value" onClick={onParticipantsClick}>
              {participantsCount}
            </div>
          </Skeleton>
          <Skeleton loading={loading} variant="text" width={80}>
            <div className="label">{t("t:tournaments.participants")}</div>
          </Skeleton>
        </div>
      </div>
      <div className="last-block">
        <Skeleton
          loading={loading}
          variant="rect"
          height={36}
          width={150}
          classes="border-radius-22"
        >
          <div className="state">
            <img className="round-icon" src={RoundOfIcon} alt="state" />
            {getTournamentStageByDepthIndex(state, t)}
          </div>
        </Skeleton>
      </div>
    </div>
  );
};

CurrentTournament.defaultProps = defaultProps;

export default memo(withTranslation()(CurrentTournament));
