import React from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import "./styles.scss";

const propTypes = {
  currentTab: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }))
    .isRequired,
  onTabChange: PropTypes.func,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(["default", "capsule"])
};

const TabsComponent = ({ currentTab, tabs, onTabChange, loading = false, type = "default" }) => {
  return (
    <Tabs
      value={currentTab}
      onChange={(e, value) => onTabChange(value)}
      classes={{
        root: `tabs-wrapper tabs-wrapper__${type}`,
        flexContainer: `tabs-container tabs-container__${type}`,
        indicator: `tab-indicator tab-indicator__${type}`
      }}
    >
      {tabs.map(({ label, value }) => (
        <Tab
          key={value}
          label={label}
          value={value}
          disabled={loading}
          classes={{
            root: `tab-wrapper tab-wrapper__${type}`,
            selected: `tab-selected tab-selected__${type}`
          }}
        />
      ))}
    </Tabs>
  );
};

TabsComponent.propTypes = propTypes;

export default TabsComponent;
