import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import urls from "../../../../core/urls";

import UsersIcon from "../../../../assets/image/users.svg";
import SkillChallengesIcon from "../../../../assets/image/skill_challenges.svg";
import TournamentsIcon from "../../../../assets/image/tournaments.svg";
import DisputesIcon from "../../../../assets/image/disputes.svg";
import ChallengesIcon from "../../../../assets/image/challenges-menu.svg";
import RegistrationsIcon from "../../../../assets/image/registrations.svg";

import "./styles.scss";

const Panel = () => {
  const { t } = useTranslation();
  const { widePanel, unresolvedDisputesCount } = useSelector(({ app }) => app);
  return (
    <aside className={clsx("app-panel", widePanel ? "wide" : "hidden")}>
      <nav>
        <NavLink to={urls.usersUrl} activeClassName="active-link">
          <img src={UsersIcon} alt="users" />
          <p className="link-name">{t("t:shared.users")}</p>
        </NavLink>
        <NavLink to={urls.registrationsUrl} activeClassName="active-link">
          <img src={RegistrationsIcon} alt="registrations" />
          <p className="link-name">{t("t:shared.registrations")}</p>
        </NavLink>
        <NavLink to={`${urls.challengesSystemUrl}?tab=daily`} activeClassName="active-link">
          <img src={ChallengesIcon} alt="challenges" />
          <p className="link-name">{t("t:shared.challenges-system")}</p>
        </NavLink>
        <NavLink to={`${urls.skillChallengesUrl}?tab=connection`} activeClassName="active-link">
          <img src={SkillChallengesIcon} alt="users" />
          <p className="link-name">{t("t:shared.skill-challenges")}</p>
        </NavLink>
        <NavLink exact to={urls.tournamentsUrl} activeClassName="active-link">
          <img src={TournamentsIcon} alt="tournaments" />
          <p className="link-name">{t("t:shared.tournaments")}</p>
        </NavLink>
        <NavLink exact to={urls.tournamentDisputesUrl} activeClassName="active-link">
          <img src={DisputesIcon} alt="disputes" />
          <p className="link-name">{t("t:shared.tournament-disputes")}</p>
          {!!unresolvedDisputesCount && (
            <div className="unread-number">{unresolvedDisputesCount}</div>
          )}
        </NavLink>
      </nav>
    </aside>
  );
};

export default Panel;
