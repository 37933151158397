import React from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ThemeProvider } from "@material-ui/core/styles";
import defaultMaterialTheme from "./MaterialUITheme";

import CalendarIcon from "../../../assets/image/calendar.svg";

import "./styles.scss";

const DateTimePickerComponent = ({
  dateTime,
  handleDateTime,
  format = "dd.MM.yyyy HH:mm",
  disablePast = true
}) => {
  return (
    <div className="datetime-picker-wrapper">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={defaultMaterialTheme}>
          <DateTimePicker
            variant="inline"
            ampm={false}
            value={dateTime}
            onChange={handleDateTime}
            disablePast={disablePast}
            format={format}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <img src={CalendarIcon} alt="calendar" />
                  </IconButton>
                </InputAdornment>
              )
            }}
            classes={{
              root: "datetime-picker"
            }}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
};

const DatePickerComponent = ({
  dateTime,
  handleDateTime,
  format = "dd.MM.yyyy HH:mm",
  views = ["year", "month", "date"],
  disablePast = false
}) => {
  return (
    <div className="datetime-picker-wrapper">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={defaultMaterialTheme}>
          <DatePicker
            variant="inline"
            ampm={false}
            value={dateTime}
            onChange={handleDateTime}
            disablePast={disablePast}
            format={format}
            views={views}
            autoOk
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <img src={CalendarIcon} alt="calendar" />
                  </IconButton>
                </InputAdornment>
              )
            }}
            classes={{
              root: "datetime-picker"
            }}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DateTimePickerComponent;

export { DatePickerComponent };
