import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import BackLink from "../../_shared/BackLink";
import urls from "../../../core/urls";
import TournamentFields from "../TournamentFields";
import Loader from "../../_shared/Loader";

import { getTournament, resetTournamentInfo } from "../actions";

import "./styles.scss";

const EditTournament = ({ t, match, tournament, getTournament, resetTournamentInfo }) => {
  useEffect(() => {
    getTournament(match.params.id);
    return () => resetTournamentInfo();
  }, []);

  return (
    <div className="default-content-wrapper edit-tournament">
      <BackLink to={urls.tournamentsUrl} />
      <h1>{t("t:tournaments.edit-tournament")}</h1>
      {tournament.loading ? (
        <Loader />
      ) : (
        <TournamentFields
          tournamentID={match.params.id}
          {...tournament}
          custom_first_place_prize={tournament.custom_first_place_prize || ""}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ tournaments }) => {
  return {
    tournament: tournaments.tournament
  };
};

const mapDispatchToProps = {
  getTournament,
  resetTournamentInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditTournament));
