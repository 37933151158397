const urls = {
  loginUrl: "/auth/login",

  usersUrl: "/admin/users",
  addNewUser: "/admin/users/add",

  userProfileUrl: "/admin/user/:id",

  tournamentsUrl: "/admin/tournaments",
  addNewTournament: "/admin/tournaments/add-new",
  editTournamentUrl: "/admin/tournaments/edit/:id",
  tournamentUrl: "/admin/tournaments/tournament/:id",
  tournamentDisputesUrl: "/admin/tournament-disputes",
  tournamentDisputeInnerUrl: "/admin/tournament-disputes/:id",

  disputeCenterUrl: "/admin/dispute-center",

  skillChallengesUrl: "/admin/skill-challenges",

  challengesSystemUrl: "/admin/challenges-system",
  addDailyChallengeUrl: "/admin/challenges-system/daily/add-new",
  addOneVsOneChallengeUrl: "/admin/challenges-system/1vs1/add-new",
  addSkillChallengeUrl: "/admin/challenges-system/skill/add-new",
  editDailyChallengeUrl: "/admin/challenges-system/daily/edit/:id",
  editOneVsOneChallengeUrl: "/admin/challenges-system/1vs1/edit/:id",
  editSkillChallengeUrl: "/admin/challenges-system/skill/edit/:id",

  registrationsUrl: "/admin/registrations"
};

export default urls;
