import React from "react";
import { Controller } from "react-hook-form";
import _ from "lodash";
import { FormControlLabel } from "@material-ui/core";

import { roles } from "../../../../core/helpers/constants";
import CheckField from "../../../_shared/CheckField";

const Role = ({ control }) => (
  <div className="user-profile__general--block">
    <div className="user-profile__general--subtitle">Roles</div>
    <div className="user-profile__general--roles">
      {roles.map(({ name, value }) => (
        <FormControlLabel
          key={value}
          classes={{
            label: "user-profile__general--check-label"
          }}
          control={
            <Controller
              name={`is_${value}`}
              control={control}
              render={props => <CheckField {..._.omit(props.field, "ref")} />}
            />
          }
          label={name}
        />
      ))}
    </div>
  </div>
);

export default Role;
