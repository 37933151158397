const TOURNAMENTS = {
  GET_TOURNAMENTS: "GET_TOURNAMENTS",
  GET_TOURNAMENTS_SUCCESS: "GET_TOURNAMENTS_SUCCESS",
  GET_TOURNAMENTS_FAIL: "GET_TOURNAMENTS_FAIL",
  RESET_TOURNAMENTS: "RESET_TOURNAMENTS",
  SET_USERS_LIST: "SET_USERS_LIST",
  SET_DISPUTE_DIALOG_OPEN: "SET_DISPUTE_DIALOG_OPEN",

  GET_TOURNAMENT: "GET_TOURNAMENT",
  GET_TOURNAMENT_SUCCESS: "GET_TOURNAMENT_SUCCESS",
  GET_TOURNAMENT_FAIL: "GET_TOURNAMENT_FAIL",
  RESET_TOURNAMENT_INFO: "RESET_TOURNAMENT_INFO",

  POST_DEACTIVATE_TOURNAMENT: "POST_DEACTIVATE_TOURNAMENT",
  POST_DEACTIVATE_TOURNAMENT_SUCCESS: "POST_DEACTIVATE_TOURNAMENT_SUCCESS",
  POST_DEACTIVATE_TOURNAMENT_FAIL: "POST_DEACTIVATE_TOURNAMENT_FAIL",

  POST_PUBLISH_TOURNAMENT: "POST_PUBLISH_TOURNAMENT",
  POST_PUBLISH_TOURNAMENT_SUCCESS: "POST_PUBLISH_TOURNAMENT_SUCCESS",
  POST_PUBLISH_TOURNAMENT_FAIL: "POST_PUBLISH_TOURNAMENT_FAIL",

  POST_RESTORE_TOURNAMENT: "POST_RESTORE_TOURNAMENT",
  POST_RESTORE_TOURNAMENT_SUCCESS: "POST_RESTORE_TOURNAMENT_SUCCESS",
  POST_RESTORE_TOURNAMENT_FAIL: "POST_RESTORE_TOURNAMENT_FAIL",

  POST_UNPUBLISH_TOURNAMENT: "POST_UNPUBLISH_TOURNAMENT",
  POST_UNPUBLISH_TOURNAMENT_SUCCESS: "POST_UNPUBLISH_TOURNAMENT_SUCCESS",
  POST_UNPUBLISH_TOURNAMENT_FAIL: "POST_UNPUBLISH_TOURNAMENT_FAIL",

  POST_CREATE_TOURNAMENT: "POST_CREATE_TOURNAMENT",
  POST_CREATE_TOURNAMENT_SUCCESS: "POST_CREATE_TOURNAMENT_SUCCESS",
  POST_CREATE_TOURNAMENT_FAIL: "POST_CREATE_TOURNAMENT_FAIL",

  PATCH_TOURNAMENT: "PATCH_TOURNAMENT",
  PATCH_TOURNAMENT_SUCCESS: "PATCH_TOURNAMENT_SUCCESS",
  PATCH_TOURNAMENT_FAIL: "PATCH_TOURNAMENT_FAIL",

  POST_JOINED_CHECK_IN: "POST_JOINED_CHECK_IN",
  POST_JOINED_CHECK_IN_SUCCESS: "POST_JOINED_CHECK_IN_SUCCESS",
  POST_JOINED_CHECK_IN_FAIL: "POST_JOINED_CHECK_IN_FAIL",

  POST_JOINED_CHECK_IN_ALL: "POST_JOINED_CHECK_IN_ALL",
  POST_JOINED_CHECK_IN_ALL_SUCCESS: "POST_JOINED_CHECK_IN_ALL_SUCCESS",
  POST_JOINED_CHECK_IN_ALL_FAIL: "POST_JOINED_CHECK_IN_ALL_FAIL",

  POST_JOINED_REMOVE: "POST_JOINED_REMOVE",
  POST_JOINED_REMOVE_SUCCESS: "POST_JOINED_REMOVE_SUCCESS",
  POST_JOINED_REMOVE_FAIL: "POST_JOINED_REMOVE_FAIL",

  POST_JOINED_REMOVE_ALL: "POST_JOINED_REMOVE_ALL",
  POST_JOINED_REMOVE_ALL_SUCCESS: "POST_JOINED_REMOVE_ALL_SUCCESS",
  POST_JOINED_REMOVE_ALL_FAIL: "POST_JOINED_REMOVE_ALL_FAIL",

  POST_JOINED_ADD_RANDOM: "POST_JOINED_ADD_RANDOM",
  POST_JOINED_ADD_RANDOM_SUCCESS: "POST_JOINED_ADD_RANDOM_SUCCESS",
  POST_JOINED_ADD_RANDOM_FAIL: "POST_JOINED_ADD_RANDOM_FAIL",

  POST_WAITLIST_REMOVE: "POST_WAITLIST_REMOVE",
  POST_WAITLIST_REMOVE_SUCCESS: "POST_WAITLIST_REMOVE_SUCCESS",
  POST_WAITLIST_REMOVE_FAIL: "POST_WAITLIST_REMOVE_FAIL",

  POST_WAITLIST_REMOVE_ALL: "POST_WAITLIST_REMOVE_ALL",
  POST_WAITLIST_REMOVE_ALL_SUCCESS: "POST_WAITLIST_REMOVE_ALL_SUCCESS",
  POST_WAITLIST_REMOVE_ALL_FAIL: "POST_WAITLIST_REMOVE_ALL_FAIL",

  POST_WAITLIST_ADD_RANDOM: "POST_WAITLIST_ADD_RANDOM",
  POST_WAITLIST_ADD_RANDOM_SUCCESS: "POST_WAITLIST_ADD_RANDOM_SUCCESS",
  POST_WAITLIST_ADD_RANDOM_FAIL: "POST_WAITLIST_ADD_RANDOM_FAIL",

  POST_START_TOURNAMENT: "POST_START_TOURNAMENT",
  POST_START_TOURNAMENT_SUCCESS: "POST_START_TOURNAMENT_SUCCESS",
  POST_START_TOURNAMENT_FAIL: "POST_START_TOURNAMENT_FAIL",

  POST_START_CHECK_IN: "POST_START_CHECK_IN",
  POST_START_CHECK_IN_SUCCESS: "POST_START_CHECK_IN_SUCCESS",
  POST_START_CHECK_IN_FAIL: "POST_START_CHECK_IN_FAIL",

  POST_START_WAITLIST: "POST_START_WAITLIST",
  POST_START_WAITLIST_SUCCESS: "POST_START_WAITLIST_SUCCESS",
  POST_START_WAITLIST_FAIL: "POST_START_WAITLIST_FAIL",

  GET_TOURNAMENT_SELECT_OPTIONS: "GET_TOURNAMENT_SELECT_OPTIONS",
  GET_TOURNAMENT_SELECT_OPTIONS_SUCCESS: "GET_TOURNAMENT_SELECT_OPTIONS_SUCCESS",
  GET_TOURNAMENT_SELECT_OPTIONS_FAIL: "GET_TOURNAMENT_SELECT_OPTIONS_FAIL",

  POST_TOURNAMENT_RANDOM_PLAY: "POST_TOURNAMENT_RANDOM_PLAY",
  POST_TOURNAMENT_RANDOM_PLAY_SUCCESS: "POST_TOURNAMENT_RANDOM_PLAY_SUCCESS",
  POST_TOURNAMENT_RANDOM_PLAY_FAIL: "POST_TOURNAMENT_RANDOM_PLAY_FAIL",

  POST_MATCH_RANDOM_PLAY: "POST_MATCH_RANDOM_PLAY",
  POST_MATCH_RANDOM_PLAY_SUCCESS: "POST_MATCH_RANDOM_PLAY_SUCCESS",
  POST_MATCH_RANDOM_PLAY_FAIL: "POST_MATCH_RANDOM_PLAY_FAIL",

  POST_MATCH_WINNER: "POST_MATCH_WINNER",
  POST_MATCH_WINNER_SUCCESS: "POST_MATCH_WINNER_SUCCESS",
  POST_MATCH_WINNER_FAIL: "POST_MATCH_WINNER_FAIL",

  GET_TOURNAMENT_DISPUTES_LIST: "GET_TOURNAMENT_DISPUTES_LIST",
  GET_TOURNAMENT_DISPUTES_LIST_SUCCESS: "GET_TOURNAMENT_DISPUTES_LIST_SUCCESS",
  GET_TOURNAMENT_DISPUTES_LIST_FAIL: "GET_TOURNAMENT_DISPUTES_LIST_FAIL",

  GET_CURRENT_TOURNAMENT_DISPUTES: "GET_CURRENT_TOURNAMENT_DISPUTES",
  GET_CURRENT_TOURNAMENT_DISPUTES_SUCCESS: "GET_CURRENT_TOURNAMENT_DISPUTES_SUCCESS",
  GET_CURRENT_TOURNAMENT_DISPUTES_FAIL: "GET_CURRENT_TOURNAMENT_DISPUTES_FAIL",

  GET_TOURNAMENT_DISPUTE_INNER: "GET_TOURNAMENT_DISPUTE_INNER",
  GET_TOURNAMENT_DISPUTE_INNER_SUCCESS: "GET_TOURNAMENT_DISPUTE_INNER_SUCCESS",
  GET_TOURNAMENT_DISPUTE_INNER_FAIL: "GET_TOURNAMENT_DISPUTE_INNER_FAIL",

  RESET_TOURNAMENT_DISPUTE_INNER: "RESET_TOURNAMENT_DISPUTE_INNER",

  POST_SET_TOURNAMENT_DISPUTE_WINNER: "POST_SET_TOURNAMENT_DISPUTE_WINNER",
  POST_SET_TOURNAMENT_DISPUTE_WINNER_SUCCESS: "POST_SET_TOURNAMENT_DISPUTE_WINNER_SUCCESS",
  POST_SET_TOURNAMENT_DISPUTE_WINNER_FAIL: "POST_SET_TOURNAMENT_DISPUTE_WINNER_FAIL",

  GET_DISPUTE_MESSAGES: "GET_DISPUTE_MESSAGES",
  GET_DISPUTE_MESSAGES_SUCCESS: "GET_DISPUTE_MESSAGES_SUCCESS",

  UPDATE_DISPUTE_MESSAGE_LIST: "UPDATE_DISPUTE_MESSAGE_LIST",
  POST_CHAT_IMAGE: "POST_CHAT_IMAGE"
};

export default TOURNAMENTS;
