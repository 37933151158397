import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import _ from "lodash";

import DefaultButton from "../../_shared/Buttons/DefaultButton";
import Dialog from "../../_shared/Dialog";
import Tabs from "../../_shared/Tabs";
import InputField from "../../_shared/InputField";
import { challengesRanks, rewardOptions } from "../constants";
import { nullableIntegerValidation } from "../../../core/helpers/validations";
import { getReward, patchReward } from "../actions";

import RewardIcon from "../../../assets/image/star.svg";
import RadioSelectedIcon from "../../../assets/image/radio-selected.svg";
import RadioUnselectedIcon from "../../../assets/image/radio-unselected.svg";

import "./styles.scss";

const Reward = ({ challengeType, defaultRank }) => {
  const dispatch = useDispatch();
  const { rewardInfo } = useSelector(({ challengesSystem }) => challengesSystem);
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);
  const [isOpen, setIsOpen] = useState(false);
  const [rank, setRank] = useState(defaultRank);
  const [rewardType, setRewardType] = useState("euro");

  const handleIsOpen = () => setIsOpen(val => !val);

  useEffect(() => {
    if (isOpen) dispatch(getReward(challengeType));
  }, [isOpen]);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        reward_money: nullableIntegerValidation(),
        reward_bonus_cash: nullableIntegerValidation()
      })
    ),
    defaultValues: {
      reward_money: "",
      reward_bonus_cash: ""
    },
    shouldFocusError: true
  });

  const watchFields = watch();

  const disabled = useMemo(
    () =>
      (rewardType === "euro_+_bonus_cash" &&
        !watchFields.reward_bonus_cash &&
        !watchFields.reward_money) ||
      (rewardType === "euro" && !watchFields.reward_money) ||
      (rewardType === "bonus_cash" && !watchFields.reward_bonus_cash),
    [rewardType, watchFields]
  );

  const currentRank = useMemo(() => rewardInfo.find(el => el.name === rank), [rewardInfo, rank]);

  useEffect(() => {
    if (currentRank) {
      setRewardType(
        currentRank.reward_money && currentRank.reward_bonus_cash
          ? "euro_+_bonus_cash"
          : currentRank.reward_bonus_cash
          ? "bonus_cash"
          : "euro"
      );
      setValue("reward_money", currentRank.reward_money);
      setValue("reward_bonus_cash", currentRank.reward_bonus_cash);
    }
  }, [currentRank]);

  const prepareDataForSubmit = data => ({
    reward_money: rewardType === "bonus_cash" ? 0 : data.reward_money,
    reward_bonus_cash: rewardType === "euro" ? 0 : data.reward_bonus_cash,
    name: rank
  });

  const onSubmit = async data => {
    const res = await dispatch(patchReward(challengeType, prepareDataForSubmit(data)));
    if (res?.payload) handleIsOpen();
  };

  return (
    <>
      <DefaultButton variant="outlined" onClick={handleIsOpen} classes="blue-btn">
        <div className="challenge-reward__btn-content">
          <img src={RewardIcon} alt="star" />
          Reward
        </div>
      </DefaultButton>
      <Dialog open={isOpen} onClose={handleIsOpen}>
        <div className="confirmation-dialog challenge-reward__dialog">
          <div className="challenge-reward__dialog--title">Reward settings</div>
          <Tabs currentTab={rank} tabs={challengesRanks} onTabChange={setRank} type="capsule" />
          <div className="challenge-reward__dialog--description">
            Specify a reward for completing challenges of this rank
          </div>
          <div className="radio-buttons">
            {rewardOptions.map(({ label, value }) => (
              <div className="radio-field" key={value} onClick={() => setRewardType(value)}>
                <img
                  src={rewardType === value ? RadioSelectedIcon : RadioUnselectedIcon}
                  alt={value}
                />
                <p>{label}</p>
              </div>
            ))}
          </div>
          <form className="challenge-reward__dialog--form-fields" onSubmit={handleSubmit(onSubmit)}>
            <div className="challenge-reward__dialog--several-fields">
              {rewardType !== "bonus_cash" && (
                <Controller
                  name="reward_money"
                  control={control}
                  render={props => (
                    <InputField
                      id="name-input"
                      label="Euro amount"
                      type="text"
                      {..._.omit(props.field, "ref")}
                      inputRef={props.ref}
                      error={errors.reward_money?.message}
                    />
                  )}
                />
              )}
              {rewardType !== "euro" && (
                <Controller
                  name="reward_bonus_cash"
                  control={control}
                  render={props => (
                    <InputField
                      id="name-input"
                      label="Bonus Cash amount"
                      type="text"
                      {..._.omit(props.field, "ref")}
                      inputRef={props.ref}
                      error={errors.reward_bonus_cash?.message}
                    />
                  )}
                />
              )}
            </div>
            <hr className="separator" />
            <div className="buttons-wrapper">
              <DefaultButton variant="contained" onClick={handleIsOpen} classes="grey-btn">
                Cancel
              </DefaultButton>
              <DefaultButton
                variant="contained"
                classes="green-btn"
                loading={buttonLoading}
                disabled={disabled}
                formAction
              >
                Save
              </DefaultButton>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default Reward;
