const REGISTRATIONS = {
  GET_REGISTRATIONS: "GET_REGISTRATIONS",
  GET_REGISTRATIONS_SUCCESS: "GET_REGISTRATIONS_SUCCESS",
  GET_REGISTRATIONS_FAIL: "GET_REGISTRATIONS_FAIL",

  SET_REGISTRATIONS_META: "SET_USERS_META",
  RESET_REGISTRATIONS: "RESET_REGISTRATIONS"
};

export default REGISTRATIONS;
