import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 99999999999,
    color: "#2FC272"
  }
}));

const propTypes = {
  open: PropTypes.bool.isRequired
};

const BackDrop = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop open={open} className={classes.backdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

BackDrop.propTypes = propTypes;

export default BackDrop;
