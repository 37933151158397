import React from "react";
import { useTranslation } from "react-i18next";

import DefaultButton from "../_shared/Buttons/DefaultButton";
import urls from "../../core/urls";

import NotFoundIcon from "../../assets/image/404.svg";
import AppLogo from "../../assets/image/g_logo.svg";

import "./styles.scss";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="page_not_found">
      <img src={AppLogo} className="not_found_logo" alt="app" />
      <img src={NotFoundIcon} alt="not found" />
      <h2>404</h2>
      <h4>{t("t:shared.page-not-found")}</h4>
      <DefaultButton variant="outlined" type="link" to={urls.usersUrl}>
        {t("t:shared.back-to-dashboard")}
      </DefaultButton>
    </div>
  );
};

export default NotFound;
