import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { setMatchesMeta } from "../../actions";
import IconButtonComponent from "../../../_shared/Buttons/IconButton";
import DefaultButton from "../../../_shared/Buttons/DefaultButton";
import { matchesInitialFilters } from "../../constants";
import { renderFilterBlock } from "../../helpers";
import { capitalizeFirstLetter } from "../../../../core/helpers/functions";

import CloseIcon from "../../../../assets/image/close.svg";

const Filters = ({ filterOptions, handleFiltersPanel, filtersIsOpen }) => {
  const [filters, setFilters] = useState(matchesInitialFilters);
  const dispatch = useDispatch();
  const { matchesMeta } = useSelector(({ skillChallenges }) => skillChallenges);

  useEffect(() => {
    if (JSON.stringify(filters) !== JSON.stringify(matchesInitialFilters)) {
      if (Object.entries(matchesInitialFilters).every(el => matchesMeta[el[0]] === el[1])) {
        setFilters(matchesInitialFilters);
      }
    }
  }, [matchesMeta]);

  const onFiltersChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const onApplySelectedClick = () => {
    dispatch(setMatchesMeta(filters));
    handleFiltersPanel();
  };

  const onReset = () => {
    setFilters(matchesInitialFilters);
    dispatch(setMatchesMeta(matchesInitialFilters));
    handleFiltersPanel();
  };

  return (
    <div className={clsx("filters with-transition no-user-select", filtersIsOpen && "is-open")}>
      <div className="filters--close-wrapper">
        <IconButtonComponent
          onClick={handleFiltersPanel}
          alt="close"
          src={CloseIcon}
          classes="filters--close-icon"
        />
      </div>
      <h1 className="mb-40">Filters</h1>
      <div className="filters--container with-scrollbar">
        {renderFilterBlock("Game", "game", filterOptions.games, filters.game, onFiltersChange)}
        {renderFilterBlock(
          "Status",
          "status",
          filterOptions.statuses.map(({ label, value }) => ({
            label: capitalizeFirstLetter(label),
            value
          })),
          filters.status,
          onFiltersChange,
          "grid-2-col"
        )}
        {renderFilterBlock(
          "Bet type",
          "bet_type",
          filterOptions.bet_types,
          filters.bet_type,
          onFiltersChange,
          "with-scrollbar"
        )}
        {renderFilterBlock(
          "Loss details",
          "loss_details",
          filterOptions.loss_details,
          filters.loss_details,
          onFiltersChange
        )}
        <div className="filters--btn-wrapper">
          <DefaultButton variant="contained" onClick={onApplySelectedClick} classes="green-btn">
            Apply selected
          </DefaultButton>
          <div className="filters--reset text-hover" onClick={onReset}>
            Reset all filters
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Filters);
