import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";

import DefaultButton from "../../_shared/Buttons/DefaultButton";
import InputField from "../../_shared/InputField";
import urls from "../../../core/urls";
import addUserSchema from "./addUserSchema";
import { postNewUser } from "../actions";
import notifyError from "../../../core/helpers/notifyError";
import { handleValidationErrors, validateImage } from "../../../core/helpers/functions";

import DummyAvatar from "../../../assets/image/dummy-avatar.svg";

import "./styles.scss";

const AddUserFields = () => {
  const [previewAvatar, setPreviewAvatar] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);
  const inputRef = useRef();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(addUserSchema),
    defaultValues: {
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      password_confirm: ""
    },
    shouldFocusError: true
  });

  const onUploadClick = () => inputRef?.current.click();

  const handleFile = e => {
    e.persist();
    const file = e.target.files[0];
    if (file) {
      if (validateImage(file)) {
        const newUrl = URL.createObjectURL(file);
        setPreviewAvatar(newUrl.toString());
        setAvatar(file);
      } else {
        notifyError("File type is not supported");
      }
    }
  };

  const onDeleteClick = () => {
    setPreviewAvatar(null);
    setAvatar(null);
    inputRef.current.value = "";
  };

  const prepareDataForSubmit = data => {
    const formData = new FormData();
    Object.entries(data).map(field => formData.append(field[0], field[1]));
    if (avatar) formData.append("avatar", avatar);
    return formData;
  };

  const onSubmit = async data => {
    const res = await dispatch(postNewUser(prepareDataForSubmit(data)));
    if (res?.payload) {
      history.push(`${urls.usersUrl}?tab=upcoming&page=1`);
    } else {
      handleValidationErrors(res, setError);
    }
  };

  return (
    <form className="tournaments-fields" onSubmit={handleSubmit(onSubmit)}>
      <div className="fields-wrapper">
        <div className="fields-block margin-bottom-48">
          <div className="block-name">General info</div>
          <input
            ref={inputRef}
            className="input-file"
            type="file"
            multiple={false}
            onChange={handleFile}
            accept="image/*"
          />
          <div className="fields">
            <div className="several-fields">
              {previewAvatar ? (
                <div className="input-field flex-center">
                  <img src={previewAvatar} alt="preview" className="preview-image" />
                  <div>
                    <p>Profile photo</p>
                    <div className="photo-btns">
                      <button
                        type="button"
                        className="change-btn text-hover"
                        onClick={onUploadClick}
                      >
                        Change
                      </button>
                      <button
                        type="button"
                        className="delete-btn text-hover"
                        onClick={onDeleteClick}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="input-field flex-center">
                  <img src={DummyAvatar} alt="preview" className="preview-image" />
                  <div>
                    <p className="profile-photo">Profile photo</p>
                    <div className="photo-btns">
                      <button
                        type="button"
                        className="change-btn text-hover"
                        onClick={onUploadClick}
                      >
                        Choose photo
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <Controller
                name="username"
                control={control}
                render={props => (
                  <InputField
                    id="username-input"
                    label="Username"
                    type="text"
                    {..._.omit(props.field, "ref")}
                    error={errors.username?.message}
                  />
                )}
              />
            </div>
            <div className="several-fields margin-top-32">
              <Controller
                name="first_name"
                control={control}
                render={props => (
                  <InputField
                    id="first-name-input"
                    label="First name"
                    type="text"
                    {..._.omit(props.field, "ref")}
                    error={errors.first_name?.message}
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                render={props => (
                  <InputField
                    id="last-name-input"
                    label="Last name"
                    type="text"
                    {..._.omit(props.field, "ref")}
                    error={errors.last_name?.message}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="fields-block margin-bottom-48">
          <div className="block-name">Credentials</div>
          <div className="fields">
            <div className="several-fields">
              <Controller
                name="email"
                control={control}
                render={props => (
                  <InputField
                    id="email-name-input"
                    label="Email"
                    type="text"
                    {..._.omit(props.field, "ref")}
                    error={errors.email?.message}
                  />
                )}
              />
              <Controller
                name="phone"
                control={control}
                render={props => (
                  <InputField
                    id="phone-number-input"
                    label="Phone number"
                    type="text"
                    {..._.omit(props.field, "ref")}
                    error={errors.phone?.message}
                  />
                )}
              />
            </div>
            <div className="several-fields margin-top-32">
              <Controller
                name="password"
                control={control}
                render={props => (
                  <InputField
                    id="password-input"
                    label="Password"
                    type="password"
                    {..._.omit(props.field, "ref")}
                    error={errors.password?.message}
                  />
                )}
              />
              <Controller
                name="password_confirm"
                control={control}
                render={props => (
                  <InputField
                    id="password-confirm-input"
                    label="Confirm password"
                    type="password"
                    {..._.omit(props.field, "ref")}
                    error={errors.password_confirm?.message}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="buttons-wrapper">
        <DefaultButton variant="contained" classes="green-btn" loading={buttonLoading} formAction>
          Create user
        </DefaultButton>
      </div>
    </form>
  );
};

export default AddUserFields;
