import React from "react";
import clsx from "clsx";

import RadioSelectedIcon from "../../assets/image/radio-selected.svg";
import RadioUnselectedIcon from "../../assets/image/radio-unselected.svg";

const translateConnectionStatus = status => {
  switch (status) {
    case "initial":
      return "Not connected";
    case "verifying":
      return "Verifying";
    case "verified":
      return "Verified";
    case "error":
      return "Error";
    default:
      return "Unknown status";
  }
};

const translateMatchesStatus = status => {
  switch (status) {
    case "won":
      return "Won";
    case "lost":
      return "Lost";
    case "error":
      return "Error";
    case "super_error":
      return "Super error";
    case "in_game":
      return "In game";
    case "not_started_in_time":
      return "Not started in time";
    case "waiting_for_start":
      return "Waiting for start";
    default:
      return "Unknown status";
  }
};

const renderFilterBlock = (
  filterLabel,
  filerValue,
  filterOptions,
  chosenFilter,
  onFiltersChange,
  listClasses
) => (
  <>
    <div className="filters--title">{filterLabel}</div>
    <div className={clsx("filters--options", listClasses)}>
      {filterOptions.map(({ label, value }) => (
        <div
          key={`game-${label}`}
          className="filters--option"
          onClick={() => onFiltersChange(filerValue, value)}
        >
          <img src={chosenFilter === value ? RadioSelectedIcon : RadioUnselectedIcon} alt={label} />
          {label}
        </div>
      ))}
    </div>
  </>
);

export { translateConnectionStatus, translateMatchesStatus, renderFilterBlock };
