import USER_PROFILE from "./actionTypes";

export function getProfileGeneral(userID) {
  return {
    type: USER_PROFILE.GET_PROFILE_GENERAL,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/user-profile/${userID}/general/`,
        method: "get"
      }
    }
  };
}

export function resetUserProfile() {
  return {
    type: USER_PROFILE.RESET_USER
  };
}

export function patchProfileGeneral(userID, data) {
  return {
    type: USER_PROFILE.PATCH_PROFILE_GENERAL,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/user-profile/${userID}/general/`,
        method: "patch",
        data
      }
    }
  };
}

export function postAddBalance(userID, data) {
  return {
    type: USER_PROFILE.POST_ADD_BALANCE,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/user-profile/${userID}/add-balance/`,
        method: "post",
        data
      }
    }
  };
}

export function postAddBonusCash(userID, data) {
  return {
    type: USER_PROFILE.POST_ADD_BONUS_CASH,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/user-profile/${userID}/add-bonus-cash/`,
        method: "post",
        data
      }
    }
  };
}

export function getProfilePlatforms(userID) {
  return {
    type: USER_PROFILE.GET_PROFILE_PLATFORMS,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/user-profile/${userID}/platforms/`,
        method: "get"
      }
    }
  };
}

export function patchProfilePlatform(userID, connectionID, data) {
  return {
    type: USER_PROFILE.PATCH_PROFILE_PLATFORM,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/user-profile/${userID}/platforms/${connectionID}/update/`,
        method: "patch",
        data
      }
    }
  };
}

export function postDisconnectProfilePlatform(userID, connectionID) {
  return {
    type: USER_PROFILE.POST_DISCONNECT_PROFILE_PLATFORM,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/user-profile/${userID}/platforms/${connectionID}/disconnect/`,
        method: "post"
      }
    }
  };
}

export function getProfileAffiliate(userID) {
  return {
    type: USER_PROFILE.GET_PROFILE_AFFILIATE,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/user-profile/${userID}/affilate/`,
        method: "get"
      }
    }
  };
}

export function patchProfileAffiliate(userID, data) {
  return {
    type: USER_PROFILE.PATCH_PROFILE_AFFILIATE,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/user-profile/${userID}/affilate/`,
        method: "patch",
        data
      }
    }
  };
}

export function getProfileKYC(userID) {
  return {
    type: USER_PROFILE.GET_PROFILE_KYC,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/user-profile/${userID}/KYC/`,
        method: "get"
      }
    }
  };
}

export function patchProfileKYC(userID, data) {
  return {
    type: USER_PROFILE.PATCH_PROFILE_KYC,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/user-profile/${userID}/KYC/`,
        method: "patch",
        data
      }
    }
  };
}

export function getProfileSkillChallenges(userID) {
  return {
    type: USER_PROFILE.GET_PROFILE_SKILL_CHALLENGES,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/user-profile/${userID}/skill-challenges/`,
        method: "get"
      }
    }
  };
}

export function patchProfileSkillChallenges(userID, data) {
  return {
    type: USER_PROFILE.PATCH_PROFILE_SKILL_CHALLENGES,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/user-profile/${userID}/skill-challenges/`,
        method: "patch",
        data
      }
    }
  };
}

export function getProfileDisputes(userID, page) {
  return {
    type: USER_PROFILE.GET_PROFILE_DISPUTES,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/user-profile/${userID}/disputes/?page=${page}`,
        method: "get"
      }
    }
  };
}
