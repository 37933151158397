const USERS = {
  GET_USERS: "GET_USERS",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAIL: "GET_USERS_FAIL",

  SET_USERS_META: "SET_USERS_META",
  SELECT_USERS: "SELECT_USERS",
  DESELECT_USERS: "DESELECT_USERS",

  POST_ACTIVATE_USER: "POST_ACTIVATE_USER",
  POST_ACTIVATE_USER_SUCCESS: "POST_ACTIVATE_USER_SUCCESS",
  POST_ACTIVATE_USER_FAIL: "POST_ACTIVATE_USER_FAIL",

  POST_DEACTIVATE_USER: "POST_DEACTIVATE_USER",
  POST_DEACTIVATE_USER_SUCCESS: "POST_DEACTIVATE_USER_SUCCESS",
  POST_DEACTIVATE_USER_FAIL: "POST_DEACTIVATE_USER_FAIL",

  POST_BLOCK_USER: "POST_BLOCK_USER",
  POST_BLOCK_USER_SUCCESS: "POST_BLOCK_USER_SUCCESS",
  POST_BLOCK_USER_FAIL: "POST_BLOCK_USER_FAIL",

  POST_UNBLOCK_USER: "POST_UNBLOCK_USER",
  POST_UNBLOCK_USER_SUCCESS: "POST_UNBLOCK_USER_SUCCESS",
  POST_UNBLOCK_USER_FAIL: "POST_UNBLOCK_USER_FAIL",

  GET_USER_IN_LIST_INFO: "GET_USER_IN_LIST_INFO",
  GET_USER_IN_LIST_INFO_SUCCESS: "GET_USER_IN_LIST_INFO_SUCCESS",
  GET_USER_IN_LIST_INFO_FAIL: "GET_USER_IN_LIST_INFO_FAIL",

  POST_NEW_USER: "POST_NEW_USER",
  POST_NEW_USER_SUCCESS: "POST_NEW_USER_SUCCESS",
  POST_NEW_USER_FAIL: "POST_NEW_USER_FAIL",

  RESET_USERS: "RESET_USERS"
};

export default USERS;
