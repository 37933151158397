import GoogleIcon from "../../assets/image/google.svg";
import FacebookIcon from "../../assets/image/facebook.svg";
import TwitchIcon from "../../assets/image/twitch.svg";
import AppleIcon from "../../assets/image/apple.svg";

const userProfileTabs = [
  { label: "General", value: "general" },
  { label: "Platforms", value: "platforms" },
  { label: "Affiliate", value: "affiliate" },
  { label: "KYC", value: "kyc" },
  { label: "Skill challenges", value: "skill_challenges" },
  { label: "Disputes", value: "disputes" },
  { label: "Transactions", value: "transactions" }
];

const socials = [
  { label: "Google", value: "google", image: GoogleIcon },
  { label: "Facebook", value: "facebook", image: FacebookIcon },
  { label: "Twitch", value: "twitch", image: TwitchIcon },
  { label: "Apple", value: "apple", image: AppleIcon }
];

const userProfileSettings = [
  { label: "Receive push notifications", value: "receive_push_notifications" },
  { label: "Show in leaderboard", value: "show_in_leaderboard" },
  { label: "Receive special offers", value: "receive_special_offers" },
  { label: "Add opponents to friends", value: "add_opponents_to_friends" },
  { label: "Can have unlimited invitees", value: "can_have_unlimited_invitees" }
];

const userProfileSkillChallengesStatistics = [
  { label: "Game count", value: "game_count", color: "white", unit: "" },
  { label: "Total bets", value: "bets_amount_sum", color: "white", unit: "€" },
  { label: "Won games", value: "won_games", color: "green", unit: "" },
  { label: "Total payouts", value: "payout_amount_sum", color: "white", unit: "€" },
  { label: "Lost games", value: "lost_games", color: "gray", unit: "" },
  { label: "Total profit", value: "profit_payout_sum", color: "green", unit: "€" }
];

export { userProfileTabs, socials, userProfileSettings, userProfileSkillChallengesStatistics };
