import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { socials } from "../../constants";

import CheckIcon from "../../../../assets/image/green-checkmark.svg";

const SocialAccounts = () => {
  const general = useSelector(({ userProfile }) => userProfile.general);
  return (
    <div className="user-profile__general--block">
      <div className="user-profile__general--subtitle">Social accounts</div>
      <div className="user-profile__general--socials flex-center">
        {socials.map(({ label, value, image }) => (
          <div
            className={clsx(
              "user-profile__general--social flex-center",
              general[`is_${value}_connected`] && "is-connected"
            )}
            key={value}
          >
            <img src={image} alt={value} className="user-profile__general--social-icon" />
            <p>{label}</p>
            {general[`is_${value}_connected`] && (
              <img src={CheckIcon} alt={value} className="user-profile__general--social-check" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialAccounts;
