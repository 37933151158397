import React, { memo } from "react";
import dayjs from "dayjs";

import Skeleton from "../../../_shared/Skeleton";

const StatisticsItem = ({
  loading = false,
  date,
  bets_amount_sum,
  bets_minus_payout_sum,
  games_count,
  loss_count,
  payout_amount_sum,
  win_count
}) => (
  <div className="skill-challenges__statistics-user-row">
    <div className="skill-challenges__statistics-user-row--col">
      <Skeleton loading={loading} variant="text" width={80}>
        {dayjs(date).format("MMM D, YYYY")}
      </Skeleton>
    </div>
    <div className="skill-challenges__statistics-user-row--col text-overflow">
      <Skeleton loading={loading} variant="text" width={80}>
        {games_count}
      </Skeleton>
    </div>
    <div className="skill-challenges__statistics-user-row--col">
      <Skeleton loading={loading} variant="text" width={80}>
        {win_count}
      </Skeleton>
    </div>
    <div className="skill-challenges__statistics-user-row--col">
      <Skeleton loading={loading} variant="text" width={80}>
        {loss_count}
      </Skeleton>
    </div>
    <div className="skill-challenges__statistics-user-row--col">
      <Skeleton loading={loading} variant="text" width={80}>
        {`${bets_amount_sum} €`}
      </Skeleton>
    </div>
    <div className="skill-challenges__statistics-user-row--col">
      <Skeleton loading={loading} variant="text" width={80}>
        {`${payout_amount_sum} €`}
      </Skeleton>
    </div>
    <div className="skill-challenges__statistics-user-row--col">
      <Skeleton loading={loading} variant="text" width={80}>
        {`${bets_minus_payout_sum} €`}
      </Skeleton>
    </div>
  </div>
);

export default memo(StatisticsItem);
