import React from "react";
import { Controller } from "react-hook-form";
import _ from "lodash";
import { FormControlLabel } from "@material-ui/core";

import CheckField from "../../../_shared/CheckField";
import { userProfileSettings } from "../../constants";

const Settings = ({ control }) => {
  return (
    <div className="user-profile__general--block">
      <div className="user-profile__general--subtitle">Settings</div>
      <div className="user-profile__general--settings">
        {userProfileSettings.map(({ label, value }) => (
          <FormControlLabel
            key={value}
            classes={{
              label: "user-profile__general--check-label"
            }}
            control={
              <Controller
                name={value}
                control={control}
                render={props => <CheckField {..._.omit(props.field, "ref")} />}
              />
            }
            label={label}
          />
        ))}
      </div>
    </div>
  );
};

export default Settings;
