import React from "react";
import { useLocation } from "react-router-dom";

import BackLink from "../../_shared/BackLink";
import urls from "../../../core/urls";
import { DailyFields, RankFields } from "../ChallengesFields";
import { getChallengeTypeByPathname } from "../helpers";

import "./styles.scss";

const AddNewChallenge = () => {
  const { pathname } = useLocation();
  return (
    <div className="default-content-wrapper add-new-challenge">
      <BackLink to={urls.challengesSystemUrl} />
      <h1>Add new challenge</h1>
      {getChallengeTypeByPathname(pathname) === "daily" ? <DailyFields /> : <RankFields />}
    </div>
  );
};

export default AddNewChallenge;
