import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Dialog from "../../Dialog";
import IconButton from "../../Buttons/IconButton";
import DefaultButton from "../../Buttons/DefaultButton";

import RemoveIcon from "../../../../assets/image/remove.svg";
import AcceptIcon from "../../../../assets/image/accept.svg";

import "./styles.scss";
import { getPlatformNameAndIcon } from "../../../../core/helpers/functions";

const propTypes = {
  onClose: PropTypes.func.isRequired,
  usersList: PropTypes.shape({
    open: PropTypes.bool,
    title: PropTypes.string,
    numberOfUsers: PropTypes.number,
    users: PropTypes.array,
    clearAllAction: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    acceptAction: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    checkInAllAction: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    removeAction: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    addRandom: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    addRandomAction: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    participants: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

const defaultProps = {
  onClose: () => {},
  usersList: {
    open: false,
    title: "",
    numberOfUsers: 0,
    users: [],
    clearAllAction: false,
    acceptAction: false,
    checkInAllAction: false,
    removeAction: false,
    addRandom: false,
    addRandomAction: false,
    participants: []
  }
};

const UsersListDialog = ({
  onClose,
  usersList: {
    open,
    title,
    numberOfUsers,
    users,
    clearAllAction,
    acceptAction,
    checkInAllAction,
    removeAction,
    addRandomAction,
    participants
  }
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <div className="users-list-dialog">
        <div className="title-wrapper">
          <h2 className="title">{title}</h2>
          <div className="number">{numberOfUsers}</div>
          {addRandomAction && (
            <div className="add-random text-hover no-user-select" onClick={addRandomAction}>
              + {t("t:tournaments.add-random")}
            </div>
          )}
        </div>
        <div className="users-list with-scrollbar">
          {users.map(({ id, user }) => (
            <div className="user" key={`participant_${id}`}>
              <div className="info-block">
                <img className="avatar" src={user?.avatar} alt="avatar" />
                <div>
                  <p className="name">{user?.username}</p>
                  {getPlatformNameAndIcon(user?.platform_username, user?.platform_type)}
                </div>
              </div>
              <div className="actions-block">
                {removeAction && (
                  <IconButton src={RemoveIcon} onClick={() => removeAction(id)} alt="remove" />
                )}
                {acceptAction && !participants.some(el => el?.user?.id === user?.id) && (
                  <IconButton src={AcceptIcon} onClick={() => acceptAction(id)} alt="accept" />
                )}
              </div>
            </div>
          ))}
        </div>
        {(clearAllAction || checkInAllAction) && users.length > 0 && (
          <div className="users-actions">
            {clearAllAction && (
              <DefaultButton variant="outlined" onClick={clearAllAction} classes="grey-btn">
                {t("t:shared.clear-all")}
              </DefaultButton>
            )}
            {checkInAllAction && (
              <DefaultButton variant="contained" onClick={checkInAllAction} classes="green-btn">
                {t("t:tournaments.check-in-all")}
              </DefaultButton>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};

UsersListDialog.propTypes = propTypes;
UsersListDialog.defaultProps = defaultProps;

export default UsersListDialog;
