import React from "react";
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";

const override = css`
  display: block;
  margin: 0 auto;
`;

const Loader = props => {
  return (
    <div className="loader_block">
      <PulseLoader
        css={override}
        sizeUnit={"px"}
        color={"#ff0000"}
        loading={true}
        {...props}
        size={15}
      />
    </div>
  );
};

export default Loader;
