import React from "react";
import dayjs from "dayjs";

import { getGameInfo, getPlatformStatus, renderUserRow } from "../../core/helpers/functions";
import Skeleton from "../_shared/Skeleton";

import XboxIcon from "../../assets/image/xbox-in-circle.svg";
import SteamIcon from "../../assets/image/steam-in-circle.svg";
import PlaystationIcon from "../../assets/image/psn-in-circle.svg";
import { Link } from "react-router-dom";
import urls from "../../core/urls";

function renderPlatformWithStatus(platform, status) {
  switch (platform) {
    case "xbox":
      return (
        <div className="flex-center">
          <img src={XboxIcon} alt="xbox" className="user-profile__platforms--platform-icon" />
          <div>
            <p className="user-profile__platforms--platform-name">Xbox</p>
            {getPlatformStatus(status)}
          </div>
        </div>
      );
    case "steam":
      return (
        <div className="flex-center">
          <img src={SteamIcon} alt="steam" className="user-profile__platforms--platform-icon" />
          <div>
            <p className="user-profile__platforms--platform-name">Steam</p>
            {getPlatformStatus(status)}
          </div>
        </div>
      );
    default:
      return (
        <div className="flex-center">
          <img src={PlaystationIcon} alt="psn" className="user-profile__platforms--platform-icon" />
          <div>
            <p className="user-profile__platforms--platform-name">Playstation</p>
            {getPlatformStatus(status)}
          </div>
        </div>
      );
  }
}

const renderDisputeResult = result => {
  switch (result) {
    case "win":
      return <div className="user-profile__disputes--result win">Winner</div>;
    case "loss":
      return <div className="user-profile__disputes--result loss">Loser</div>;
    case "draw":
      return <div className="user-profile__disputes--result draw">Draw</div>;
    default:
      return null;
  }
};

const DisputeRow = ({
  id,
  loading = false,
  is_tournament,
  creation_date,
  game,
  platform,
  opponent,
  my_result
}) => (
  <div className="user-profile__disputes--row">
    <div className="user-profile__disputes--col">
      {is_tournament ? (
        <Link
          className="user-profile__disputes--id clickable"
          to={urls.tournamentDisputeInnerUrl.replace(":id", id)}
        >
          {`#${id}`}
        </Link>
      ) : (
        <p className="user-profile__disputes--id">{`#${id}`}</p>
      )}
      <Skeleton loading={loading} variant="text" width={140}>
        <div className="user-profile__disputes--date">
          {dayjs(creation_date).format("DD.MM.YYYY HH:mm")}
        </div>
      </Skeleton>
    </div>
    <div className="user-profile__disputes--col">
      {getGameInfo(game, { name: platform?.type }, loading)}
    </div>
    <div className="user-profile__disputes--col">
      {renderUserRow(opponent, () => "Opponent", loading)}
    </div>
    <div className="user-profile__disputes--col">{renderDisputeResult(my_result)}</div>
  </div>
);

export { renderPlatformWithStatus, DisputeRow };
