import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import { getTournamentDisputes } from "../Tournaments/actions";
import { tournamentDisputesTabs } from "../../core/helpers/constants";
import Tabs from "../_shared/Tabs";
import { useQueryParams } from "../../core/helpers/hooks";
import urls from "../../core/urls";
import NoItems from "../_shared/NoItems";
import Pagination from "../_shared/Pagination";
import Skeleton from "../_shared/Skeleton";
import { getGameInfo, getUserInfo } from "../../core/helpers/functions";

import "./styles.scss";

const TournamentDisputes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getParam } = useQueryParams();
  const [page, setPage] = useState(+getParam("page") || 1);
  const [tab, setTab] = useState(getParam("tab") || "pending");
  const { count, results } = useSelector(({ tournaments }) => tournaments.disputes);

  const handleUrl = () => history.push(`${urls.tournamentDisputesUrl}?tab=${tab}`);

  const fetchDisputes = () => {
    const queries = [`page=${page}`, "page_size=10"];
    dispatch(getTournamentDisputes(tab, queries));
  };

  useEffect(() => {
    fetchDisputes();
    handleUrl();
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      fetchDisputes();
      handleUrl();
    } else {
      setPage(1);
    }
  }, [tab]);

  return (
    <div className="tournament-disputes__wrapper">
      <h1>{t("t:shared.tournament-disputes")}</h1>
      <Tabs currentTab={tab} tabs={tournamentDisputesTabs(t)} onTabChange={setTab} />
      {count > 0 ? (
        results.map(({ id, creation_date, loading = false, game, platform, user_1, user_2 }) => (
          <div key={`dispute_${id}`} className="tournament-disputes__dispute">
            <div className="tournament-disputes__column">
              <Skeleton loading={loading} variant="text" width={80}>
                <Link
                  to={urls.tournamentDisputeInnerUrl.replace(":id", id)}
                  className="tournament-disputes__column--link"
                >
                  {`#${id}`}
                </Link>
              </Skeleton>
              <Skeleton loading={loading} variant="text" width={140}>
                <div className="tournament-disputes__column--date">
                  {dayjs(creation_date).format("DD.MM.YYYY HH:mm")}
                </div>
              </Skeleton>
            </div>
            <div className="tournament-disputes__column">
              {getGameInfo(game, platform, loading)}
            </div>
            <div className="tournament-disputes__column">
              {getUserInfo(
                user_1,
                platform,
                t,
                loading,
                tab === "solved" && user_1?.result === "win"
              )}
              <div className="vs-separator">vs</div>
              {getUserInfo(
                user_2,
                platform,
                t,
                loading,
                tab === "solved" && user_2?.result === "win"
              )}
            </div>
          </div>
        ))
      ) : (
        <NoItems />
      )}
      {count > 0 && <Pagination onChange={e => setPage(e)} total={count} page={page} />}
    </div>
  );
};

export default TournamentDisputes;
