import React from "react";
import Select, { components } from "react-select";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./styles.scss";

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <div
          className={
            props.selectProps.menuIsOpen ? "select_indicator indicator_active" : "select_indicator"
          }
        >
          <ExpandMoreIcon />
        </div>
      </components.DropdownIndicator>
    )
  );
};

const propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.shape({}),
  options: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool
};

const SelectComponent = ({
  id,
  value,
  options,
  change,
  placeholder,
  isClearable = false,
  isDisabled = false,
  isSearchable = false,
  ...props
}) => (
  <Select
    {...props}
    id={id}
    className="select_component"
    classNamePrefix="select"
    name="color"
    value={value}
    options={options}
    onChange={change}
    placeholder={placeholder}
    components={{ DropdownIndicator }}
    isClearable={isClearable}
    isDisabled={isDisabled}
    isSearchable={isSearchable}
  />
);

SelectComponent.propTypes = propTypes;

export default SelectComponent;
