export const usersTableCols = [
  { label: "ID", value: "id" },
  { label: "Username", value: "username" },
  { label: "Email", value: "email" },
  { label: "Phone", value: "phone" },
  { label: "Balance", value: "balance" }
];

export const filterOptions = {
  isActive: [
    { label: "All", value: "" },
    { label: "Active", value: true },
    { label: "Inactive", value: false }
  ],
  isBlocked: [
    { label: "All", value: "" },
    { label: "Blocked", value: true },
    { label: "Not blocked", value: false }
  ],
  isAdmin: [
    { label: "All", value: "" },
    { label: "Admin", value: true },
    { label: "Not admin", value: false }
  ],
  isBlogger: [
    { label: "All", value: "" },
    { label: "Blogger", value: true },
    { label: "Not blogger", value: false }
  ],
  kycVerified: [
    { label: "All", value: "" },
    { label: "Verified", value: true },
    { label: "Not verified", value: false }
  ],
  isKycSubmitted: [
    { label: "All", value: "" },
    { label: "Submitted", value: true },
    { label: "Not submitted", value: false }
  ]
};

export const initialFilters = {
  is_active: "",
  is_blocked: "",
  is_admin: "",
  is_blogger: "",
  kyc_verified: "",
  is_kyc_submitted: ""
};

export const generalCols = [
  { label: "Admin", value: "is_admin" },
  { label: "KYC verified", value: "kyc_verified" },
  { label: "KYC is submitted", value: "is_kyc_submitted" }
];

export const platformsCols = [
  { label: "Playstation", value: "playstation" },
  { label: "KYC verified", value: "kyc_verified" },
  { label: "KYC is submitted", value: "is_kyc_submitted" }
];

export const statisticsRows = [
  { label: "Game count:", value: "game_count", isMoney: false, color: "white" },
  { label: "Won games:", value: "won_count", isMoney: false, color: "green" },
  { label: "Lost games:", value: "lost_count", isMoney: false, color: "gray" },
  { label: "Total bets:", value: "total_bets", isMoney: true, color: "white" },
  { label: "Total payouts:", value: "total_payouts", isMoney: true, color: "white" },
  { label: "Total profit:", value: "total_profit", isMoney: true, color: "green" }
];
