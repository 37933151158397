import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import SearchIcon from "../../../assets/image/search.svg";
import DeleteIcon from "../../../assets/image/delete-text.svg";

import "./styles.scss";

const propTypes = {
  placeholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired
};

const SearchBy = ({ placeholder, onSearch }) => {
  const [searchValue, setSearchValue] = useState("");
  const inputRef = useRef();

  const onInputChange = e => setSearchValue(e.target.value);

  const inInputKeyUp = e => {
    if (e.key === "Enter") {
      inputRef?.current.blur();
      onSearch(searchValue);
    }
  };

  const onClearClick = () => {
    setSearchValue("");
    onSearch("");
  };

  const onSearchClick = () => onSearch(searchValue);

  return (
    <div className="search-by__wrapper">
      <input
        type="text"
        value={searchValue}
        className="search-by__field"
        placeholder={placeholder}
        onChange={onInputChange}
        onKeyUp={inInputKeyUp}
        ref={inputRef}
      />
      {searchValue && (
        <div className="search-by__clear--wrapper">
          <img
            src={DeleteIcon}
            alt="clear"
            className="search-by__clear--icon"
            onClick={onClearClick}
          />
        </div>
      )}
      <img
        src={SearchIcon}
        alt="search"
        className="search-by__search-icon"
        onClick={onSearchClick}
      />
    </div>
  );
};

SearchBy.propTypes = propTypes;

export default SearchBy;
