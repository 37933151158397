import _ from "lodash";

import USERS from "./actionTypes";

const INITIAL_STATE = {
  users: { count: 10, results: Array.from({ length: 10 }, (v, k) => ({ id: k, loading: true })) },
  meta: {
    page: 1,
    page_size: 10,
    search: "",
    is_active: "",
    is_blocked: "",
    is_admin: "",
    is_blogger: "",
    kyc_verified: "",
    is_kyc_submitted: "",
    ordering: ""
  },
  selectedUsers: [],
  infoLoading: false
};

export default function(state = INITIAL_STATE, { type, payload, meta, users, toRemove }) {
  switch (type) {
    case USERS.GET_USERS_SUCCESS:
      return { ...state, users: payload.data };
    case USERS.GET_USER_IN_LIST_INFO:
      return {
        ...state,
        infoLoading: true
      };
    case USERS.GET_USER_IN_LIST_INFO_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          results: state.users.results.map(el => {
            if (el.id === payload.data.id) {
              return { ...el, withAdditionalInfo: true, ...payload.data };
            }
            return el;
          })
        },
        infoLoading: false
      };
    case USERS.SET_USERS_META:
      return { ...state, meta: { ...state.meta, ...meta } };
    case USERS.SELECT_USERS:
      if (toRemove)
        return { ...state, selectedUsers: state.selectedUsers.filter(el => !users.includes(el)) };
      return { ...state, selectedUsers: _.uniq([...state.selectedUsers, ...users]) };
    case USERS.DESELECT_USERS:
      return { ...state, selectedUsers: [] };
    case USERS.POST_ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          results: state.users.results.map(el => {
            if (el.id === payload.data.id) {
              return { ...el, is_active: true };
            }
            return el;
          })
        }
      };
    case USERS.POST_DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          results: state.users.results.map(el => {
            if (el.id === payload.data.id) {
              return { ...el, is_active: false };
            }
            return el;
          })
        }
      };
    case USERS.POST_BLOCK_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          results: state.users.results.map(el => {
            if (el.id === payload.data.id) {
              return { ...el, is_blocked: true };
            }
            return el;
          })
        }
      };
    case USERS.POST_UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          results: state.users.results.map(el => {
            if (el.id === payload.data.id) {
              return { ...el, is_blocked: false };
            }
            return el;
          })
        }
      };
    case USERS.RESET_USERS:
      return INITIAL_STATE;
    default:
      return state;
  }
}
