import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { useLocation } from "react-router-dom";

import BackLink from "../../_shared/BackLink";
import urls from "../../../core/urls";
import { DailyFields, RankFields } from "../ChallengesFields";
import Loader from "../../_shared/Loader";
import { getChallengeTypeByPathname } from "../helpers";

import { getChallenge, resetChallengesSystem } from "../actions";

import "./styles.scss";

const EditChallenge = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { challenge } = useSelector(({ challengesSystem }) => challengesSystem);

  useEffect(() => {
    dispatch(getChallenge(getChallengeTypeByPathname(pathname), match.params.id));
    return () => {
      resetChallengesSystem();
    };
  }, []);

  return (
    <div className="default-content-wrapper edit-challenge">
      <BackLink to={urls.challengesSystemUrl} />
      {challenge.initial ? (
        <Loader />
      ) : (
        <>
          <h1>{challenge.is_ongoing ? "Challenge details" : "Edit challenge"}</h1>
          {getChallengeTypeByPathname(pathname) === "daily" ? (
            <DailyFields {...challenge} />
          ) : (
            <RankFields {...challenge} />
          )}
        </>
      )}
    </div>
  );
};

export default EditChallenge;
