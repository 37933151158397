import { takeEvery } from "redux-saga/effects";
import {
  enableButtonLoader,
  disableButtonLoader,
  putConfirmationDialogClose,
  openSuccessSnack,
  openErrorSnack
} from "../../core/rootSaga";

export function* handleUserProfileActions() {
  yield takeEvery("POST_DISCONNECT_PROFILE_PLATFORM_SUCCESS", putConfirmationDialogClose);
}

export function* handleUserProfileLoader() {
  yield takeEvery("PATCH_PROFILE_GENERAL", enableButtonLoader);
  yield takeEvery("PATCH_PROFILE_GENERAL_SUCCESS", disableButtonLoader);
  yield takeEvery("PATCH_PROFILE_GENERAL_FAIL", disableButtonLoader);

  yield takeEvery("POST_ADD_BALANCE", enableButtonLoader);
  yield takeEvery("POST_ADD_BALANCE_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_ADD_BALANCE_FAIL", disableButtonLoader);

  yield takeEvery("POST_ADD_BONUS_CASH", enableButtonLoader);
  yield takeEvery("POST_ADD_BONUS_CASH_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_ADD_BONUS_CASH_FAIL", disableButtonLoader);

  yield takeEvery("POST_DISCONNECT_PROFILE_PLATFORM", enableButtonLoader);
  yield takeEvery("POST_DISCONNECT_PROFILE_PLATFORM_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_DISCONNECT_PROFILE_PLATFORM_FAIL", disableButtonLoader);

  yield takeEvery("PATCH_PROFILE_PLATFORM", enableButtonLoader);
  yield takeEvery("PATCH_PROFILE_PLATFORM_SUCCESS", disableButtonLoader);
  yield takeEvery("PATCH_PROFILE_PLATFORM_FAIL", disableButtonLoader);

  yield takeEvery("PATCH_PROFILE_AFFILIATE", enableButtonLoader);
  yield takeEvery("PATCH_PROFILE_AFFILIATE_SUCCESS", disableButtonLoader);
  yield takeEvery("PATCH_PROFILE_AFFILIATE_FAIL", disableButtonLoader);

  yield takeEvery("PATCH_PROFILE_KYC", enableButtonLoader);
  yield takeEvery("PATCH_PROFILE_KYC_SUCCESS", disableButtonLoader);
  yield takeEvery("PATCH_PROFILE_KYC_FAIL", disableButtonLoader);

  yield takeEvery("PATCH_PROFILE_SKILL_CHALLENGES", enableButtonLoader);
  yield takeEvery("PATCH_PROFILE_SKILL_CHALLENGES_SUCCESS", disableButtonLoader);
  yield takeEvery("PATCH_PROFILE_SKILL_CHALLENGES_FAIL", disableButtonLoader);
}

export function* handleUserProfileSnacks() {
  yield takeEvery("PATCH_PROFILE_GENERAL_SUCCESS", () => openSuccessSnack("Successfully saved"));
  yield takeEvery("POST_ADD_BALANCE_SUCCESS", () =>
    openSuccessSnack("Money bonus sent successfully")
  );
  yield takeEvery("POST_ADD_BONUS_CASH_SUCCESS", () =>
    openSuccessSnack("Bonus cash sent successfully")
  );
  yield takeEvery("PATCH_PROFILE_PLATFORM_SUCCESS", () => openSuccessSnack("Successfully saved"));
  yield takeEvery("POST_DISCONNECT_PROFILE_PLATFORM_SUCCESS", () =>
    openSuccessSnack("Platform has been disconnected successfully")
  );
  yield takeEvery("PATCH_PROFILE_AFFILIATE_SUCCESS", () => openSuccessSnack("Successfully saved"));
  yield takeEvery("PATCH_PROFILE_KYC_SUCCESS", () => openSuccessSnack("Successfully saved"));
  yield takeEvery("PATCH_PROFILE_KYC_FAIL", openErrorSnack);
  yield takeEvery("PATCH_PROFILE_SKILL_CHALLENGES_SUCCESS", () =>
    openSuccessSnack("Successfully saved")
  );
}
