import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProfileDisputes } from "../actions";
import NoItems from "../../_shared/NoItems";
import Pagination from "../../_shared/Pagination";
import { DisputeRow } from "../helpers";

const Disputes = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const {
    general: { id: userID },
    disputes: {
      results: { resolved, pending },
      loss_count,
      count
    }
  } = useSelector(({ userProfile }) => userProfile);

  useEffect(() => {
    dispatch(getProfileDisputes(userID, page));
  }, [page]);

  return (
    <div className="user-profile__content-wrapper user-profile__disputes">
      <div className="justify-between">
        <div className="user-profile__general--subtitle">Disputes</div>
        <div className="user-profile__disputes--lost-count">
          Lost count: <span>{loss_count}</span>
        </div>
      </div>
      <div className="user-profile__disputes--wrapper">
        <div className="user-profile__disputes--subtitle">Pending</div>
        {pending.length ? (
          pending.map(({ id, ...rest }) => (
            <DisputeRow key={`pending_${id}`} id={id} {...rest} my_result={null} />
          ))
        ) : (
          <NoItems />
        )}
        <div className="user-profile__disputes--subtitle mt-24">Solved</div>
        {resolved.length ? (
          resolved.map(({ id, ...rest }) => <DisputeRow key={`resolved_${id}`} id={id} {...rest} />)
        ) : (
          <NoItems />
        )}
      </div>
      {count > 0 && <Pagination onChange={e => setPage(e)} total={count} page={page} />}
    </div>
  );
};

export default Disputes;
