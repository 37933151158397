import { put, takeEvery, all, delay } from "redux-saga/effects";
import {
  handleTournamentsSnacks,
  handleTournamentsLoader,
  handleTournamentsActions
} from "../components/Tournaments/saga";
import { closeConfirmationDialog } from "./appActions";
import { handleUsersLoader, handleUsersSnacks, handleUsersActions } from "../components/Users/saga";
import {
  handleUserProfileActions,
  handleUserProfileLoader,
  handleUserProfileSnacks
} from "../components/UserProfile/saga";
import {
  handleSkillChallengesActions,
  handleSkillChallengesSnacks,
  handleSkillChallengesLoader
} from "../components/SkillChallenges/saga";
import {
  handleChallengesSystemActions,
  handleChallengesSystemLoader,
  handleChallengesSystemSnacks
} from "../components/ChallengesSystem/saga";

export default function* rootSaga() {
  yield all([
    handleLoader(),
    handleSnack(),
    handleTournamentsLoader(),
    handleTournamentsSnacks(),
    handleTournamentsActions(),
    handleUsersLoader(),
    handleUsersSnacks(),
    handleUsersActions(),
    handleUserProfileActions(),
    handleUserProfileLoader(),
    handleUserProfileSnacks(),
    handleSkillChallengesActions(),
    handleSkillChallengesSnacks(),
    handleSkillChallengesLoader(),
    handleChallengesSystemActions(),
    handleChallengesSystemLoader(),
    handleChallengesSystemSnacks()
  ]);
}

export function* putConfirmationDialogClose() {
  yield put(closeConfirmationDialog());
}

export function* openErrorSnack(e) {
  yield put({
    type: "ERROR_SNACK_OPEN",
    payload:
      e && e.error && e.error.response && e.error.response.data
        ? typeof Object?.values(e.error.response.data)[0] === "string"
          ? Object?.values(e.error.response.data)[0]
          : Object?.values(e.error.response.data)[0][0]
        : typeof e.error === "string"
        ? e.error
        : "Something went wrong"
  });
}

export function* openSuccessSnack(e) {
  yield put({ type: "SUCCESS_SNACK_OPEN", payload: e });
}

export function* resetErrorSnack() {
  yield delay(100);
  yield put({ type: "ERROR_SNACK_CLOSE" });
}

export function* resetSuccessSnack() {
  yield delay(100);
  yield put({ type: "SUCCESS_SNACK_CLOSE" });
}

export function* handleSnack() {
  yield takeEvery("ERROR_SNACK_OPEN", resetErrorSnack);
  yield takeEvery("SUCCESS_SNACK_OPEN", resetSuccessSnack);
}

export function* enableLoader() {
  yield put({ type: "LOADING", payload: true });
}

export function* disableLoader() {
  yield put({ type: "LOADING", payload: false });
}

export function* enableButtonLoader() {
  yield put({ type: "BUTTON_LOADING", payload: true });
}

export function* disableButtonLoader() {
  yield put({ type: "BUTTON_LOADING", payload: false });
}

function* handleLoader() {
  yield takeEvery("LOGIN", enableLoader);
  yield takeEvery("LOGIN_SUCCESS", disableLoader);
  yield takeEvery("LOGIN_FAIL", disableLoader);

  yield takeEvery("LOGIN", enableButtonLoader);
  yield takeEvery("LOGIN_SUCCESS", disableButtonLoader);
  yield takeEvery("LOGIN_FAIL", disableButtonLoader);
}
