import { takeEvery } from "redux-saga/effects";
import {
  enableButtonLoader,
  disableButtonLoader,
  putConfirmationDialogClose,
  openSuccessSnack,
  openErrorSnack
} from "../../core/rootSaga";

export function* handleSkillChallengesActions() {
  yield takeEvery("DELETE_SKILL_CHALLENGES_CONNECTION_SUCCESS", putConfirmationDialogClose);
  yield takeEvery("POST_SKILL_CHALLENGES_MATCH_WIN_SUCCESS", putConfirmationDialogClose);
  yield takeEvery("POST_SKILL_CHALLENGES_MATCH_LOSS_SUCCESS", putConfirmationDialogClose);
}

export function* handleSkillChallengesLoader() {
  yield takeEvery("DELETE_SKILL_CHALLENGES_CONNECTION", enableButtonLoader);
  yield takeEvery("DELETE_SKILL_CHALLENGES_CONNECTION_SUCCESS", disableButtonLoader);
  yield takeEvery("DELETE_SKILL_CHALLENGES_CONNECTION_FAIL", disableButtonLoader);

  yield takeEvery("POST_SKILL_CHALLENGES_MATCH_WIN", enableButtonLoader);
  yield takeEvery("POST_SKILL_CHALLENGES_MATCH_WIN_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_SKILL_CHALLENGES_MATCH_WIN_FAIL", disableButtonLoader);

  yield takeEvery("POST_SKILL_CHALLENGES_MATCH_LOSS", enableButtonLoader);
  yield takeEvery("POST_SKILL_CHALLENGES_MATCH_LOSS_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_SKILL_CHALLENGES_MATCH_LOSS_FAIL", disableButtonLoader);
}

export function* handleSkillChallengesSnacks() {
  yield takeEvery("DELETE_SKILL_CHALLENGES_CONNECTION_SUCCESS", () =>
    openSuccessSnack("Connection deleted")
  );
  yield takeEvery("DELETE_SKILL_CHALLENGES_CONNECTION_FAIL", openErrorSnack);

  yield takeEvery("POST_SKILL_CHALLENGES_MATCH_WIN_SUCCESS", () =>
    openSuccessSnack("Result successfully set")
  );
  yield takeEvery("POST_SKILL_CHALLENGES_MATCH_WIN_FAIL", openErrorSnack);

  yield takeEvery("POST_SKILL_CHALLENGES_MATCH_LOSS_SUCCESS", () =>
    openSuccessSnack("Result successfully set")
  );
  yield takeEvery("POST_SKILL_CHALLENGES_MATCH_LOSS_FAIL", openErrorSnack);
}
