import React from "react";
import clsx from "clsx";

import Skeleton from "../../_shared/Skeleton";
import NoPhoto from "../../../assets/image/no-photo-dispute.png";
import { getPlatformNameAndIcon } from "../../../core/helpers/functions";

export const renderOpponentInfo = (userInfo, platform, loading, resolved, t, classes = "") => (
  <div className={clsx("tournament-dispute-inner__participants--user-info", classes)}>
    <div className="flex-center">
      <Skeleton loading={loading} variant="circle" width={36} height={36} classes="mr-12">
        <img
          src={userInfo?.avatar}
          alt="agent"
          className="tournament-dispute-inner__participants--user-icon"
        />
      </Skeleton>
      <div>
        <Skeleton loading={loading} variant="text" width={130}>
          <div className="tournament-dispute-inner__participants--user-name">
            {`${userInfo?.first_name} ${userInfo?.last_name}`}
          </div>
        </Skeleton>
        <Skeleton loading={loading} variant="text" width={60}>
          <div className="tournament-dispute-inner__participants--username">
            {getPlatformNameAndIcon(userInfo?.platform_username, platform?.type)}
          </div>
        </Skeleton>
      </div>
    </div>
    {!loading && resolved && userInfo?.result === "win" && (
      <div className="tournament-dispute-inner__participants--winner">
        {t("t:tournaments.winner")}
      </div>
    )}
    {!loading && !resolved && (
      <div className="tournament-dispute-inner__participants--win">{t("t:tournaments.win")}</div>
    )}
  </div>
);

export const renderScreenshotRow = (userInfo, platform, loading, openImageBox) => (
  <div className="tournament-dispute-inner__screenshots--row">
    <Skeleton loading={loading} variant="rect" width={64} height={64} classes="mr-32">
      <img
        src={userInfo?.screenshot_1 || NoPhoto}
        alt="evidence"
        className={clsx(
          "tournament-dispute-inner__screenshots--screenshot",
          userInfo?.screenshot_1 && "clickable"
        )}
        onClick={() => userInfo?.screenshot_1 && openImageBox(userInfo?.screenshot_1)}
      />
    </Skeleton>
    <div className="flex-center">
      <Skeleton loading={loading} variant="circle" width={36} height={36} classes="mr-12">
        <img
          src={userInfo?.avatar}
          alt="agent"
          className="tournament-dispute-inner__screenshots--user-icon"
        />
      </Skeleton>
      <Skeleton loading={loading} variant="text" width={100}>
        <div>
          <p className="tournament-dispute-inner__screenshots--user-name">
            {`${userInfo?.first_name} ${userInfo?.last_name}`}
          </p>
          <p>{getPlatformNameAndIcon(userInfo?.platform_username, platform?.type)}</p>
        </div>
      </Skeleton>
    </div>
  </div>
);

export const renderUserIconAndName = (userInfo, loading, isAgent = false) => (
  <div className="flex-center">
    <Skeleton loading={loading} variant="circle" width={36} height={36} classes="mr-12">
      <img
        src={userInfo?.avatar}
        alt="agent"
        className="tournament-dispute-inner__participants--user-icon"
      />
    </Skeleton>
    <Skeleton loading={loading} variant="text" width={100}>
      <p className="tournament-dispute-inner__participants--user-name">
        {isAgent ? userInfo?.username : `${userInfo?.first_name} ${userInfo?.last_name}`}
      </p>
    </Skeleton>
  </div>
);
