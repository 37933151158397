import { takeEvery } from "redux-saga/effects";
import {
  enableButtonLoader,
  disableButtonLoader,
  putConfirmationDialogClose,
  openSuccessSnack,
  openErrorSnack
} from "../../core/rootSaga";

export function* handleChallengesSystemActions() {
  yield takeEvery("DELETE_CHALLENGE_SUCCESS", putConfirmationDialogClose);
  yield takeEvery("PATCH_CHALLENGE_SUCCESS", putConfirmationDialogClose);
}

export function* handleChallengesSystemLoader() {
  yield takeEvery("DELETE_CHALLENGE", enableButtonLoader);
  yield takeEvery("DELETE_CHALLENGE_SUCCESS", disableButtonLoader);
  yield takeEvery("DELETE_CHALLENGE_FAIL", disableButtonLoader);

  yield takeEvery("PATCH_CHALLENGE", enableButtonLoader);
  yield takeEvery("PATCH_CHALLENGE_SUCCESS", disableButtonLoader);
  yield takeEvery("PATCH_CHALLENGE_FAIL", disableButtonLoader);

  yield takeEvery("POST_CHALLENGE", enableButtonLoader);
  yield takeEvery("POST_CHALLENGE_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_CHALLENGE_FAIL", disableButtonLoader);

  yield takeEvery("PATCH_REWARD", enableButtonLoader);
  yield takeEvery("PATCH_REWARD_SUCCESS", disableButtonLoader);
  yield takeEvery("PATCH_REWARD_FAIL", disableButtonLoader);
}

export function* handleChallengesSystemSnacks() {
  yield takeEvery("DELETE_CHALLENGE_SUCCESS", () => openSuccessSnack("Challenge deleted"));
  yield takeEvery("PATCH_CHALLENGE_SUCCESS", () => openSuccessSnack("Challenge changed"));
  yield takeEvery("POST_CHALLENGE_SUCCESS", () => openSuccessSnack("Challenge created"));
  yield takeEvery("PATCH_REWARD_SUCCESS", () => openSuccessSnack("Reward saved"));

  yield takeEvery("DELETE_CHALLENGE_FAIL", openErrorSnack);
  yield takeEvery("PATCH_CHALLENGE_FAIL", openErrorSnack);
  yield takeEvery("POST_CHALLENGE_FAIL", openErrorSnack);
  yield takeEvery("PATCH_REWARD_FAIL", openErrorSnack);
}
