import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ErrorIcon from "@material-ui/icons/Error";

import TooltipMessage from "../TooltipMessage";

import CloseIcon from "../../../assets/image/close.svg";

import "./styles.scss";

const propTypes = {
  input: PropTypes.shape({}),
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  autoCapitalize: PropTypes.string,
  change: PropTypes.func,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
  })
};

const RenderField = ({
  input,
  type,
  label,
  autoFocus,
  disabled,
  multiline,
  change,
  meta: { touched, error },
  autoCapitalize = "sentences"
}) => {
  return (
    <TextField
      {...input}
      type={type}
      label={label}
      variant="filled"
      disabled={disabled}
      error={touched && !!error}
      autoComplete="off"
      autoFocus={autoFocus}
      multiline={multiline}
      rows="4"
      rowsMax="10"
      classes={{
        root: "custom_input_wrapper"
      }}
      inputProps={{ autoCapitalize }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {change && input.value.length > 0 ? (
              <IconButton
                type="button"
                classes={{
                  root: "field_clear_btn"
                }}
                onClick={() => change(input.name, "")}
              >
                <img src={CloseIcon} alt="close" />
              </IconButton>
            ) : null}
            {touched && !!error ? (
              <TooltipMessage text={error} delay={200} error>
                <ErrorIcon />
              </TooltipMessage>
            ) : (
              ""
            )}
          </InputAdornment>
        ),
        classes: {
          root: "custom_input",
          focused: "custom_input_focused",
          error: "custom_input_error",
          adornedEnd: "custom_input_adorned",
          multiline: "custom_input_multiline"
        }
      }}
      InputLabelProps={{
        classes: {
          root: "custom_input_label",
          focused: "custom_input_label_focused",
          shrink: "custom_input_label_active",
          error: "custom_input_label_error",
          disabled: "custom_input_label_disabled"
        }
      }}
    />
  );
};

RenderField.propTypes = propTypes;

export default RenderField;
