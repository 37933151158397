const tournamentsTabs = t => {
  return [
    { label: t("t:tournaments.current"), value: "current" },
    { label: t("t:tournaments.upcoming"), value: "upcoming" },
    { label: t("t:tournaments.completed"), value: "completed" },
    { label: t("t:tournaments.inactive"), value: "inactive" }
  ];
};

const games = [{ id: 4, name: "FIFA 21 (Online Friendlies)" }];

const platforms = [{ id: 1, name: "Playstation" }];

const tournamentDisputesTabs = t => {
  return [
    { label: t("t:tournaments.pending"), value: "pending" },
    { label: t("t:tournaments.solved"), value: "solved" }
  ];
};

const roles = [
  { name: "Super Admin", value: "admin" },
  { name: "Support", value: "support" },
  { name: "KYC Agent", value: "agent" }
];

const platformStatuses = [
  { label: "Verified", value: "verified" },
  { label: "Verifying", value: "verifying" },
  { label: "Not connected", value: "not_connected" },
  { label: "Error", value: "error" }
];

export { tournamentsTabs, games, platforms, tournamentDisputesTabs, roles, platformStatuses };
