import { takeEvery } from "redux-saga/effects";
import {
  disableLoader,
  enableLoader,
  enableButtonLoader,
  disableButtonLoader,
  openSuccessSnack,
  openErrorSnack,
  putConfirmationDialogClose
} from "../../core/rootSaga";

export function* handleTournamentsActions() {
  yield takeEvery("POST_DEACTIVATE_TOURNAMENT_SUCCESS", putConfirmationDialogClose);
  yield takeEvery("POST_PUBLISH_TOURNAMENT_SUCCESS", putConfirmationDialogClose);
  yield takeEvery("POST_UNPUBLISH_TOURNAMENT_SUCCESS", putConfirmationDialogClose);
}

export function* handleTournamentsLoader() {
  yield takeEvery("GET_TOURNAMENT", enableLoader);
  yield takeEvery("GET_TOURNAMENT_SUCCESS", disableLoader);
  yield takeEvery("GET_TOURNAMENT_FAIL", disableLoader);

  yield takeEvery("POST_DEACTIVATE_TOURNAMENT", enableButtonLoader);
  yield takeEvery("POST_DEACTIVATE_TOURNAMENT_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_DEACTIVATE_TOURNAMENT_FAIL", disableButtonLoader);

  yield takeEvery("POST_PUBLISH_TOURNAMENT", enableButtonLoader);
  yield takeEvery("POST_PUBLISH_TOURNAMENT_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_PUBLISH_TOURNAMENT_FAIL", disableButtonLoader);

  yield takeEvery("POST_RESTORE_TOURNAMENT", enableButtonLoader);
  yield takeEvery("POST_RESTORE_TOURNAMENT_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_RESTORE_TOURNAMENT_FAIL", disableButtonLoader);

  yield takeEvery("POST_UNPUBLISH_TOURNAMENT", enableButtonLoader);
  yield takeEvery("POST_UNPUBLISH_TOURNAMENT_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_UNPUBLISH_TOURNAMENT_FAIL", disableButtonLoader);

  yield takeEvery("POST_CREATE_TOURNAMENT", enableButtonLoader);
  yield takeEvery("POST_CREATE_TOURNAMENT_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_CREATE_TOURNAMENT_FAIL", disableButtonLoader);

  yield takeEvery("POST_JOINED_CHECK_IN", enableButtonLoader);
  yield takeEvery("POST_JOINED_CHECK_IN_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_JOINED_CHECK_IN_FAIL", disableButtonLoader);

  yield takeEvery("POST_JOINED_CHECK_IN_ALL", enableButtonLoader);
  yield takeEvery("POST_JOINED_CHECK_IN_ALL_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_JOINED_CHECK_IN_ALL_FAIL", disableButtonLoader);

  yield takeEvery("POST_JOINED_REMOVE", enableButtonLoader);
  yield takeEvery("POST_JOINED_REMOVE_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_JOINED_REMOVE_FAIL", disableButtonLoader);

  yield takeEvery("POST_JOINED_REMOVE_ALL", enableButtonLoader);
  yield takeEvery("POST_JOINED_REMOVE_ALL_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_JOINED_REMOVE_ALL_FAIL", disableButtonLoader);

  // yield takeEvery("POST_JOINED_ADD_RANDOM", enableButtonLoader);
  // yield takeEvery("POST_JOINED_ADD_RANDOM_SUCCESS", disableButtonLoader);
  // yield takeEvery("POST_JOINED_ADD_RANDOM_FAIL", disableButtonLoader);

  yield takeEvery("POST_WAITLIST_REMOVE", enableButtonLoader);
  yield takeEvery("POST_WAITLIST_REMOVE_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_WAITLIST_REMOVE_FAIL", disableButtonLoader);

  yield takeEvery("POST_WAITLIST_REMOVE_ALL", enableButtonLoader);
  yield takeEvery("POST_WAITLIST_REMOVE_ALL_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_WAITLIST_REMOVE_ALL_FAIL", disableButtonLoader);

  yield takeEvery("POST_WAITLIST_ADD_RANDOM", enableButtonLoader);
  yield takeEvery("POST_WAITLIST_ADD_RANDOM_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_WAITLIST_ADD_RANDOM_FAIL", disableButtonLoader);

  yield takeEvery("POST_START_TOURNAMENT", enableButtonLoader);
  yield takeEvery("POST_START_TOURNAMENT_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_START_TOURNAMENT_FAIL", disableButtonLoader);

  yield takeEvery("POST_START_CHECK_IN", enableButtonLoader);
  yield takeEvery("POST_START_CHECK_IN_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_START_CHECK_IN_FAIL", disableButtonLoader);

  yield takeEvery("POST_START_WAITLIST", enableButtonLoader);
  yield takeEvery("POST_START_WAITLIST_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_START_WAITLIST_FAIL", disableButtonLoader);

  yield takeEvery("POST_TOURNAMENT_RANDOM_PLAY", enableButtonLoader);
  yield takeEvery("POST_TOURNAMENT_RANDOM_PLAY_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_TOURNAMENT_RANDOM_PLAY_FAIL", disableButtonLoader);

  yield takeEvery("POST_MATCH_RANDOM_PLAY", enableButtonLoader);
  yield takeEvery("POST_MATCH_RANDOM_PLAY_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_MATCH_RANDOM_PLAY_FAIL", disableButtonLoader);

  yield takeEvery("POST_MATCH_WINNER", enableButtonLoader);
  yield takeEvery("POST_MATCH_WINNER_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_MATCH_WINNER_FAIL", disableButtonLoader);

  yield takeEvery("GET_CURRENT_TOURNAMENT_DISPUTES", enableButtonLoader);
  yield takeEvery("GET_CURRENT_TOURNAMENT_DISPUTES_SUCCESS", disableButtonLoader);
  yield takeEvery("GET_CURRENT_TOURNAMENT_DISPUTES_FAIL", disableButtonLoader);
}

export function* handleTournamentsSnacks() {
  yield takeEvery("POST_DEACTIVATE_TOURNAMENT_SUCCESS", e =>
    openSuccessSnack(e.meta.previousAction.successMessage)
  );
  yield takeEvery("POST_DEACTIVATE_TOURNAMENT_FAIL", openErrorSnack);

  yield takeEvery("POST_PUBLISH_TOURNAMENT_SUCCESS", e =>
    openSuccessSnack(e.meta.previousAction.successMessage)
  );
  yield takeEvery("POST_PUBLISH_TOURNAMENT_FAIL", openErrorSnack);

  yield takeEvery("POST_RESTORE_TOURNAMENT_SUCCESS", e =>
    openSuccessSnack(e.meta.previousAction.successMessage)
  );
  yield takeEvery("POST_RESTORE_TOURNAMENT_FAIL", openErrorSnack);

  yield takeEvery("POST_UNPUBLISH_TOURNAMENT_SUCCESS", e =>
    openSuccessSnack(e.meta.previousAction.successMessage)
  );
  yield takeEvery("POST_UNPUBLISH_TOURNAMENT_FAIL", openErrorSnack);

  yield takeEvery("POST_JOINED_CHECK_IN_FAIL", openErrorSnack);
  yield takeEvery("POST_JOINED_CHECK_IN_ALL_FAIL", openErrorSnack);
  yield takeEvery("POST_JOINED_REMOVE_FAIL", openErrorSnack);
  yield takeEvery("POST_JOINED_REMOVE_ALL_FAIL", openErrorSnack);
  yield takeEvery("POST_JOINED_ADD_RANDOM_FAIL", openErrorSnack);
  yield takeEvery("POST_WAITLIST_REMOVE_FAIL", openErrorSnack);
  yield takeEvery("POST_WAITLIST_REMOVE_ALL_FAIL", openErrorSnack);
  yield takeEvery("POST_WAITLIST_ADD_RANDOM_FAIL", openErrorSnack);
  yield takeEvery("POST_START_TOURNAMENT_FAIL", openErrorSnack);
  yield takeEvery("POST_TOURNAMENT_RANDOM_PLAY_FAIL", openErrorSnack);
  yield takeEvery("POST_MATCH_RANDOM_PLAY_FAIL", openErrorSnack);
  yield takeEvery("POST_MATCH_WINNER_FAIL", openErrorSnack);
  yield takeEvery("POST_START_CHECK_IN_FAIL", openErrorSnack);
  yield takeEvery("POST_START_WAITLIST_FAIL", openErrorSnack);
}
