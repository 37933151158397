import React, { memo } from "react";
import { Controller } from "react-hook-form";
import _ from "lodash";

import InputField from "../../../_shared/InputField";

import DummyAvatar from "../../../../assets/image/dummy-avatar.svg";

const UserFields = ({
  inputRef,
  handleFile,
  previewAvatar,
  onUploadClick,
  onDeleteClick,
  control,
  errors
}) => {
  return (
    <div className="user-profile__general--block">
      <input
        ref={inputRef}
        className="input-file"
        type="file"
        multiple={false}
        onChange={handleFile}
        accept="image/*"
      />
      <div className="fields-block">
        <div className="fields">
          <div className="several-fields">
            {previewAvatar ? (
              <div className="input-field flex-center">
                <img src={previewAvatar} alt="preview" className="preview-image" />
                <div>
                  <p>Profile photo</p>
                  <div className="photo-btns">
                    <button type="button" className="change-btn text-hover" onClick={onUploadClick}>
                      Change
                    </button>
                    <button type="button" className="delete-btn text-hover" onClick={onDeleteClick}>
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="input-field flex-center">
                <img src={DummyAvatar} alt="preview" className="preview-image" />
                <div>
                  <p className="profile-photo">Profile photo</p>
                  <div className="photo-btns">
                    <button type="button" className="change-btn text-hover" onClick={onUploadClick}>
                      Choose photo
                    </button>
                  </div>
                </div>
              </div>
            )}
            <Controller
              name="username"
              control={control}
              render={props => (
                <InputField
                  id="username-input"
                  label="Username"
                  type="text"
                  {..._.omit(props.field, "ref")}
                  error={errors.username?.message}
                />
              )}
            />
          </div>
          <div className="several-fields margin-top-32">
            <Controller
              name="first_name"
              control={control}
              render={props => (
                <InputField
                  id="first-name-input"
                  label="First name"
                  type="text"
                  {..._.omit(props.field, "ref")}
                  error={errors.first_name?.message}
                />
              )}
            />
            <Controller
              name="last_name"
              control={control}
              render={props => (
                <InputField
                  id="last-name-input"
                  label="Last name"
                  type="text"
                  {..._.omit(props.field, "ref")}
                  error={errors.last_name?.message}
                />
              )}
            />
          </div>
          <div className="several-fields margin-top-32">
            <Controller
              name="email"
              control={control}
              render={props => (
                <InputField
                  id="email-name-input"
                  label="Email"
                  type="text"
                  {..._.omit(props.field, "ref")}
                  error={errors.email?.message}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={props => (
                <InputField
                  id="phone-input"
                  label="Phone number"
                  type="text"
                  {..._.omit(props.field, "ref")}
                  error={errors.phone?.message}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(UserFields);
