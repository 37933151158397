import React, { memo } from "react";
import clsx from "clsx";

import { generalCols, statisticsRows } from "../constants";
import { getPlatformIconAndName } from "../../../core/helpers/functions";

import ActiveIcon from "../../../assets/image/is_active.svg";
import InactiveIcon from "../../../assets/image/is_inactive.svg";

const MoreUserInfo = fields => (
  <div className="users__more-info">
    <div className="users__more-info--title">General</div>
    <div className="users__more-info--cols-wrapper">
      {generalCols.map(({ label, value }) => (
        <div className="users__more-info--col" key={value}>
          <p className="users__more-info--row">
            <img
              src={fields[value] ? ActiveIcon : InactiveIcon}
              alt={value}
              className="users__more-info--active"
            />
            {label}
          </p>
        </div>
      ))}
    </div>
    <hr className="users__more-info--separator" />
    <div className="users__more-info--title">Connected platforms</div>
    <div className="users__more-info--cols-wrapper">
      {fields.connected_platforms &&
        fields.connected_platforms
          .sort((first, second) => first.platform_type.length - second.platform_type.length)
          .map(({ profile_id, status, platform_name, platform_type, status_display }) => (
            <div className="users__more-info--col" key={platform_name}>
              <p className="users__more-info--row">
                <img
                  src={status === "verified" ? ActiveIcon : InactiveIcon}
                  alt={platform_type}
                  className="users__more-info--active"
                />
                {getPlatformIconAndName(platform_name)}
              </p>
              <p className="users__more-info--row">
                {status === "verified" ? (
                  <span className="platform green">{profile_id}</span>
                ) : (
                  <span className="platform gray">{status_display}</span>
                )}
              </p>
            </div>
          ))}
    </div>
    <hr className="users__more-info--separator" />
    <div className="users__more-info--title">Statistics</div>
    <div className="users__more-info--cols-wrapper">
      <div className="users__more-info--col">
        <div className="users__more-info--subtitle">1vs1 - PLAY FOR 1€</div>
        {statisticsRows.map(({ label, value, isMoney, color }) => (
          <p className="users__more-info--row" key={`free_${value}`}>
            <span>{label}</span>
            {fields.statistics_free && (
              <span className={clsx("users__more-info--value-in-row", color)}>
                {isMoney
                  ? `${fields.statistics_free[value].toFixed(2)}€`
                  : fields.statistics_free[value]}
              </span>
            )}
          </p>
        ))}
      </div>
      <div className="users__more-info--col">
        <div className="users__more-info--subtitle">1vs1 - PLAY FOR 2-25€</div>
        {statisticsRows.map(({ label, value, isMoney, color }) => (
          <p className="users__more-info--row" key={`paid_${value}`}>
            <span>{label}</span>
            {fields.statistics_paid && (
              <span className={clsx("users__more-info--value-in-row", color)}>
                {isMoney
                  ? `${fields.statistics_paid[value].toFixed(2)}€`
                  : fields.statistics_paid[value]}
              </span>
            )}
          </p>
        ))}
      </div>
      <div className="users__more-info--col">
        <div className="users__more-info--subtitle">Skill challenges</div>
        {statisticsRows.map(({ label, value, isMoney, color }) => (
          <p className="users__more-info--row" key={`boy_${value}`}>
            <span>{label}</span>
            {fields.statistics_boy && (
              <span className={clsx("users__more-info--value-in-row", color)}>
                {isMoney
                  ? `${fields.statistics_boy[value].toFixed(2)}€`
                  : fields.statistics_boy[value]}
              </span>
            )}
          </p>
        ))}
      </div>
    </div>
    <hr className="users__more-info--separator" />
    <div className="users__more-info--col">
      <div className="users__more-info--subtitle">Disputes</div>
      <p className="users__more-info--row">
        <span>All:</span>
        <span className="users__more-info--value-in-row">{fields.disputes_count}</span>
      </p>
      <p className="users__more-info--row">
        <span>Pending:</span>
        <span className="users__more-info--value-in-row">{fields.pending_disputes_count}</span>
      </p>
    </div>
  </div>
);

export default memo(MoreUserInfo);
