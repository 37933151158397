import dayjs from "dayjs";
import { splitByCommas } from "../../core/helpers/functions";

const periods = [
  { label: "Daily", value: "day" },
  { label: "Weekly", value: "week" },
  { label: "Monthly", value: "month" }
];

const pieChartOptions = {
  title: {
    text: null
  },
  tooltip: {
    borderWidth: 0,
    borderRadius: 8,
    backgroundColor: "##0E182A",
    boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.25)",

    style: {
      fontFamily: "Inter,sans-serif",
      color: "#FFFFFF"
    }
  },
  credits: {
    enabled: false
  },
  chart: {
    renderTo: "container",
    type: "pie",
    backgroundColor: "transparent",
    marginLeft: -50,
    spacingTop: 0,
    spacingBottom: 0,
    spacingLeft: 0,
    spacingRight: 0,
    height: 200,
    width: 450
  },
  plotOptions: {
    pie: {
      paddingLeft: 0,
      innerSize: 144,
      size: 160,
      cursor: "pointer",
      showInLegend: true,
      borderWidth: 0,
      borderColor: "transparent",
      dataLabels: {
        enabled: false
      }
    }
  },
  legend: {
    enabled: true,
    align: "right",
    verticalAlign: "middle",
    borderWidth: 0,
    margin: 0,
    labelFormatter: function() {
      return "<b>&nbsp;" + splitByCommas(this.y) + "&nbsp;</b>" + this.name;
    },
    itemWidth: 220,
    itemMarginTop: 12,
    itemMarginBottom: 12,
    itemHoverStyle: {
      color: "#FFFFFF",
      opacity: 0.7
    },
    itemHiddenStyle: {
      color: "#FFFFFF",
      opacity: 0.3
    },
    itemStyle: {
      fontFamily: "Inter,sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      color: "#FFFFFF",
      opacity: 1
    },
    symbolRadius: 2,
    symbolHeight: 12
  }
};

const lineChartOptions = {
  title: {
    text: null
  },
  tooltip: {
    borderWidth: 0,
    borderRadius: 4,
    backgroundColor: "#080B1B",
    shared: true,
    //     formatter: function() {
    //       return `
    // <span style="color:rgba(255, 255, 255, 0.5); opacity: 0.5; font-size: 12px;">${dayjs(this.x).format(
    //         "D MMM YYYY"
    //       )}</span>
    // `;
    //     },
    style: {
      fontFamily: "Inter,sans-serif",
      color: "#FFFFFF"
    }
  },
  credits: {
    enabled: false
  },
  chart: {
    type: "line",
    height: 500,
    backgroundColor: "transparent",
    marginTop: 30,
    spacingRight: 40
  },
  navigator: {
    enabled: true
  },
  xAxis: {
    type: "datetime",
    gridLineWidth: 1,
    gridLineColor: "rgba(56, 63, 70, 0.75)",
    tickColor: "#969a9e",
    lineColor: "#969a9e",
    lineWidth: 2,
    tickWidth: 2,
    tickLength: 6
  },
  yAxis: {
    title: "",
    gridLineWidth: 1,
    gridLineColor: "rgba(56, 63, 70, 0.75)",
    tickColor: "#969a9e",
    lineColor: "#969a9e",
    lineWidth: 2,
    tickWidth: 2,
    tickLength: 6,
    labels: {
      style: {
        color: "rgba(255, 255, 255, 0.5)"
      }
    }
  },
  plotOptions: {
    line: {
      marker: {
        enabled: true,
        enabledThreshold: 1
      },
      lineWidth: 3
    }
  },
  legend: {
    enabled: true,
    borderWidth: 0,
    margin: 24,
    itemHoverStyle: {
      color: "#FFFFFF",
      opacity: 0.7
    },
    itemHiddenStyle: {
      color: "#FFFFFF",
      opacity: 0.3
    },
    itemStyle: {
      fontFamily: "Inter,sans-serif",
      fontSize: "12px",
      fontWeight: 600,
      color: "#FFFFFF",
      opacity: 1
    },
    symbolRadius: 2,
    symbolHeight: 12
  }
};

const prepareLineChartData = ({ android, ios, unknown, web }) => {
  return [
    {
      name: "Web",
      color: "#F3BB48",
      data: web?.map(({ reg_day, reg_count }) => [dayjs(reg_day).valueOf(), reg_count]) || [],
      marker: {
        symbol: "circle"
      }
    },
    {
      name: "iOS",
      color: "#57A1E6",
      data: ios?.map(({ reg_day, reg_count }) => [dayjs(reg_day).valueOf(), reg_count]) || [],
      marker: {
        symbol: "circle"
      }
    },
    {
      name: "Android",
      color: "#2FC272",
      data: android?.map(({ reg_day, reg_count }) => [dayjs(reg_day).valueOf(), reg_count]) || [],
      marker: {
        symbol: "circle"
      }
    },
    {
      name: "Unknown",
      color: "#737A93",
      data: unknown?.map(({ reg_day, reg_count }) => [dayjs(reg_day).valueOf(), reg_count]) || [],
      marker: {
        symbol: "circle"
      }
    }
  ];
};

export { periods, pieChartOptions, lineChartOptions, prepareLineChartData };
