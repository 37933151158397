import TOURNAMENTS from "./actionTypes";
import { joinQueries } from "../../core/helpers/functions";

export function getTournaments(status, queries) {
  return {
    type: TOURNAMENTS.GET_TOURNAMENTS,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournaments/${status}/${joinQueries(queries)}`,
        method: "get"
      }
    }
  };
}

export function resetTournaments() {
  return {
    type: TOURNAMENTS.RESET_TOURNAMENTS
  };
}

export function resetTournamentInfo() {
  return {
    type: TOURNAMENTS.RESET_TOURNAMENT_INFO
  };
}

export function setUsersList(users) {
  return {
    type: TOURNAMENTS.SET_USERS_LIST,
    users
  };
}

export function setDisputeDialogOpen(disputesDialogOpen) {
  return {
    type: TOURNAMENTS.SET_DISPUTE_DIALOG_OPEN,
    disputesDialogOpen
  };
}

export function getTournament(id) {
  return {
    type: TOURNAMENTS.GET_TOURNAMENT,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${id}/`,
        method: "get"
      }
    }
  };
}

export function postDeactivateTournament(id, successMessage) {
  return {
    type: TOURNAMENTS.POST_DEACTIVATE_TOURNAMENT,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${id}/deactivate/`,
        method: "post"
      }
    },
    successMessage
  };
}

export function postPublishTournament(id, successMessage) {
  return {
    type: TOURNAMENTS.POST_PUBLISH_TOURNAMENT,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${id}/publish/`,
        method: "post"
      }
    },
    successMessage
  };
}

export function postRestoreTournament(id, data, successMessage) {
  return {
    type: TOURNAMENTS.POST_RESTORE_TOURNAMENT,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${id}/restore/`,
        method: "post",
        data
      }
    },
    successMessage
  };
}

export function postUnpublishTournament(id, successMessage) {
  return {
    type: TOURNAMENTS.POST_UNPUBLISH_TOURNAMENT,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${id}/unpublish/`,
        method: "post"
      }
    },
    successMessage
  };
}

export function postCreateTournament(data) {
  return {
    type: TOURNAMENTS.POST_CREATE_TOURNAMENT,
    payload: {
      client: "default",
      request: {
        url: "/api/admin/tournaments/create/",
        method: "post",
        data
      }
    }
  };
}

export function patchTournament(id, data) {
  return {
    type: TOURNAMENTS.PATCH_TOURNAMENT,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${id}/edit/`,
        method: "patch",
        data
      }
    }
  };
}

export function postJoinedCheckIn(tournament_id, joined_id) {
  return {
    type: TOURNAMENTS.POST_JOINED_CHECK_IN,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${tournament_id}/joined/${joined_id}/check-in/`,
        method: "post"
      }
    }
  };
}

export function postJoinedCheckInAll(tournament_id) {
  return {
    type: TOURNAMENTS.POST_JOINED_CHECK_IN_ALL,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${tournament_id}/joined/check-in-all/`,
        method: "post"
      }
    }
  };
}

export function postJoinedRemove(tournament_id, joined_id) {
  return {
    type: TOURNAMENTS.POST_JOINED_REMOVE,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${tournament_id}/joined/${joined_id}/remove/`,
        method: "post"
      }
    }
  };
}

export function postJoinedRemoveAll(tournament_id) {
  return {
    type: TOURNAMENTS.POST_JOINED_REMOVE_ALL,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${tournament_id}/joined/remove-all/`,
        method: "post"
      }
    }
  };
}

export function postJoinedAddRandom(tournament_id) {
  return {
    type: TOURNAMENTS.POST_JOINED_ADD_RANDOM,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${tournament_id}/joined/add-random/`,
        method: "post"
      }
    }
  };
}

export function postWaitlistRemove(tournament_id, waitlist_id) {
  return {
    type: TOURNAMENTS.POST_WAITLIST_REMOVE,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${tournament_id}/waitlist/${waitlist_id}/remove/`,
        method: "post"
      }
    }
  };
}

export function postWaitlistRemoveAll(tournament_id) {
  return {
    type: TOURNAMENTS.POST_WAITLIST_REMOVE_ALL,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${tournament_id}/waitlist/remove-all/`,
        method: "post"
      }
    }
  };
}

export function postWaitlistAddRandom(tournament_id) {
  return {
    type: TOURNAMENTS.POST_WAITLIST_ADD_RANDOM,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${tournament_id}/waitlist/add-random/`,
        method: "post"
      }
    }
  };
}

export function postStartTournament(tournament_id) {
  return {
    type: TOURNAMENTS.POST_START_TOURNAMENT,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${tournament_id}/start/`,
        method: "post"
      }
    }
  };
}

export function postStartCheckIn(tournament_id) {
  return {
    type: TOURNAMENTS.POST_START_CHECK_IN,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${tournament_id}/start-checkin/`,
        method: "post"
      }
    }
  };
}

export function postStartWaitlist(tournament_id) {
  return {
    type: TOURNAMENTS.POST_START_WAITLIST,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${tournament_id}/start-waitlist/`,
        method: "post"
      }
    }
  };
}

export function getTournamentsSelectOptions() {
  return {
    type: TOURNAMENTS.GET_TOURNAMENT_SELECT_OPTIONS,
    payload: {
      client: "default",
      request: {
        url: "/api/admin/tournaments/select-options/",
        method: "get"
      }
    }
  };
}

export function postTournamentRandomPlay(tournament_id) {
  return {
    type: TOURNAMENTS.POST_TOURNAMENT_RANDOM_PLAY,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${tournament_id}/random-play/`,
        method: "post"
      }
    }
  };
}

export function postMatchRandomPlay(tournament_id, match_id) {
  return {
    type: TOURNAMENTS.POST_MATCH_RANDOM_PLAY,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${tournament_id}/match/${match_id}/random-play/`,
        method: "post"
      }
    }
  };
}

export function postMatchWinner(tournament_id, match_id, participant_id) {
  return {
    type: TOURNAMENTS.POST_MATCH_WINNER,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${tournament_id}/match/${match_id}/set-winner/${participant_id}/`,
        method: "post"
      }
    }
  };
}

export function getCurrentTournamentDisputes(id) {
  return {
    type: TOURNAMENTS.GET_CURRENT_TOURNAMENT_DISPUTES,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/tournament/${id}/disputes/`,
        method: "get"
      }
    }
  };
}

export function getTournamentDisputes(status, queries) {
  return {
    type: TOURNAMENTS.GET_TOURNAMENT_DISPUTES_LIST,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/disputes/tournament/${status}/${joinQueries(queries)}`,
        method: "get"
      }
    }
  };
}

export function getTournamentDisputeInner(id) {
  return {
    type: TOURNAMENTS.GET_TOURNAMENT_DISPUTE_INNER,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/disputes/tournament/${id}/`,
        method: "get"
      }
    }
  };
}

export function resetTournamentDisputeInner() {
  return {
    type: TOURNAMENTS.RESET_TOURNAMENT_DISPUTE_INNER
  };
}

export function postSetTournamentDisputeWinner(id, data) {
  return {
    type: TOURNAMENTS.POST_SET_TOURNAMENT_DISPUTE_WINNER,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/disputes/tournament/${id}/set-winner/`,
        method: "post",
        data
      }
    }
  };
}

export function getDisputeMessages(query) {
  return {
    type: TOURNAMENTS.GET_DISPUTE_MESSAGES,
    payload: {
      client: "default",
      request: {
        url: `/api/v0/chat/dispute-message/${query}`,
        method: "get"
      }
    }
  };
}

export function updateDisputeMessageList(data) {
  return {
    type: TOURNAMENTS.UPDATE_DISPUTE_MESSAGE_LIST,
    data
  };
}

export function postChatImage(data) {
  return {
    type: TOURNAMENTS.POST_CHAT_IMAGE,
    payload: {
      client: "default",
      request: {
        url: "/api/v0/chat/image/",
        method: "post",
        data
      }
    }
  };
}
