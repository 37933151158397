import React, { memo } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import Skeleton from "../../_shared/Skeleton";
import UserActionsPopover from "../../Users/UserActionsPopover";

import ActiveIcon from "../../../assets/image/is_active.svg";
import InactiveIcon from "../../../assets/image/is_inactive.svg";
import BlockedIcon from "../../../assets/image/is_blocked.svg";

const ProfileHeader = () => {
  const {
    general: { initial, id, avatar, username, last_online, is_blocked, is_active }
  } = useSelector(({ userProfile }) => userProfile);
  return (
    <div className="user-profile__header justify-between">
      <div className="flex-center">
        <Skeleton
          loading={!avatar}
          variant="circle"
          width={56}
          height={56}
          classes="user-profile__header--avatar"
        >
          <img src={avatar} alt="avatar" className="user-profile__header--avatar" />
        </Skeleton>
        <div>
          <Skeleton
            loading={!username}
            variant="text"
            width={200}
            classes="user-profile__header--username"
          >
            <div className="user-profile__header--username flex-center">
              {username}
              <img
                src={is_active ? ActiveIcon : InactiveIcon}
                alt="is_active"
                className="user-profile__header--active-icon"
              />
              {is_blocked && <img src={BlockedIcon} alt="blocked" />}
            </div>
          </Skeleton>
          <Skeleton loading={!username} variant="text" width={150}>
            <div className="user-profile__header--last-active">
              {`Last active ${dayjs(last_online).format("DD.MM.YYYY HH:mm")}`}
            </div>
          </Skeleton>
        </div>
      </div>
      <UserActionsPopover id={id} loading={initial} is_blocked={is_blocked} is_active={is_active} />
    </div>
  );
};

export default memo(ProfileHeader);
