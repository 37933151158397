const SKILL_CHALLENGES = {
  GET_SKILL_CHALLENGES_CONNECTION: "GET_SKILL_CHALLENGES_CONNECTION",
  GET_SKILL_CHALLENGES_CONNECTION_SUCCESS: "GET_SKILL_CHALLENGES_CONNECTION_SUCCESS",
  GET_SKILL_CHALLENGES_CONNECTION_FAIL: "GET_SKILL_CHALLENGES_CONNECTION_FAIL",

  DELETE_SKILL_CHALLENGES_CONNECTION: "DELETE_SKILL_CHALLENGES_CONNECTION",
  DELETE_SKILL_CHALLENGES_CONNECTION_SUCCESS: "DELETE_SKILL_CHALLENGES_CONNECTION_SUCCESS",
  DELETE_SKILL_CHALLENGES_CONNECTION_FAIL: "DELETE_SKILL_CHALLENGES_CONNECTION_FAIL",

  GET_SKILL_CHALLENGES_MATCHES: "GET_SKILL_CHALLENGES_MATCHES",
  GET_SKILL_CHALLENGES_MATCHES_SUCCESS: "GET_SKILL_CHALLENGES_MATCHES_SUCCESS",
  GET_SKILL_CHALLENGES_MATCHES_FAIL: "GET_SKILL_CHALLENGES_MATCHES_FAIL",

  POST_SKILL_CHALLENGES_MATCH_WIN: "POST_SKILL_CHALLENGES_MATCH_WIN",
  POST_SKILL_CHALLENGES_MATCH_WIN_SUCCESS: "POST_SKILL_CHALLENGES_MATCH_WIN_SUCCESS",
  POST_SKILL_CHALLENGES_MATCH_WIN_FAIL: "POST_SKILL_CHALLENGES_MATCH_WIN_FAIL",

  POST_SKILL_CHALLENGES_MATCH_LOSS: "POST_SKILL_CHALLENGES_MATCH_LOSS",
  POST_SKILL_CHALLENGES_MATCH_LOSS_SUCCESS: "POST_SKILL_CHALLENGES_MATCH_LOSS_SUCCESS",
  POST_SKILL_CHALLENGES_MATCH_LOSS_FAIL: "POST_SKILL_CHALLENGES_MATCH_LOSS_FAIL",

  GET_SKILL_CHALLENGES_STATISTICS: "GET_SKILL_CHALLENGES_STATISTICS",
  GET_SKILL_CHALLENGES_STATISTICS_SUCCESS: "GET_SKILL_CHALLENGES_STATISTICS_SUCCESS",
  GET_SKILL_CHALLENGES_STATISTICS_FAIL: "GET_SKILL_CHALLENGES_STATISTICS_FAIL",

  SET_CONNECTION_META: "SET_CONNECTION_META",
  SET_MATCHES_META: "SET_MATCHES_META",
  SET_STATISTICS_META: "SET_STATISTICS_META",
  RESET_SKILL_CHALLENGES: "RESET_SKILL_CHALLENGES"
};

export default SKILL_CHALLENGES;
