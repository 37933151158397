import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";

import CheckedIcon from "../../../assets/image/checked.svg";
import UncheckedIcon from "../../../assets/image/unchecked.svg";

import "./styles.scss";

const propTypes = {
  disabled: PropTypes.bool
};

const defaultProps = {
  disabled: false
};

const CheckField = ({ disabled, ...props }) => (
  <Checkbox
    {...props}
    checked={!!props.value}
    onChange={e => props.onChange(e.target.checked)}
    disabled={disabled}
    icon={<img src={UncheckedIcon} alt="unchecked" />}
    checkedIcon={<img src={CheckedIcon} alt="checked" />}
  />
);

CheckField.propTypes = propTypes;
CheckField.defaultProps = defaultProps;

export default CheckField;
