import * as yup from "yup";
import {
  integerNumberValidation,
  positiveOrZeroNumberValidation
} from "../../../core/helpers/validations";

const affiliateSchema = yup.object().shape({
  is_blogger: yup.bool(),
  blogger_code: yup.string(),
  blogger_revenue_days: integerNumberValidation(),
  blogger_revenue_share_percentage: positiveOrZeroNumberValidation()
});

export default affiliateSchema;
