import { takeEvery } from "redux-saga/effects";
import {
  disableLoader,
  enableButtonLoader,
  disableButtonLoader,
  enableLoader,
  putConfirmationDialogClose,
  openSuccessSnack,
  openErrorSnack
} from "../../core/rootSaga";

export function* handleUsersActions() {
  yield takeEvery("POST_ACTIVATE_USER_SUCCESS", putConfirmationDialogClose);
  yield takeEvery("POST_DEACTIVATE_USER_SUCCESS", putConfirmationDialogClose);
  yield takeEvery("POST_BLOCK_USER_SUCCESS", putConfirmationDialogClose);
  yield takeEvery("POST_UNBLOCK_USER_SUCCESS", putConfirmationDialogClose);
}

export function* handleUsersLoader() {
  yield takeEvery("GET_USERS", enableLoader);
  yield takeEvery("GET_USERS_SUCCESS", disableLoader);
  yield takeEvery("GET_USERS_FAIL", disableLoader);

  yield takeEvery("POST_ACTIVATE_USER", enableButtonLoader);
  yield takeEvery("POST_ACTIVATE_USER_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_ACTIVATE_USER_FAIL", disableButtonLoader);

  yield takeEvery("POST_DEACTIVATE_USER", enableButtonLoader);
  yield takeEvery("POST_DEACTIVATE_USER_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_DEACTIVATE_USER_FAIL", disableButtonLoader);

  yield takeEvery("POST_BLOCK_USER", enableButtonLoader);
  yield takeEvery("POST_BLOCK_USER_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_BLOCK_USER_FAIL", disableButtonLoader);

  yield takeEvery("POST_UNBLOCK_USER", enableButtonLoader);
  yield takeEvery("POST_UNBLOCK_USER_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_UNBLOCK_USER_FAIL", disableButtonLoader);

  yield takeEvery("POST_NEW_USER", enableButtonLoader);
  yield takeEvery("POST_NEW_USER_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_NEW_USER_FAIL", disableButtonLoader);
}

export function* handleUsersSnacks() {
  yield takeEvery("POST_ACTIVATE_USER_SUCCESS", () =>
    openSuccessSnack("User successfully activated")
  );
  yield takeEvery("POST_ACTIVATE_USER_FAIL", openErrorSnack);

  yield takeEvery("POST_DEACTIVATE_USER_SUCCESS", () =>
    openSuccessSnack("User successfully deactivated")
  );
  yield takeEvery("POST_DEACTIVATE_USER_FAIL", openErrorSnack);

  yield takeEvery("POST_BLOCK_USER_SUCCESS", () => openSuccessSnack("User successfully blocked"));
  yield takeEvery("POST_BLOCK_USER_FAIL", openErrorSnack);

  yield takeEvery("POST_UNBLOCK_USER_SUCCESS", () =>
    openSuccessSnack("User successfully unblocked")
  );
  yield takeEvery("POST_UNBLOCK_USER_FAIL", openErrorSnack);

  yield takeEvery("POST_NEW_USER_SUCCESS", () => openSuccessSnack("User successfully created"));
}
