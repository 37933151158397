import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import Popover from "@material-ui/core/Popover";
import clsx from "clsx";

import { closeConfirmationDialog, setConfirmationDialog } from "../../../../../core/appActions";
import {
  postDeactivateTournament,
  // postUnpublishTournament,
  setUsersList,
  postJoinedAddRandom,
  postWaitlistAddRandom,
  postJoinedCheckIn,
  postJoinedRemove,
  postWaitlistRemove,
  postJoinedCheckInAll,
  postJoinedRemoveAll,
  postWaitlistRemoveAll,
  postStartTournament,
  getTournament,
  postStartCheckIn,
  postStartWaitlist,
  setDisputeDialogOpen
} from "../../../actions";

import urls from "../../../../../core/urls";
import { getGameInfo, getTournamentStatus } from "../../../../../core/helpers/functions";
import DefaultButton from "../../../../_shared/Buttons/DefaultButton";
import BackLink from "../../../../_shared/BackLink";
import IconButton from "../../../../_shared/Buttons/IconButton";

import CheckIcon from "../../../../../assets/image/checkmark.svg";
import OpenIcon from "../../../../../assets/image/open.svg";
import ActionsIcon from "../../../../../assets/image/three-vertical-dots.svg";
// import UnpublishIcon from "../../../../assets/image/unpublish.svg";
import PublishIcon from "../../../../../assets/image/publish.svg";
import EditIcon from "../../../../../assets/image/edit.svg";
import DeactivateIcon from "../../../../../assets/image/deactivate.svg";
import ParticipantsIcon from "../../../../../assets/image/participants.svg";
import PlayIcon from "../../../../../assets/image/play.svg";
import DisputesIcon from "../../../../../assets/image/dispute-details.svg";

const propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  status: PropTypes.string,
  start_at: PropTypes.string,
  game: PropTypes.shape({}),
  platform: PropTypes.shape({}),
  min_participants: PropTypes.number,
  max_participants: PropTypes.number,
  joined: PropTypes.arrayOf(PropTypes.shape({})),
  waitlist: PropTypes.arrayOf(PropTypes.shape({})),
  participants: PropTypes.arrayOf(PropTypes.shape({})),
  joined_count: PropTypes.number,
  waitlist_count: PropTypes.number,
  participants_count: PropTypes.number,
  entry_fee: PropTypes.number,
  system_fee: PropTypes.number,
  max_prize_pool: PropTypes.number,
  prize_pool: PropTypes.number,
  second_place_prize: PropTypes.number,
  disputes_count: PropTypes.number,
  bracket: PropTypes.arrayOf(PropTypes.shape({})),
  onPublishClick: PropTypes.func,
  is_test: PropTypes.bool
};

const defaultProps = {
  id: 0,
  title: "-",
  status: "",
  start_at: new Date().toISOString(),
  game: {},
  platform: {},
  min_participants: 0,
  max_participants: 0,
  joined: [],
  joined_count: 0,
  waitlist: [],
  waitlist_count: 0,
  entry_fee: 0,
  system_fee: 0,
  max_prize_pool: 0,
  participants: [],
  participants_count: 0,
  prize_pool: 0,
  second_place_prize: 0,
  disputes_count: 0,
  bracket: {},
  is_test: false,
  onPublishClick: () => {}
};

const InnerHeader = ({
  id,
  title,
  status,
  start_at,
  game,
  platform,
  min_participants,
  max_participants,
  joined,
  participants,
  participants_count,
  joined_count,
  waitlist,
  waitlist_count,
  entry_fee,
  system_fee,
  max_prize_pool,
  onPublishClick,
  prize_pool,
  second_place_prize,
  disputes_count,
  bracket,
  is_test,
  custom_first_place_prize
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isPublished = status === "published" || status === "upcoming";
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (status === "inactive") {
      history.push(`${urls.tournamentsUrl}?tab=inactive&page=1`);
    }
  }, [status]);

  const hasUnresolvedDispute = useMemo(() => {
    let unresolved = false;
    if (bracket.levels) {
      bracket.levels.map(level => {
        if (level.matches.some(({ has_dispute, has_winner }) => has_dispute && !has_winner)) {
          unresolved = true;
        }
      });
    }
    return unresolved;
  }, [bracket]);

  const onParticipantsClick = () => {
    dispatch(
      setUsersList({
        open: true,
        title: t("t:tournaments.participants"),
        numberOfUsers: participants_count,
        users: participants
      })
    );
  };

  const onJoinedClick = () => {
    dispatch(
      setUsersList({
        open: true,
        title: t("t:tournaments.joined-users"),
        numberOfUsers: joined_count,
        users: joined,
        clearAllAction: is_test ? () => dispatch(postJoinedRemoveAll(id)) : false,
        acceptAction: is_test
          ? async userId => {
              await dispatch(postJoinedCheckIn(id, userId));
              dispatch(getTournament(id));
            }
          : false,
        checkInAllAction: is_test
          ? async () => {
              await dispatch(postJoinedCheckInAll(id));
              dispatch(getTournament(id));
            }
          : false,
        removeAction: is_test ? userId => dispatch(postJoinedRemove(id, userId)) : false,
        addRandomAction: is_test ? () => dispatch(postJoinedAddRandom(id)) : false,
        participants
      })
    );
  };

  const onWaitlistClick = () => {
    dispatch(
      setUsersList({
        open: true,
        title: t("t:tournaments.waitlist"),
        numberOfUsers: waitlist_count,
        users: waitlist,
        acceptAction: false,
        checkInAllAction: false,
        clearAllAction: is_test ? () => dispatch(postWaitlistRemoveAll(id)) : false,
        removeAction: is_test ? userId => dispatch(postWaitlistRemove(id, userId)) : false,
        addRandomAction: is_test ? () => dispatch(postWaitlistAddRandom(id)) : false
      })
    );
  };

  const onOpenClick = () => {
    window.open(
      `${process.env.REACT_APP_USER_APP_URL}/admin/tournaments/tournament/${id}`,
      "_blank"
    );
  };

  const onMenuClick = event => setAnchorEl(event.currentTarget);

  const onMenuClose = () => setAnchorEl(null);

  // const onUnpublishClick = () => {
  //   onMenuClose();
  //   dispatch(
  //     setConfirmationDialog({
  //       open: true,
  //       onClose: () => dispatch(closeConfirmationDialog()),
  //       confirmAction: () => dispatch(postUnpublishTournament(id, t("t:success-messages.unpublish"))),
  //       declineAction: () => dispatch(closeConfirmationDialog()),
  //       title: t("t:tournaments.unpublish-tournament"),
  //       description: t("t:tournaments.unpublish-confirmation-text"),
  //       confirmText: t("t:tournaments.unpublish"),
  //       declineText: t("t:shared.cancel")
  //     })
  //   );
  // };

  const onDeactivateClick = () => {
    onMenuClose();
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: () =>
          dispatch(postDeactivateTournament(id, t("t:success-messages.deactivate"))),
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: t("t:tournaments.deactivate-tournament"),
        description: t("t:tournaments.deactivate-confirmation-text"),
        confirmText: t("t:tournaments.deactivate"),
        declineText: t("t:shared.cancel")
      })
    );
  };

  const onDisputesNumberClick = () => dispatch(setDisputeDialogOpen(true));

  const tournamentControls = () => {
    switch (status) {
      case "not_published":
      case "published":
      case "upcoming":
        return (
          <div className="items-center">
            <DefaultButton variant="contained" classes="green-btn" onClick={onOpenClick}>
              <p className="items-center">
                <img src={OpenIcon} alt="open" className="open-icon" />
                {t("t:shared.open")}
              </p>
            </DefaultButton>
            <IconButton
              src={ActionsIcon}
              onClick={onMenuClick}
              alt="actions"
              classes="primary-color"
            />
          </div>
        );
      case "current":
        return (
          <div className="items-center">
            <DefaultButton variant="outlined" classes="grey-btn" onClick={onDeactivateClick}>
              {t("t:tournaments.deactivate")}
            </DefaultButton>
          </div>
        );
      default:
        return null;
    }
  };

  const tournamentInfo = () => {
    switch (status) {
      case "not_published":
      case "published":
      case "upcoming":
        return (
          <div className="upcoming-tournament-info">
            <div className="game-info">{getGameInfo(game, platform)}</div>
            <div className="users-info">
              <img className="participants-icon" src={ParticipantsIcon} alt="users" />
              <div className="value">
                {min_participants}
                <span className="label lower-case">{t("t:tournaments.min")}</span>
              </div>
              <div className="value">
                {max_participants}
                <span className="label">{t("t:tournaments.max")}</span>
              </div>
              {isPublished && (
                <>
                  <div className={clsx("value clickable")} onClick={onJoinedClick}>
                    {joined_count}
                    <span className="label">{t("t:tournaments.joined")}</span>
                  </div>
                  <div className={clsx("value clickable")} onClick={onWaitlistClick}>
                    {waitlist_count}
                    <span className="label">{t("t:tournaments.waitlist")}</span>
                  </div>
                </>
              )}
            </div>
            <div className="finance-info">
              <div>
                {entry_fee > 0 ? (
                  <div className="value fee">{entry_fee}€</div>
                ) : (
                  <div className="value free">Free</div>
                )}
                <div className="label">{t("t:tournaments.entry-fee")}</div>
              </div>
              <div className="line-separator" />
              <div>
                <div className="value">{system_fee}€</div>
                <div className="label">{t("t:tournaments.system-fee")}</div>
              </div>
              {entry_fee > 0 && (
                <>
                  <div className="line-separator" />
                  <div>
                    <div className="value">{max_prize_pool}€</div>
                    <div className="label">{t("t:tournaments.max-prize-pool")}</div>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      case "current":
      case "completed":
      case "finished":
        return (
          <div className="current-tournament-info">
            <div className="game-info">{getGameInfo(game, platform)}</div>
            <div className="participants-and-disputes">
              <img className="participants-icon" src={ParticipantsIcon} alt="users" />
              <div>
                <div className={clsx("value clickable")} onClick={onParticipantsClick}>
                  {participants_count}
                </div>
                <div className="label">{t("t:tournaments.participants")}</div>
              </div>
              <div className="line-separator" />
              <div className="disputes-number">
                <img className="disputes-icon" src={DisputesIcon} alt="disputes" />
                {disputes_count > 0 && (
                  <span className={clsx("unread", hasUnresolvedDispute && "unresolved")} />
                )}
              </div>
              <div>
                <div
                  className={clsx("value", disputes_count > 0 && "clickable")}
                  onClick={() => disputes_count > 0 && onDisputesNumberClick()}
                >
                  {disputes_count}
                </div>
                <div className="label">{t("t:tournaments.disputes")}</div>
              </div>
            </div>
            <div className="finance-info">
              <div>
                {entry_fee > 0 ? (
                  <div className="value fee">{entry_fee}€</div>
                ) : (
                  <div className="value free">Free</div>
                )}
                <div className="label">{t("t:tournaments.entry-fee")}</div>
              </div>
              <div className="line-separator" />
              <div>
                <div className="value">{system_fee}€</div>
                <div className="label">{t("t:tournaments.system-fee")}</div>
              </div>
            </div>
            <div className="win-amount-info">
              <div>
                <div className="value green">
                  {entry_fee > 0
                    ? (prize_pool - second_place_prize).toFixed(0)
                    : custom_first_place_prize}
                  €
                </div>
                <div className="label">{t("t:tournaments.first-place")}</div>
              </div>
              <div className="line-separator" />
              <div>
                <div className="value">{second_place_prize}€</div>
                <div className="label">{t("t:tournaments.second-place")}</div>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="current-tournament-info">
            <div className="game-info">{getGameInfo(game, platform)}</div>
          </div>
        );
    }
  };

  const onStartClick = async () => {
    onMenuClose();
    await dispatch(postStartTournament(id));
    dispatch(getTournament(id));
  };

  const onStartCheckIn = async () => {
    onMenuClose();
    await dispatch(postStartCheckIn(id));
    dispatch(getTournament(id));
  };

  const onStartWaitlist = async () => {
    onMenuClose();
    await dispatch(postStartWaitlist(id));
    dispatch(getTournament(id));
  };

  return (
    <section className="default-content-wrapper tournament-inner-header">
      <BackLink to={urls.tournamentsUrl} />
      <div className="header-wrapper">
        <div>
          <h1>
            {title}
            {is_test && (
              <>
                <span className="dot-separator">•</span>
                Test
              </>
            )}
          </h1>
          <div className="start-date-and-status">
            <p className="start-date">
              {t("t:tournaments.start-at")} {dayjs(start_at).format("DD.MM.YYYY HH:mm")}
            </p>
            {(status === "upcoming" || status === "published" || status === "not_published") && (
              <>
                <span className="dot-separator">•</span>
                <p className="tournament-status">
                  {(status === "published" || status === "upcoming") && (
                    <img className="check-icon" src={CheckIcon} alt="status" />
                  )}
                  {getTournamentStatus(status, t)}
                </p>
              </>
            )}
          </div>
        </div>
        {tournamentControls()}
      </div>
      {tournamentInfo()}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        classes={{
          paper: "default-popover"
        }}
      >
        <div className="app-menu tournaments-actions">
          {isPublished ? (
            <>
              {/*<button onClick={onUnpublishClick}>*/}
              {/*  <span>{t("t:tournaments.unpublish")}</span>*/}
              {/*  <img src={UnpublishIcon} alt="unpublish" />*/}
              {/*</button>*/}
              {is_test && (
                <>
                  <button onClick={onStartCheckIn}>
                    <span>{t("t:tournaments.start-checkin")}</span>
                    <img src={PlayIcon} alt="play" className="start-icon" />
                  </button>
                  <button onClick={onStartWaitlist}>
                    <span>{t("t:tournaments.start-waitlist")}</span>
                    <img src={PlayIcon} alt="play" className="start-icon" />
                  </button>
                  <button onClick={onStartClick}>
                    <span>{t("t:tournaments.start-playing")}</span>
                    <img src={PlayIcon} alt="play" className="start-icon" />
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              <button
                onClick={() => {
                  onMenuClose();
                  onPublishClick();
                }}
              >
                <span>{t("t:tournaments.publish")}</span>
                <img src={PublishIcon} alt="publish" />
              </button>
              <Link to={urls.editTournamentUrl.replace(":id", id)} onClick={onMenuClose}>
                <span>{t("t:shared.edit")}</span>
                <img src={EditIcon} alt="edit" />
              </Link>
            </>
          )}
          <button onClick={onDeactivateClick}>
            <span>{t("t:tournaments.deactivate")}</span>{" "}
            <img src={DeactivateIcon} alt="deactivate" />
          </button>
        </div>
      </Popover>
    </section>
  );
};

InnerHeader.propTypes = propTypes;
InnerHeader.defaultProps = defaultProps;

export default InnerHeader;
