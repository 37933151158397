import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";

import { postMatchRandomPlay, postMatchWinner } from "../../../../actions";
import DefaultButton from "../../../../../_shared/Buttons/DefaultButton";
import TooltipMessage from "../../../../../_shared/TooltipMessage";
import urls from "../../../../../../core/urls";
import { getPlatformNameAndIcon } from "../../../../../../core/helpers/functions";

import InfoIcon from "../../../../../../assets/image/info.svg";
import ClockIcon from "../../../../../../assets/image/clock-circle.svg";
import ShuffleIcon from "../../../../../../assets/image/shuffle.svg";
import DisputeDetailsIcon from "../../../../../../assets/image/dispute-details.svg";

import "./styles.scss";

const propTypes = {
  state: PropTypes.string.isRequired,
  tournamentID: PropTypes.number.isRequired,
  is_test: PropTypes.bool.isRequired,
  bracket: PropTypes.shape({
    id: PropTypes.number,
    depth: PropTypes.number,
    tournament: PropTypes.number,
    levels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        bracket: PropTypes.number,
        depth_index: PropTypes.number,
        level_index: PropTypes.number,
        matches: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            created_at: PropTypes.string,
            depth_index: PropTypes.number,
            end_at: PropTypes.string,
            group_index: PropTypes.number,
            has_dispute: PropTypes.bool,
            has_winner: PropTypes.bool,
            level: PropTypes.number,
            level_index: PropTypes.number,
            start_at: PropTypes.string,
            winner_at: PropTypes.string,
            winner: PropTypes.number,
            participants: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.number,
                depth_index: PropTypes.number,
                group_index: PropTypes.number,
                index_in_group: PropTypes.number,
                index_in_level: PropTypes.number,
                is_winner: PropTypes.bool,
                level_index: PropTypes.number,
                match: PropTypes.number,
                participant: PropTypes.object
              })
            )
          })
        )
      })
    )
  })
};

const BranchComponent = ({ bracket, state, tournamentID, is_test }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { levels } = bracket;

  const onWinClick = async (matchID, participantID) => {
    await dispatch(postMatchWinner(tournamentID, matchID, participantID));
  };

  const onRandomWinnerClick = async matchID => {
    await dispatch(postMatchRandomPlay(tournamentID, matchID));
  };

  const onViewInDjangoAdminClicked = matchID => {
    window
      .open(
        `https://api.gaming-stars.4-com.pro/admin/tournaments/bracketmatch/?id=${matchID}`,
        "_blank"
      )
      .focus();
  };

  const renderCell = (match, participant) => {
    const { id: matchID, winner, participants } = match;
    const { id: participantID, user } = participant;
    const winButton = state === "playing" && participants?.every(el => !!el.participant) && !winner;
    return (
      <div
        className={clsx("participant-in-branch", winner === participantID && "cell-with-winner")}
      >
        <div className={clsx("participant-info", winButton && "with-button")}>
          <img src={user?.avatar} alt="avatar" className="participant-avatar" />
          <div className="participant-username">
            <p title={user?.username}>{user?.username}</p>
            {getPlatformNameAndIcon(user.platform_username, user.platform_type)}
          </div>
        </div>
        {winButton && (
          <div className="participant-actions">
            <DefaultButton
              variant="outlined"
              onClick={() => onWinClick(matchID, participantID)}
              classes="green-btn branch-win-btn"
            >
              {t("t:tournaments.win")}
            </DefaultButton>
          </div>
        )}
      </div>
    );
  };

  const renderInfoTooltip = (start_at, matchID, end_at, winner, has_dispute, disputeID) => {
    return (
      <div className="match-tooltip-info">
        {!is_test && (
          <div className="time-info">
            <img src={ClockIcon} alt="started at" className="clock-icon" />
            <div className="started-at">{dayjs(start_at).format("HH:mm:ss")}</div>
            {end_at && (
              <div className="going-on">
                {dayjs(dayjs(end_at).diff(dayjs(start_at))).format("mm:ss")}
              </div>
            )}
          </div>
        )}
        {has_dispute && (
          <DefaultButton
            variant="outlined"
            type="link"
            to={urls.tournamentDisputeInnerUrl.replace(":id", disputeID)}
            classes="blue-btn"
          >
            <p className="button-text-with-image">
              <img src={DisputeDetailsIcon} alt="shuffle" />
              {t("t:tournaments.dispute-details")}
            </p>
          </DefaultButton>
        )}
        {state === "playing" && !winner && (
          <DefaultButton
            variant="outlined"
            onClick={() => onRandomWinnerClick(matchID)}
            classes="green-btn"
          >
            <p className="button-text-with-image">
              <img src={ShuffleIcon} alt="shuffle" />
              {t("t:tournaments.random-winner")}
            </p>
          </DefaultButton>
        )}
        <DefaultButton
          variant="outlined"
          onClick={() => onViewInDjangoAdminClicked(matchID)}
          classes="green-btn"
        >
          {t("t:tournaments.view-in-django-admin")}
        </DefaultButton>
      </div>
    );
  };

  return (
    <section className="branch-wrapper">
      {levels.map(({ id: levelID, matches }) => (
        <div className="branch-level" key={`branch-level_${levelID}`}>
          {matches.map(match => {
            const {
              id: matchID,
              participants,
              start_at,
              end_at,
              winner,
              has_dispute,
              has_winner,
              dispute_id
            } = match;
            return (
              <div className="branch-match" key={`branch-match_${matchID}`}>
                {participants.map(({ id: participantID, participant }) => (
                  <div className="branch-cell" key={`branch-cell_${participantID}`}>
                    {participant && renderCell(match, participant)}
                  </div>
                ))}
                {(start_at || (is_test && participants?.every(el => !!el.participant))) && (
                  <div className="match-info">
                    <div className="relative">
                      <TooltipMessage
                        text={renderInfoTooltip(
                          start_at,
                          matchID,
                          end_at,
                          winner,
                          has_dispute,
                          dispute_id
                        )}
                        delay={200}
                        classes="match-info-tooltip"
                        position="right"
                        interactive
                      >
                        <img src={InfoIcon} alt="info" className="info-icon" />
                      </TooltipMessage>
                      {has_dispute && has_winner ? (
                        <div className="resolved-dispute-in-match" />
                      ) : has_dispute ? (
                        <div className="unresolved-dispute-in-match" />
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ))}
    </section>
  );
};

BranchComponent.propTypes = propTypes;

export default BranchComponent;
