import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import urls from "../../../core/urls";
import { tournamentsTabs } from "../../../core/helpers/constants";
import DefaultButton from "../../_shared/Buttons/DefaultButton";
import Pagination from "../../_shared/Pagination";
import Tabs from "../../_shared/Tabs";
import NoItems from "../../_shared/NoItems";
import UsersListDialog from "../../_shared/Dialogs/UsersListDialog";
import { useQueryParams } from "../../../core/helpers/hooks";
import { getTournaments, resetTournaments, setUsersList } from "../actions";

import CurrentTournament from "./TournamentListItems/CurrentTournament";
import UpcomingTournament from "./TournamentListItems/UpcomingTournament";
import InactiveTournament from "./TournamentListItems/InactiveTournament";
import CompletedTournament from "./TournamentListItems/CompletedTournament";

import "./styles.scss";

const TournamentsList = ({
  t,
  tournaments,
  getTournaments,
  history,
  resetTournaments,
  usersList,
  setUsersList,
  successSnackText
}) => {
  const { getParam } = useQueryParams();
  const [page, setPage] = useState(+getParam("page") || 1);
  const [tab, setTab] = useState(getParam("tab") || "current");

  useEffect(
    () => () => {
      resetTournaments();
    },
    []
  );

  useEffect(() => {
    fetchTournaments();
    history.push(`${urls.tournamentsUrl}?tab=${tab}&page=${page}`);
  }, [tab, page]);

  useEffect(() => {
    if (successSnackText) fetchTournaments();
  }, [successSnackText]);

  useEffect(() => {
    if (!usersList.open && usersList.users.length) fetchTournaments();
  }, [usersList]);

  const fetchTournaments = async () => {
    const queries = [`page=${page}`, "page_size=10"];
    const res = await getTournaments(tab, queries);
    if (!res.payload) {
      setPage(1);
    }
  };

  const onTabChange = tabToChange => {
    resetTournaments();
    setTab(tabToChange);
  };

  const renderTournamentInList = ({
    id,
    title,
    start_at,
    game,
    platform,
    entry_fee,
    participants_count,
    status,
    participants,
    waitlist,
    min_participants,
    max_participants,
    waitlist_count,
    joined_count,
    joined,
    winner_1,
    winner_2,
    first_place_prize,
    second_place_prize,
    is_test,
    current_depth
  }) => {
    switch (tab) {
      case "current":
        return (
          <CurrentTournament
            key={id}
            tournamentID={id}
            loading={tournaments.loading}
            name={title}
            startDate={start_at}
            game={game}
            platform={platform}
            entryFee={entry_fee}
            participants={participants}
            participantsCount={participants_count}
            is_test={is_test}
            state={current_depth}
          />
        );
      case "upcoming":
        return (
          <UpcomingTournament
            key={id}
            tournamentID={id}
            loading={tournaments.loading}
            name={title}
            startDate={start_at}
            game={game}
            platform={platform}
            entryFee={entry_fee}
            minParticipants={min_participants}
            maxParticipants={max_participants}
            waitlist={waitlist_count}
            joined={joined_count}
            status={status}
            joinedUsers={joined}
            waitlistUsers={waitlist}
            is_test={is_test}
            fetchTournaments={fetchTournaments}
          />
        );
      case "completed":
        return (
          <CompletedTournament
            key={id}
            tournamentID={id}
            loading={tournaments.loading}
            name={title}
            startDate={start_at}
            game={game}
            platform={platform}
            entryFee={entry_fee}
            participants={participants}
            participantsCount={participants_count}
            winner={winner_1}
            runnerUp={winner_2}
            firstPlacePrize={first_place_prize}
            secondPlacePrize={second_place_prize}
            is_test={is_test}
          />
        );
      case "inactive":
        return (
          <InactiveTournament
            key={id}
            tournamentID={id}
            loading={tournaments.loading}
            name={title}
            startDate={start_at}
            game={game}
            platform={platform}
            entryFee={entry_fee}
            minParticipants={min_participants}
            maxParticipants={max_participants}
            is_test={is_test}
          />
        );
      default:
        return null;
    }
  };

  const onUsersDialogClose = () => {
    setUsersList({ open: false });
  };

  return (
    <div className="default-content-wrapper tournaments-wrapper">
      <div className="title-wrapper">
        <h1>{t("t:shared.tournaments")}</h1>
        <DefaultButton
          variant="contained"
          type="link"
          to={urls.addNewTournament}
          classes="green-btn"
        >
          + {t("t:tournaments.add-new")}
        </DefaultButton>
      </div>
      <Tabs
        currentTab={tab}
        tabs={tournamentsTabs(t)}
        onTabChange={onTabChange}
        loading={tournaments.loading}
      />
      {tournaments.count > 0 ? (
        tournaments.results.map(el => renderTournamentInList(el))
      ) : (
        <NoItems />
      )}
      {tournaments.count > 0 && (
        <Pagination onChange={e => setPage(e)} total={tournaments.count} page={page} />
      )}
      <UsersListDialog onClose={onUsersDialogClose} usersList={usersList} />
    </div>
  );
};

const mapStateToProps = ({ app, tournaments }) => {
  return {
    tournaments: tournaments.tournamentsList,
    loading: app.loading,
    successSnackText: app.successSnackText,
    usersList: tournaments.usersList
  };
};

const mapDispatchToProps = {
  getTournaments,
  resetTournaments,
  setUsersList
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TournamentsList));
