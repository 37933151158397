import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { useQueryParams } from "../../core/helpers/hooks";
import urls from "../../core/urls";
import Tabs from "../_shared/Tabs";
import { skillChallengesTabs } from "./constants";
import { resetSkillChallenges } from "./actions";
import Connection from "./Connection";
import Matches from "./Matches";
import Statistics from "./Statistics";

import "./styles.scss";

const SkillChallenges = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { getParam, queries } = useQueryParams();
  const [tab, setTab] = useState(getParam("tab"));

  const onTabChange = newTab => history.push(`${urls.skillChallengesUrl}?tab=${newTab}`);

  useEffect(
    () => () => {
      dispatch(resetSkillChallenges());
    },
    []
  );

  useEffect(() => {
    if (tab !== getParam("tab")) {
      setTab(getParam("tab"));
    }
  }, [queries]);

  const tabContent = useMemo(() => {
    switch (tab) {
      case "connection":
        return <Connection />;
      case "matches":
        return <Matches />;
      case "statistics":
        return <Statistics />;
      default:
        return null;
    }
  }, [tab]);

  return (
    <div className="default-content-wrapper skill-challenges">
      <h1>Skill Challenges</h1>
      <Tabs currentTab={tab} tabs={skillChallengesTabs} onTabChange={onTabChange} />
      {tabContent}
    </div>
  );
};

export default SkillChallenges;
