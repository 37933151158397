import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  getTournament,
  postPublishTournament,
  resetTournamentInfo,
  setDisputeDialogOpen,
  setUsersList
} from "../actions";
import { closeConfirmationDialog, setConfirmationDialog } from "../../../core/appActions";

import BackDrop from "../../_shared/BackDrop";
import UsersListDialog from "../../_shared/Dialogs/UsersListDialog";
import InnerHeader from "./InnerBlocks/InnerHeader";
import TournamentBranch from "./InnerBlocks/TournamentBranch";
import Dialog from "../../_shared/Dialog";
import DisputesDialog from "./DisputesDialog";

import "./styles.scss";

const TournamentInner = ({ match, tournament, getTournament, resetTournamentInfo, usersList }) => {
  const { t } = useTranslation();
  const { successSnackText, buttonLoading } = useSelector(({ app }) => app);
  const disputesDialogOpen = useSelector(({ tournaments }) => tournaments.disputesDialogOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    getTournament(match.params.id);
    return () => {
      resetTournamentInfo();
      dispatch(setDisputeDialogOpen(false));
    };
  }, []);

  useEffect(() => {
    if (successSnackText) {
      getTournament(match.params.id);
    }
  }, [successSnackText]);

  const onPublishClick = () => {
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: () =>
          dispatch(postPublishTournament(match.params.id, t("t:success-messages.publish"))),
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: t("t:tournaments.publish-tournament"),
        description: t("t:tournaments.publish-confirmation-text"),
        confirmText: t("t:tournaments.publish"),
        declineText: t("t:shared.cancel")
      })
    );
  };

  const onUsersDialogClose = () => dispatch(setUsersList({ open: false }));
  const onDisputesDialogClose = () => dispatch(setDisputeDialogOpen(false));

  return (
    <>
      <InnerHeader {...tournament} onPublishClick={onPublishClick} />
      {tournament.status === "not_published" ? (
        <section className="default-content-wrapper not-published">
          <div className="title">{t("t:tournaments.not-published")}</div>
          <div className="description">
            <span className="text-hover clickable" onClick={onPublishClick}>
              {t("t:tournaments.publish")}&nbsp;
            </span>
            <p>{t("t:tournaments.this-tournament-to-allow-users-to-join")}</p>
          </div>
        </section>
      ) : (
        <TournamentBranch
          bracket={tournament.bracket}
          is_test={tournament.is_test}
          tournamentID={+match.params.id}
          state={tournament.state}
        />
      )}
      <UsersListDialog onClose={onUsersDialogClose} usersList={usersList} />
      <BackDrop open={tournament.loading || buttonLoading} />
      <Dialog open={disputesDialogOpen} onClose={onDisputesDialogClose}>
        <DisputesDialog tournamentID={match.params.id} />
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ tournaments }) => {
  return {
    tournament: tournaments.tournament,
    usersList: tournaments.usersList
  };
};

const mapDispatchToProps = {
  getTournament,
  resetTournamentInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentInner);
