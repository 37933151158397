import SKILL_CHALLENGES from "./actionTypes";
import { joinQueries } from "../../core/helpers/functions";

export function getSkillChallengesConnection(queries) {
  return {
    type: SKILL_CHALLENGES.GET_SKILL_CHALLENGES_CONNECTION,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/skill-challenges/connections/${joinQueries(queries)}`,
        method: "get"
      }
    }
  };
}

export function deleteSkillChallengesConnection(id) {
  return {
    type: SKILL_CHALLENGES.DELETE_SKILL_CHALLENGES_CONNECTION,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/skill-challenges/connection/${id}`,
        method: "delete"
      }
    }
  };
}

export function getSkillChallengesMatches(queries) {
  return {
    type: SKILL_CHALLENGES.GET_SKILL_CHALLENGES_MATCHES,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/skill-challenges/matches/${joinQueries(queries)}`,
        method: "get"
      }
    }
  };
}

export function postSkillChallengesMatchWin(id) {
  return {
    type: SKILL_CHALLENGES.POST_SKILL_CHALLENGES_MATCH_WIN,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/skill-challenges/match/${id}/set-win/`,
        method: "post"
      }
    }
  };
}

export function postSkillChallengesMatchLoss(id) {
  return {
    type: SKILL_CHALLENGES.POST_SKILL_CHALLENGES_MATCH_LOSS,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/skill-challenges/match/${id}/set-loss/`,
        method: "post"
      }
    }
  };
}

export function getSkillChallengesStatistics(queries) {
  return {
    type: SKILL_CHALLENGES.GET_SKILL_CHALLENGES_STATISTICS,
    payload: {
      client: "default",
      request: {
        url: `/api/admin/skill-challenges/statistics/${joinQueries(queries)}`,
        method: "get"
      }
    }
  };
}

export function setConnectionMeta(connectionMeta) {
  return {
    type: SKILL_CHALLENGES.SET_CONNECTION_META,
    connectionMeta
  };
}

export function setMatchesMeta(matchesMeta) {
  return {
    type: SKILL_CHALLENGES.SET_MATCHES_META,
    matchesMeta
  };
}

export function setStatisticsMeta(statisticsMeta) {
  return {
    type: SKILL_CHALLENGES.SET_STATISTICS_META,
    statisticsMeta
  };
}

export function resetSkillChallenges() {
  return {
    type: SKILL_CHALLENGES.RESET_SKILL_CHALLENGES
  };
}
