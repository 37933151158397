import CHALLENGES_SYSTEM from "./actionTypes";

const INITIAL_STATE = {
  challenges: [],
  challengeToDelete: null,
  isLoading: true,
  challenge: { initial: true },
  rewardInfo: []
};

export default function(state = INITIAL_STATE, { type, payload, challengeToDelete }) {
  switch (type) {
    case CHALLENGES_SYSTEM.GET_CHALLENGES:
      return {
        ...state,
        challenges: [],
        isLoading: true
      };
    case CHALLENGES_SYSTEM.GET_CHALLENGES_SUCCESS:
      return {
        ...state,
        challenges: payload.data,
        isLoading: false
      };
    case CHALLENGES_SYSTEM.GET_CHALLENGE_SUCCESS:
      return {
        ...state,
        challenge: payload.data
      };
    case CHALLENGES_SYSTEM.DELETE_CHALLENGE:
      return {
        ...state,
        challengeToDelete
      };
    case CHALLENGES_SYSTEM.DELETE_CHALLENGE_SUCCESS:
      return {
        ...state,
        challenges: state.challenges.filter(el => el.id !== state.challengeToDelete),
        challengeToDelete: null
      };
    case CHALLENGES_SYSTEM.PATCH_CHALLENGE_SUCCESS:
      if (state.challenges.length) {
        return {
          ...state,
          challenges: state.challenges
            .map(el => {
              if (el.id !== payload.data.id) return el;
              return {
                ...el,
                ...payload.data
              };
            })
            .sort((a, b) => a.position - b.position)
        };
      }
      return state;

    case CHALLENGES_SYSTEM.GET_REWARD_SUCCESS:
      return {
        ...state,
        rewardInfo: payload.data
      };

    case CHALLENGES_SYSTEM.PATCH_REWARD_SUCCESS:
      return {
        ...state,
        rewardInfo: []
      };

    case CHALLENGES_SYSTEM.RESET_CHALLENGES_SYSTEM:
      return INITIAL_STATE;
    default:
      return state;
  }
}
