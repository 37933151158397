import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import BackIcon from "../../../assets/image/back-arrow.svg";

import "./styles.scss";

const propTypes = {
  to: PropTypes.string.isRequired,
  hard: PropTypes.bool
};

const defaultProps = {
  hard: false
};

const BackLink = ({ to, hard }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = () => {
    if (hard) {
      history.push(to);
    } else {
      history.length > 1 ? history.goBack() : history.push(to);
    }
  };

  return (
    <div onClick={handleClick} className="back-link text-hover">
      <img className="arrow-back" src={BackIcon} alt="back link" />
      {t("t:shared.back")}
    </div>
  );
};

BackLink.propTypes = propTypes;
BackLink.defaultProps = defaultProps;

export default BackLink;
