import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import _ from "lodash";

import IconButtonComponent from "../../../_shared/Buttons/IconButton";

import AddIcon from "../../../../assets/image/add-balance.svg";
import DefaultButton from "../../../_shared/Buttons/DefaultButton";
import Dialog from "../../../_shared/Dialog";
import InputField from "../../../_shared/InputField";
import { postAddBalance, postAddBonusCash } from "../../actions";
import { handleValidationErrors } from "../../../../core/helpers/functions";

const Balance = ({ openedDialog, setOpenedDialog }) => {
  const dispatch = useDispatch();
  const { id: userID, balance, bonus_cash_balance } = useSelector(
    ({ userProfile }) => userProfile.general
  );
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        amount: yup.number().required(),
        comment: yup.string()
      })
    ),
    defaultValues: {
      amount: "",
      comment: ""
    },
    shouldFocusError: true
  });

  const onAddBalanceClick = () => setOpenedDialog("add-balance");
  const onAddBonusCashClick = () => setOpenedDialog("add-bonus-cash");
  const onDialogClose = () => setOpenedDialog(null);

  const onSubmit = async data => {
    let res;
    res = await dispatch(
      openedDialog === "add-balance" ? postAddBalance(userID, data) : postAddBonusCash(userID, data)
    );
    if (res.error) {
      handleValidationErrors(res, setError);
    } else {
      onDialogClose();
    }
  };

  return (
    <div className="user-profile__general--block">
      <div className="user-profile__general--subtitle">Balance</div>
      <div className="user-profile__general--balance">
        <div className="flex-center">
          <p className="user-profile__general--balance-label">Balance:</p>
          <div className="flex-center">
            <span className="user-profile__general--balance-value">{`${balance} €`}</span>
            <IconButtonComponent onClick={onAddBalanceClick} alt="add" src={AddIcon} />
          </div>
        </div>
        <div className="flex-center">
          <p className="user-profile__general--balance-label">Bonus Cash:</p>
          <div className="flex-center">
            <span className="user-profile__general--balance-value">{bonus_cash_balance}</span>
            <IconButtonComponent onClick={onAddBonusCashClick} alt="add" src={AddIcon} />
          </div>
        </div>
      </div>
      <Dialog open={Boolean(openedDialog)} onClose={onDialogClose}>
        <form className="confirmation-dialog" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title initial-text">{`Send personal ${
            openedDialog === "add-balance" ? "money bonus" : "bonus cash"
          }`}</h2>
          <div className="description">Enter the amount you want to send to this user</div>
          <div className="user-profile__general--balance-amount-wrapper">
            <Controller
              name="amount"
              control={control}
              render={props => (
                <InputField
                  id="amount-input"
                  label="Amount"
                  type="number"
                  {..._.omit(props.field, "ref")}
                  error={errors.amount?.message}
                  endAdornment={openedDialog === "add-balance" && <span className="euro">€</span>}
                />
              )}
            />
          </div>
          <Controller
            name="comment"
            control={control}
            render={props => (
              <InputField
                id="comment-input"
                label="Comment"
                type="text"
                multiline
                {..._.omit(props.field, "ref")}
                error={errors.comment?.message}
              />
            )}
          />
          <hr className="separator" />
          <div className="buttons-wrapper">
            <DefaultButton variant="contained" onClick={onDialogClose} classes="grey-btn">
              Cancel
            </DefaultButton>
            <DefaultButton
              variant="contained"
              formAction
              classes="green-btn"
              loading={buttonLoading}
            >
              Send
            </DefaultButton>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default Balance;
