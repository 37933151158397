const skillChallengesTabs = [
  { label: "Connection", value: "connection" },
  { label: "Matches", value: "matches" },
  { label: "Statistics", value: "statistics" }
];

const connectionTableCols = [
  { label: "User", value: "user" },
  { label: "Game", value: "game" },
  { label: "Username", value: "username" },
  { label: "Status", value: "status" }
];

const connectionInitialFilters = {
  game: "",
  status: ""
};

const matchesTableCols = connectionTableCols;

const matchesInitialFilters = {
  game: "",
  status: "",
  bet_type: "",
  loss_details: ""
};

const statisticsTableCols = [
  { label: "Date", value: "date" },
  { label: "Games count", value: "games_count" },
  { label: "Win count", value: "win_count" },
  { label: "Loss count", value: "loss_count" },
  { label: "Total bets", value: "bets_amount_sum" },
  { label: "Total payouts", value: "payout_amount_sum" },
  { label: "Total bets minus payouts", value: "bets_minus_payout_sum" }
];

export {
  skillChallengesTabs,
  connectionTableCols,
  connectionInitialFilters,
  matchesTableCols,
  matchesInitialFilters,
  statisticsTableCols
};
