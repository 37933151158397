import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import UserFields from "./UserFields";
import SocialAccounts from "./SocialAccounts";
import Role from "./Role";
import Balance from "./Balance";
import Settings from "./Settings";

import userProfileSchema from "./UserFields/userProfileSchema";
import { handleValidationErrors, validateImage } from "../../../core/helpers/functions";
import notifyError from "../../../core/helpers/notifyError";
import { patchProfileGeneral } from "../actions";
import DefaultButton from "../../_shared/Buttons/DefaultButton";

const General = () => {
  const [previewAvatar, setPreviewAvatar] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [openedDialog, setOpenedDialog] = useState(null);
  const inputRef = useRef();
  const dispatch = useDispatch();
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);
  const {
    id: userID,
    avatar: userAvatar,
    username,
    first_name,
    last_name,
    email,
    phone,
    is_active,
    is_admin,
    is_agent,
    is_support,
    is_blocked,
    receive_push_notifications,
    receive_special_offers,
    can_have_unlimited_invitees,
    show_in_leaderboard,
    add_opponents_to_friends
  } = useSelector(({ userProfile }) => userProfile.general);

  useEffect(() => {
    if (userAvatar) setPreviewAvatar(userAvatar);
  }, [userAvatar]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(userProfileSchema),
    defaultValues: {
      username: username || "",
      first_name: first_name || "",
      last_name: last_name || "",
      email: email || "",
      phone: phone || "",
      is_active,
      is_admin,
      is_agent,
      is_support,
      is_blocked,
      receive_push_notifications,
      receive_special_offers,
      can_have_unlimited_invitees,
      show_in_leaderboard,
      add_opponents_to_friends
    },
    shouldFocusError: true
  });

  const onUploadClick = () => inputRef?.current.click();

  const handleFile = e => {
    e.persist();
    const file = e.target.files[0];
    if (file) {
      if (validateImage(file)) {
        const newUrl = URL.createObjectURL(file);
        setPreviewAvatar(newUrl.toString());
        setAvatar(file);
      } else {
        notifyError("File type is not supported");
      }
    }
  };

  const onDeleteClick = () => {
    setPreviewAvatar(null);
    setAvatar(null);
    inputRef.current.value = "";
  };

  const prepareDataForSubmit = data => {
    const formData = new FormData();
    Object.entries(data).map(field => formData.append(field[0], field[1]));
    if (avatar) formData.append("avatar", avatar);
    return formData;
  };

  const onSubmit = async data => {
    if (!openedDialog) {
      const res = await dispatch(patchProfileGeneral(userID, prepareDataForSubmit(data)));
      if (res.error) {
        handleValidationErrors(res, setError);
      }
    }
  };

  return (
    <form
      className="user-profile__content-wrapper user-profile__general"
      onSubmit={handleSubmit(onSubmit)}
    >
      <UserFields
        inputRef={inputRef}
        handleFile={handleFile}
        previewAvatar={previewAvatar}
        onUploadClick={onUploadClick}
        onDeleteClick={onDeleteClick}
        control={control}
        errors={errors}
      />
      <SocialAccounts />
      <Role control={control} />
      <Balance openedDialog={openedDialog} setOpenedDialog={setOpenedDialog} />
      <Settings control={control} />
      <div className="user-profile__general--save-btn">
        <DefaultButton
          variant="contained"
          classes="green-btn"
          loading={buttonLoading}
          formAction
          disabled={!previewAvatar}
        >
          Save changes
        </DefaultButton>
      </div>
    </form>
  );
};

export default General;
