import React from "react";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";

import IconButtonComponent from "../Buttons/IconButton";

import CloseDialogIcon from "../../../assets/image/close.svg";

import "./styles.scss";

const propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

const DialogComponent = ({ open, onClose, children }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        root: "default_dialog_root",
        paper: "paper_custom_dialog"
      }}
    >
      <div className="dialog_wrapper">
        <IconButtonComponent
          src={CloseDialogIcon}
          onClick={onClose}
          alt="close"
          classes="dialog_close_button"
        />
        {children}
      </div>
    </Dialog>
  );
};

DialogComponent.propTypes = propTypes;

export default DialogComponent;
