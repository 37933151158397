const challengesSystemTabs = [
  { label: "Daily champion", value: "daily" },
  { label: "1 vs 1", value: "1vs1/challenge" },
  { label: "Skill challenges", value: "skill/challenge" }
];

const dailyChampionTabs = [
  { label: "All", value: "" },
  { label: "1vs1 - Bonus Cash", value: "bonus_cash" },
  { label: "1vs1 - Euro", value: "real_money" },
  { label: "Skill challenges - Bonus Cash", value: "challenges_bc" },
  { label: "Skill challenges - Euro", value: "challenges" }
];

const dailyGamemodes = [
  { label: "1vs1 - Bonus Cash", value: "bonus_cash" },
  { label: "1vs1 - Euro", value: "real_money" },
  { label: "Skill challenges - Bonus Cash", value: "challenges_bc" },
  { label: "Skill challenges - Euro", value: "challenges" }
];

const dailyCountBy = [
  { label: "Amount of games", value: "game" },
  { label: "Amount of euros", value: "money" },
  { label: "Amount of bonus cash", value: "bonus_cash" }
];

const challengesRanks = [
  { label: "No rank", value: "no_rank" },
  { label: "Bronze", value: "bronze" },
  { label: "Silver", value: "silver" },
  { label: "Gold", value: "gold" }
];

const dailyChampionTableCols = [
  { label: "Challenge", value: "name" },
  { label: "Gamemode", value: "game_mode" },
  { label: "Status", value: "status" }
];

const challengesTableCols = [
  { label: "Challenge", value: "name" },
  { label: "Progress bar", value: "progress_bar" },
  { label: "Status", value: "status" }
];

const dailyTemplates = ["played", "win_count", "win_in_a_row_count", "win_money", "bet"];

const oneVsOneTemplates = [
  "active",
  "add_friend",
  "connect_platform",
  "invite_friend",
  "kyc_verified",
  "win_friend",
  "win_random",
  "win",
  "play_different",
  "total_bets",
  "win_amount",
  "win_different_min_bet",
  "win_in_a_row_different_min_bet",
  "win_in_a_row_different",
  "total_deposit",
  "play_min_bet",
  "leaderboard"
];

const skillChallengesTemplates = [
  "connect_account",
  "play_min_bet1",
  "win_min_bet1",
  "play_min_bet5",
  "win_min_bet5",
  "profit",
  "win_in_a_row_min_bet5"
];

const rewardOptions = [
  { label: "Euro", value: "euro" },
  { label: "Bonus Cash", value: "bonus_cash" },
  { label: "Euro + Bonus Cash", value: "euro_+_bonus_cash" }
];

export {
  challengesSystemTabs,
  dailyChampionTabs,
  dailyGamemodes,
  dailyCountBy,
  challengesRanks,
  dailyChampionTableCols,
  challengesTableCols,
  dailyTemplates,
  oneVsOneTemplates,
  skillChallengesTemplates,
  rewardOptions
};
