import React from "react";

import CheckedIcon from "../../assets/image/checkmark.svg";

const translateChallengeStatus = (isActive, isOngoing) => {
  if (isActive && isOngoing) {
    return <div className="challenges-system__challenge-row--status active">Active today</div>;
  }
  if (isActive) {
    return (
      <div className="challenges-system__challenge-row--status published">
        <img
          src={CheckedIcon}
          alt="check"
          className="challenges-system__challenge-row--checkmark"
        />
        Published
      </div>
    );
  }
  return <div className="challenges-system__challenge-row--status unpublished">Not published</div>;
};

const translateRank = (rank, t) => t(`t:challenges.${rank}`);

const translateGameType = (type, t) => t(`t:challenges.${type}`);

const translateDailyChampionTemplate = (template, t) =>
  t(`t:challenges.${template}--daily-template`);
const translateOneVsOneTemplate = (template, amount, t) =>
  t(`t:challenges.${template}--one-vs-one-template`, { amount });
const translateSkillChallengesTemplate = (template, amount, t) =>
  t(`t:challenges.${template}--challenges-template`, { amount });

const getChallengeTypeByPathname = pathname => {
  if (pathname.includes("/daily/")) {
    return "daily";
  }
  if (pathname.includes("/skill/")) {
    return "skill/challenge";
  }
  return "1vs1/challenge";
};

export {
  translateChallengeStatus,
  translateRank,
  translateGameType,
  translateDailyChampionTemplate,
  translateOneVsOneTemplate,
  translateSkillChallengesTemplate,
  getChallengeTypeByPathname
};
