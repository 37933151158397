const USER_PROFILE = {
  GET_PROFILE_GENERAL: "GET_PROFILE_GENERAL",
  GET_PROFILE_GENERAL_SUCCESS: "GET_PROFILE_GENERAL_SUCCESS",
  GET_PROFILE_GENERAL_FAIL: "GET_PROFILE_GENERAL_FAIL",

  PATCH_PROFILE_GENERAL: "PATCH_PROFILE_GENERAL",
  PATCH_PROFILE_GENERAL_SUCCESS: "PATCH_PROFILE_GENERAL_SUCCESS",
  PATCH_PROFILE_GENERAL_FAIL: "PATCH_PROFILE_GENERAL_FAIL",

  POST_ADD_BALANCE: "POST_ADD_BALANCE",
  POST_ADD_BALANCE_SUCCESS: "POST_ADD_BALANCE_SUCCESS",
  POST_ADD_BALANCE_FAIL: "POST_ADD_BALANCE_FAIL",

  POST_ADD_BONUS_CASH: "POST_ADD_BONUS_CASH",
  POST_ADD_BONUS_CASH_SUCCESS: "POST_ADD_BONUS_CASH_SUCCESS",
  POST_ADD_BONUS_CASH_FAIL: "POST_ADD_BONUS_CASH_FAIL",

  GET_PROFILE_PLATFORMS: "GET_PROFILE_PLATFORMS",
  GET_PROFILE_PLATFORMS_SUCCESS: "GET_PROFILE_PLATFORMS_SUCCESS",
  GET_PROFILE_PLATFORMS_FAIL: "GET_PROFILE_PLATFORMS_FAIL",

  PATCH_PROFILE_PLATFORM: "PATCH_PROFILE_PLATFORM",
  PATCH_PROFILE_PLATFORM_SUCCESS: "PATCH_PROFILE_PLATFORM_SUCCESS",
  PATCH_PROFILE_PLATFORM_FAIL: "PATCH_PROFILE_PLATFORM_FAIL",

  POST_DISCONNECT_PROFILE_PLATFORM: "POST_DISCONNECT_PROFILE_PLATFORM",
  POST_DISCONNECT_PROFILE_PLATFORM_SUCCESS: "POST_DISCONNECT_PROFILE_PLATFORM_SUCCESS",
  POST_DISCONNECT_PROFILE_PLATFORM_FAIL: "POST_DISCONNECT_PROFILE_PLATFORM_FAIL",

  GET_PROFILE_AFFILIATE: "GET_PROFILE_AFFILIATE",
  GET_PROFILE_AFFILIATE_SUCCESS: "GET_PROFILE_AFFILIATE_SUCCESS",
  GET_PROFILE_AFFILIATE_FAIL: "GET_PROFILE_AFFILIATE_FAIL",

  PATCH_PROFILE_AFFILIATE: "PATCH_PROFILE_AFFILIATE",
  PATCH_PROFILE_AFFILIATE_SUCCESS: "PATCH_PROFILE_AFFILIATE_SUCCESS",
  PATCH_PROFILE_AFFILIATE_FAIL: "PATCH_PROFILE_AFFILIATE_FAIL",

  GET_PROFILE_KYC: "GET_PROFILE_KYC",
  GET_PROFILE_KYC_SUCCESS: "GET_PROFILE_KYC_SUCCESS",
  GET_PROFILE_KYC_FAIL: "GET_PROFILE_KYC_FAIL",

  PATCH_PROFILE_KYC: "PATCH_PROFILE_KYC",
  PATCH_PROFILE_KYC_SUCCESS: "PATCH_PROFILE_KYC_SUCCESS",
  PATCH_PROFILE_KYC_FAIL: "PATCH_PROFILE_KYC_FAIL",

  GET_PROFILE_SKILL_CHALLENGES: "GET_PROFILE_SKILL_CHALLENGES",
  GET_PROFILE_SKILL_CHALLENGES_SUCCESS: "GET_PROFILE_SKILL_CHALLENGES_SUCCESS",
  GET_PROFILE_SKILL_CHALLENGES_FAIL: "GET_PROFILE_SKILL_CHALLENGES_FAIL",

  PATCH_PROFILE_SKILL_CHALLENGES: "PATCH_PROFILE_SKILL_CHALLENGES",
  PATCH_PROFILE_SKILL_CHALLENGES_SUCCESS: "PATCH_PROFILE_SKILL_CHALLENGES_SUCCESS",
  PATCH_PROFILE_SKILL_CHALLENGES_FAIL: "PATCH_PROFILE_SKILL_CHALLENGES_FAIL",

  GET_PROFILE_DISPUTES: "GET_PROFILE_DISPUTES",
  GET_PROFILE_DISPUTES_SUCCESS: "GET_PROFILE_DISPUTES_SUCCESS",
  GET_PROFILE_DISPUTES_FAIL: "GET_PROFILE_DISPUTES_FAIL",

  RESET_USER: "RESET_USER"
};

export default USER_PROFILE;
