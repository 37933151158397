import AUTH from "./actionTypes";

export function postLogin(data) {
  return {
    type: AUTH.LOGIN,
    payload: {
      client: "default",
      request: {
        url: "/api/admin/auth/signin/",
        method: "post",
        data
      }
    }
  };
}
