import React, { memo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import Popover from "@material-ui/core/Popover";
import clsx from "clsx";

import { getGameInfo, getTournamentStatus } from "../../../../../core/helpers/functions";
import urls from "../../../../../core/urls";
import Skeleton from "../../../../_shared/Skeleton";
import IconButton from "../../../../_shared/Buttons/IconButton";

import {
  setUsersList,
  // postUnpublishTournament,
  postDeactivateTournament,
  postPublishTournament,
  postJoinedRemove,
  postWaitlistRemove
} from "../../../actions";
import { setConfirmationDialog, closeConfirmationDialog } from "../../../../../core/appActions";

import ParticipantsIcon from "../../../../../assets/image/participants.svg";
import ActionsIcon from "../../../../../assets/image/three-vertical-dots.svg";
import CheckIcon from "../../../../../assets/image/checkmark.svg";
import OpenIcon from "../../../../../assets/image/open.svg";
// import UnpublishIcon from "../../../../../assets/image/unpublish.svg";
import PublishIcon from "../../../../../assets/image/publish.svg";
import DeactivateIcon from "../../../../../assets/image/deactivate.svg";
import EditIcon from "../../../../../assets/image/edit.svg";

import "./styles.scss";

const defaultProps = {
  loading: true,
  name: "",
  startDate: dayjs().add(1, "day"),
  game: 8,
  platform: 1,
  entryFee: null,
  minParticipants: null,
  maxParticipants: null,
  joined: null,
  waitlist: null,
  tournamentID: null,
  joinedUsers: [],
  waitlistUsers: [],
  status: "",
  is_test: false,
  fetchTournaments: () => {}
};

const UpcomingTournament = ({
  loading,
  tournamentID,
  name,
  startDate,
  game,
  platform,
  entryFee,
  minParticipants,
  maxParticipants,
  joined,
  waitlist,
  status,
  joinedUsers,
  waitlistUsers,
  is_test,
  t,
  fetchTournaments
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();

  const onJoinedClick = () => {
    dispatch(
      setUsersList({
        open: true,
        title: t("t:tournaments.joined-users"),
        numberOfUsers: joined,
        users: joinedUsers,
        removeAction: is_test ? userId => dispatch(postJoinedRemove(tournamentID, userId)) : false
      })
    );
  };

  const onWaitlistClick = () => {
    dispatch(
      setUsersList({
        open: true,
        title: t("t:tournaments.waitlist"),
        numberOfUsers: waitlist,
        users: waitlistUsers,
        removeAction: is_test ? userId => dispatch(postWaitlistRemove(tournamentID, userId)) : false
      })
    );
  };

  const onMenuClick = event => setAnchorEl(event.currentTarget);

  const onMenuClose = () => setAnchorEl(null);

  const onPublishClick = () => {
    onMenuClose();
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: () =>
          dispatch(postPublishTournament(tournamentID, t("t:success-messages.publish"))),
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: t("t:tournaments.publish-tournament"),
        description: t("t:tournaments.publish-confirmation-text"),
        confirmText: t("t:tournaments.publish"),
        declineText: t("t:shared.cancel")
      })
    );
  };

  // const onUnpublishClick = () => {
  //   onMenuClose();
  //   dispatch(
  //     setConfirmationDialog({
  //       open: true,
  //       onClose: () => dispatch(closeConfirmationDialog()),
  //       confirmAction: () => dispatch(postUnpublishTournament(tournamentID, t("t:success-messages.unpublish"))),
  //       declineAction: () => dispatch(closeConfirmationDialog()),
  //       title: t("t:tournaments.unpublish-tournament"),
  //       description: t("t:tournaments.unpublish-confirmation-text"),
  //       confirmText: t("t:tournaments.unpublish"),
  //       declineText: t("t:shared.cancel")
  //     })
  //   );
  // };

  const onDeactivateClick = () => {
    onMenuClose();
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: async () => {
          await dispatch(
            postDeactivateTournament(tournamentID, t("t:success-messages.deactivate"))
          );
          fetchTournaments();
        },
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: t("t:tournaments.deactivate-tournament"),
        description: t("t:tournaments.deactivate-confirmation-text"),
        confirmText: t("t:tournaments.deactivate"),
        declineText: t("t:shared.cancel")
      })
    );
  };

  const onOpenClick = () => {
    window.open(
      `${process.env.REACT_APP_USER_APP_URL}/admin/tournaments/tournament/${tournamentID}`,
      "_blank"
    );
  };

  return (
    <div className="upcoming-tournament-in-list">
      <div className="first-block">
        <Skeleton loading={loading} variant="text" width={200}>
          <Link to={urls.tournamentUrl.replace(":id", tournamentID)} className="name text-hover">
            <p className="text-overflow">{name}</p>
            {is_test && (
              <>
                <span className="dot-separator">•</span>
                Test
              </>
            )}
          </Link>
        </Skeleton>
        <Skeleton loading={loading} variant="text" width={180}>
          <div className="start-date">
            {t("t:tournaments.start-at")} {dayjs(startDate).format("DD.MM.YYYY HH:mm")}
          </div>
        </Skeleton>
      </div>
      <div className="second-block">{getGameInfo(game, platform, loading)}</div>
      <div className="third-block">
        <Skeleton loading={loading} variant="text" width={25}>
          {entryFee > 0 ? <div className="fee">{entryFee}€</div> : <div className="free">Free</div>}
        </Skeleton>
        <Skeleton loading={loading} variant="text" width={50}>
          <div className="label lower-case">{t("t:tournaments.entry-fee")}</div>
        </Skeleton>
      </div>
      <div className="fourth-block">
        <Skeleton loading={loading} variant="circle" width={34} height={34}>
          <img className="participants-icon" src={ParticipantsIcon} alt="users" />
        </Skeleton>
        <div className="participants">
          <Skeleton loading={loading} variant="text" width={150}>
            <div className="values-wrapper">
              <div className="value">
                {minParticipants}
                <span className="label lower-case">{t("t:tournaments.min")}</span>
              </div>
              <div className="value">
                {maxParticipants}
                <span className="label">{t("t:tournaments.max")}</span>
              </div>
              <div
                className={clsx("value", joined > 0 ? "clickable" : "inactive")}
                onClick={onJoinedClick}
              >
                {joined}
                <span className="label">{t("t:tournaments.joined")}</span>
              </div>
              <div
                className={clsx("value", waitlist > 0 ? "clickable" : "inactive")}
                onClick={onWaitlistClick}
              >
                {waitlist}
                <span className="label">{t("t:tournaments.waitlist")}</span>
              </div>
            </div>
          </Skeleton>
          <Skeleton loading={loading} variant="text" width={170}>
            <div />
          </Skeleton>
        </div>
      </div>
      <div className="fifth-block">
        <Skeleton
          loading={loading}
          variant="rect"
          height={36}
          width={120}
          classes="border-radius-22"
        >
          <div className="tournament-status">
            {(status === "published" || status === "upcoming") && (
              <img className="check-icon" src={CheckIcon} alt="status" />
            )}
            {getTournamentStatus(status, t)}
          </div>
        </Skeleton>
      </div>
      <div className="last-block">
        <Skeleton loading={loading} variant="circle" width={34} height={34}>
          <IconButton
            src={ActionsIcon}
            onClick={onMenuClick}
            alt="actions"
            classes="primary-color"
          />
        </Skeleton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={onMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          classes={{
            paper: "default-popover"
          }}
        >
          <div className="app-menu tournaments-actions">
            {/*(*/}
            {/*<button onClick={onUnpublishClick}>*/}
            {/*  <span>{t("t:tournaments.unpublish")}</span>*/}
            {/*  <img src={UnpublishIcon} alt="unpublish" />*/}
            {/*</button>*/}
            {/*)*/}
            {status === "not_published" && (
              <>
                <button onClick={onPublishClick}>
                  <span>{t("t:tournaments.publish")}</span>
                  <img src={PublishIcon} alt="publish" />
                </button>
                <Link
                  to={urls.editTournamentUrl.replace(":id", tournamentID)}
                  onClick={onMenuClose}
                >
                  <span>{t("t:shared.edit")}</span>
                  <img src={EditIcon} alt="edit" />
                </Link>
              </>
            )}
            <button onClick={onDeactivateClick}>
              <span>{t("t:tournaments.deactivate")}</span>{" "}
              <img src={DeactivateIcon} alt="deactivate" />
            </button>
            <hr />
            <button onClick={onOpenClick}>
              <span>{t("t:shared.open")}</span>
              <img src={OpenIcon} alt="open" />
            </button>
          </div>
        </Popover>
      </div>
    </div>
  );
};

UpcomingTournament.defaultProps = defaultProps;

export default memo(withTranslation()(UpcomingTournament));
