import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import MenuIcon from "@material-ui/icons/Menu";

import IconButton from "../../../../components/_shared/Buttons/IconButton";
import urls from "../../../../core/urls";

import { changePanelState } from "../../../../core/appActions";

import HeaderLogo from "../../../../assets/image/header-logo.svg";
import LogoutIcon from "../../../../assets/image/logout.svg";

import "./styles.scss";

const Header = ({ history, changePanelState }) => {
  const onLogoClick = () => history.push(urls.usersUrl);

  const onLogout = () => {
    localStorage.clear();
    history.push(urls.loginUrl);
  };

  return (
    <header className="app-header">
      <div className="logo-wrapper">
        <IconButton
          onClick={changePanelState}
          alt={"logout"}
          classes="primary-color"
          imageAsComponent
        >
          <MenuIcon />
        </IconButton>
        <img className="gs-logo" src={HeaderLogo} alt="gaming stars" onClick={onLogoClick} />
      </div>
      <div className="logout">
        <div className="email">{localStorage.getItem("email") || ""}</div>
        <IconButton src={LogoutIcon} onClick={onLogout} alt={"logout"} />
      </div>
    </header>
  );
};

const mapDispatchToProps = {
  changePanelState
};

export default connect(null, mapDispatchToProps)(withRouter(Header));
