import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { deselectUsers } from "../actions";
import { closeConfirmationDialog, setConfirmationDialog } from "../../../core/appActions";

import CheckedIcon from "../../../assets/image/checked.svg";
import DeleteIcon from "../../../assets/image/delete.svg";

const BulkPanel = () => {
  const dispatch = useDispatch();
  const { selectedUsers } = useSelector(({ users }) => users);

  const onDeselect = () => dispatch(deselectUsers());

  const onDeleteClick = () => {
    dispatch(
      setConfirmationDialog({
        open: true,
        onClose: () => dispatch(closeConfirmationDialog()),
        confirmAction: () => {
          dispatch(closeConfirmationDialog());
          onDeselect();
        },
        declineAction: () => dispatch(closeConfirmationDialog()),
        title: "Delete selected users",
        description: "Are you sure you want to delete selected users?",
        confirmText: "Delete",
        declineText: "Cancel"
      })
    );
  };

  return (
    <div
      className={clsx(
        "users__bulk-panel with-transition-100ms no-user-select",
        !!selectedUsers.length && "is-open"
      )}
    >
      <div className="users__bulk-panel--content">
        <img src={CheckedIcon} alt="check" className="users__bulk-panel--checked" />
        <div className="users__bulk-panel--selected">{`${selectedUsers.length} selected`}</div>
        <div onClick={onDeselect} className="users__bulk-panel--deselect text-hover">
          Deselect all
        </div>
      </div>
      <div className="users__bulk-panel--content">
        <div className="users__bulk-panel--delete text-hover">
          <img src={DeleteIcon} alt="check" className="users__bulk-panel--delete-icon" />
          <div className="users__bulk-panel--delete-text" onClick={onDeleteClick}>
            Delete selected
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BulkPanel);
